import classNames from "classnames";
import { AprTerms, GetTermsResponse } from "../../../lib/api/models";
import { centsToDollarString } from "../../../lib/formatting";
import styles from "./APRTable.module.css";

const strings = {
  aprTerms: "APR Terms",
  balance: "BALANCE: ",
  purchaseAPR: "Purchase APR",
  cashAPR: "Cash APR",
  tierOne: "Tier 1 Penalty APR",
  tierTwo: "Tier 2 Penalty APR",
  primeRate: (s: string) => `(${s}% + Prime)`,
  fixed: "(Fixed)",
  notApplicable: "N/A",
};

type Props = {
  getTermsResponse: GetTermsResponse;
};

const renderAPRTable = (aprTerms: AprTerms, index: number) => {
  const {
    useTier1PenaltyPrimeRate,
    tier1PenaltyAPR,
    tier1PenaltyAPRSpread,
    useTier2PenaltyPrimeRate,
    tier2PenaltyAPR,
    tier2PenaltyAPRSpread,
  } = aprTerms;

  const {
    minBalanceCents,
    maxBalanceCents,
    purchaseAPR,
    cashAPR,
    purchaseAPRSpread,
    cashAPRSpread,
    usePurchasePrimeRate,
    useCashPrimeRate,
  } = aprTerms.levels[index];

  // should be percentage or N/A
  const tier1PenaltyAPRString =
    tier1PenaltyAPR && !isNaN(Number(tier1PenaltyAPR))
      ? `${tier1PenaltyAPR}%`
      : strings.notApplicable;
  const tier2PenaltyAPRString =
    tier2PenaltyAPR && !isNaN(Number(tier2PenaltyAPR))
      ? `${tier2PenaltyAPR}%`
      : strings.notApplicable;

  const tier1PenaltyAPRStringSuffix = tier1PenaltyAPR
    ? useTier1PenaltyPrimeRate
      ? strings.primeRate(tier1PenaltyAPRSpread)
      : strings.fixed
    : "";
  const tier2PenaltyAPRStringSuffix = tier2PenaltyAPR
    ? useTier2PenaltyPrimeRate
      ? strings.primeRate(tier2PenaltyAPRSpread)
      : strings.fixed
    : "";

  return (
    <table className={styles.table} key={`index-${index}`}>
      {index === 0 ? (
        <thead>
          <tr className={styles.headerRow}>
            <th>
              <span className={styles.header}>{strings.aprTerms}</span>
            </th>
          </tr>
        </thead>
      ) : null}
      <tbody>
        <tr className={classNames(styles.blueRow, styles.balanceRow)}>
          <td>
            <span>{`${strings.balance} ${centsToDollarString(minBalanceCents, false, true)} - ${centsToDollarString(maxBalanceCents, false, true)}`}</span>
          </td>
        </tr>
        <tr className={styles.evenRow}>
          <td>
            <span>{strings.purchaseAPR}</span>
          </td>
          <td>
            <span>{`${purchaseAPR}% ${usePurchasePrimeRate ? strings.primeRate(purchaseAPRSpread) : strings.fixed}`}</span>
          </td>
        </tr>
        <tr className={styles.oddRow}>
          <td>
            <span>{strings.cashAPR}</span>
          </td>
          <td>
            <span>{`${cashAPR}% ${useCashPrimeRate ? strings.primeRate(cashAPRSpread) : strings.fixed}`}</span>
          </td>
        </tr>
        <tr className={styles.evenRow}>
          <td>
            <span>{strings.tierOne}</span>
          </td>
          <td>
            <span>{`${tier1PenaltyAPRString} ${tier1PenaltyAPRStringSuffix}`}</span>
          </td>
        </tr>
        <tr className={styles.oddRow}>
          <td>
            <span>{strings.tierTwo}</span>
          </td>
          <td>
            <span>{`${tier2PenaltyAPRString} ${tier2PenaltyAPRStringSuffix}`}</span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default function APRTable({ getTermsResponse }: Props) {
  const { aprTerms } = getTermsResponse;

  return aprTerms.levels.map((_, index) => renderAPRTable(aprTerms, index));
}
