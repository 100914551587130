import classNames from "classnames";

import styles from "./Table.module.css";

export type TableColumn<TData> = {
  header: string | JSX.Element;
  alignment: "left" | "center" | "right";
  size: number;
  render: (rowData: TData) => React.ReactNode;
};

type Props<TData> = {
  className?: string;
  data: TData[];
  columns: TableColumn<TData>[];
  onClickRow?: (rowData: TData) => void;
};

const flexStyle = { left: "flex-start", right: "flex-end", center: "center" };

export default function Table<TData extends Record<string, unknown>>({
  className,
  data,
  columns,
  onClickRow,
}: Props<TData>) {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <table className={styles.container}>
        <thead className={styles.container}>
          <tr
            className={classNames(styles.headerRow, styles.tableRowContainer)}
          >
            {columns.map((column, i) => (
              <th
                key={i}
                style={{ flex: column.size, textAlign: column.alignment }}
                className={styles.headerTitleText}
              >
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={styles.container}>
          {data.map((rowData, i) => (
            <tr
              key={i}
              className={classNames(
                styles.tableRowContainer,
                styles.tableRowButton,
              )}
              onClick={() => onClickRow?.(rowData)}
            >
              {columns.map((column, j) => (
                <td
                  key={`${i},${j}`}
                  style={{
                    flex: column.size,
                    justifyContent: flexStyle[column.alignment],
                  }}
                  className={styles.entry}
                >
                  {column.render(rowData)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
