import {
  GetTermsResponse,
  InterestGrace,
  Reward,
  Script,
  ScriptType,
} from "../../api/models";
import {
  centsToDollarString,
  convertDateStringToMonthDayYearFomat,
} from "../../formatting";
import styles from "./termsScripts.module.css";

const upsetScriptsVariantA = (nextPricingStrategyDate: string): Script[] => [
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `If the customer upset/asks you don't have a lower rate?`,
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue: `Please hold while I review your account.`,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `The account must meet all of the following rules:\n`,
      },
      {
        text: `1. The account has not had the APR lowered in the last 12 months. Review critical notes.\n`,
      },
      {
        text: `2. The account cannot have had greater than 2 late fee credits in the previous 12 months. Review the Adjustments tab of Affinity to verify these credits.\n`,
      },
      {
        text: `3. The account cannot have any more than 2 late/over limit occurrences in the last 12 months and these occurrences cannot have happened in consecutive months.\n`,
      },
      {
        text: `4. See Penalty Pricing Procedure for determining if the account was Late and/or OCL and when account will cure.`,
      },
    ],
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `If Qualifies:\n`,
      },
      {
        text: `1. Place in Back Office in the "Lower APR" tab. Enter Base rate in the New Pricing Strategy field.\n`,
      },
      {
        text: `2. Result call as "Penalty Pricing".\n`,
      },
      {
        text: `3. Note call "Submit LAPR per csat".`,
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      `Because you are a valued customer, we will reinstate the non-penalty rate, but please understand, if you are late or over limit again, ` +
      `the penalty rate will apply to your new purchases and will remain in effect until you make the required minimum payment by the due date for ` +
      `each of the 6 consecutive billing cycles and the balance on your account remains at or below the credit limit for 6 consecutive billing cycles.`,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `If Doesn't Qualify:`,
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      `After reviewing your account I see that as long as you stay under your credit limit and continue to make on time payments beginning on ` +
      `${convertDateStringToMonthDayYearFomat(nextPricingStrategyDate)} your APR will return to the non-penalty rate in 6 months.`,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `If the card member is upset CS transfer to 7666/ Coll transfer to 7697.`,
      },
    ],
  },
];

const upsetScriptsVariantB = (): Script[] => [
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `If the customer upset/asks you don't have a lower rate?`,
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue: `Please hold while I review your account.`,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `The account must meet all of the following rules:\n`,
      },
      {
        text: `1. The account has not had the APR lowered in the last 12 months. Review critical notes.\n`,
      },
      {
        text: `2. The account cannot have had greater than 2 late fee credits in the previous 12 months. Review the Adjustments tab of Affinity to verify these credits.\n`,
      },
      {
        text: `3. The account cannot have any more than 2 late/over limit occurrences in the last 12 months and these occurrences cannot have happened in consecutive months.\n`,
      },
      {
        text: `4. See Penalty Pricing Procedure for determining if the account was Late and/or OCL and when account will cure.`,
      },
    ],
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `If Qualifies:\n`,
      },
      {
        text: `1. Place in Back Office in the "Lower APR" tab. Enter Base rate in the New Pricing Strategy field.\n`,
      },
      {
        text: `2. Result call as "Penalty Pricing".\n`,
      },
      {
        text: `3. Note call "Submit LAPR per csat".`,
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      `Because you are a valued customer, we will reinstate the non-penalty rate, but please understand, if you are late or over limit again, ` +
      `the penalty rate will apply to your new purchases and will remain in effect until you make the required minimum payment by the due date for ` +
      `each of the 6 consecutive billing cycles and the balance on your account remains at or below the credit limit for 6 consecutive billing cycles.`,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `If Doesn't Qualify:`,
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue: `After reviewing your account I see that as long as you stay under your credit limit and continue to make on time payments your APR will return to the non-penalty rate in 6 months.`,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `If the card member is upset CS transfer to 7666/ Coll transfer to 7697.`,
      },
    ],
  },
];

const upsetScriptsVariantC = (
  nextPricingStrategyDate: string,
  nextPricingStrategyCitMethodId: string,
  next2PricingStrategyEffectiveDate: string,
  next2PricingStrategyCitMethodId: string,
): Script[] => {
  const scripts: Script[] = [
    {
      scriptType: ScriptType.Instruction,
      instructions: [
        {
          text: `If the customer still upset:`,
        },
      ],
    },
  ];

  if (nextPricingStrategyCitMethodId === "G23MSG") {
    scripts.push({
      scriptType: ScriptType.Dialogue,
      dialogue:
        `After reviewing your account I see that as long as you stay under your credit limit and continue to make on time payments beginning on ${convertDateStringToMonthDayYearFomat(nextPricingStrategyDate)} your ` +
        `APR will return to the non-penalty rate in 6 months.`,
    });
  } else if (next2PricingStrategyCitMethodId === "G23MSG") {
    scripts.push({
      scriptType: ScriptType.Dialogue,
      dialogue:
        `After reviewing your account I see that as long as you stay under your credit limit and continue to make on time payments beginning on ${convertDateStringToMonthDayYearFomat(next2PricingStrategyEffectiveDate)} your ` +
        `APR will return to the non-penalty rate in 6 months.`,
    });
  } else {
    scripts.push(
      {
        scriptType: ScriptType.Dialogue,
        dialogue: `Please hold while I review your account.`,
      },
      {
        scriptType: ScriptType.Instruction,
        instructions: [
          {
            text: `See Penalty Pricing Procedure for determining if the account was Late and/or OCL and when account will cure.`,
          },
        ],
      },
      {
        scriptType: ScriptType.Dialogue,
        dialogue: `After reviewing your account I see that as long as you stay under your credit limit and continue to make on time payments your APR will return to the non-penalty rate in 6 months.`,
      },
    );
  }

  return [
    ...scripts,
    {
      scriptType: ScriptType.Instruction,
      instructions: [
        {
          text: `If the card member is upset CS transfer to 7666/ Coll transfer to 7697.`,
        },
      ],
    },
  ];
};

const upsetScriptsVariantD = (penaltyRate: string): Script[] => [
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `If customer asks why APR so high on full balance. (protected balance)`,
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue: `Your entire account balance is currently subject to the penalty rate of ${penaltyRate}% because you failed to make the required minimum payment by the due date for that payment.`,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `If the card member asks for specifics then you tell the date of when they went 60 days (90 FDR days) delinquent.`,
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      `The penalty rate will remain in effect until you make the required minimum payment by the due date for each of 6 consecutive billing cycles. ` +
      `After these conditions are satisfied, the non-penalty rate on your protected balance will be reinstated if applicable.`,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `If customer asks what is the non-penalty rate on the protected balance, review previous statements to determine APR.`,
      },
    ],
  },
];

const upsetScriptsVariantE = (
  nextPricingStrategyDate: string,
  nextPricingStrategyCitMethodId: string,
): Script[] => {
  const scripts: Script[] = [
    {
      scriptType: ScriptType.Instruction,
      instructions: [
        {
          text: `If the customer still upset:`,
        },
      ],
    },
  ];

  if (nextPricingStrategyCitMethodId === "410G23") {
    scripts.push({
      scriptType: ScriptType.Dialogue,
      dialogue:
        `After reviewing your account I see that as long as you continue to make on time payments beginning on ${convertDateStringToMonthDayYearFomat(nextPricingStrategyDate)} your ` +
        `APR will return to the non-penalty rate in 6 months.`,
    });
  } else {
    scripts.push(
      {
        scriptType: ScriptType.Dialogue,
        dialogue: `Please hold while I review your account.`,
      },
      {
        scriptType: ScriptType.Instruction,
        instructions: [
          {
            text: `See Penalty Pricing Procedure for determining if the account was Late and/or OCL and when account will cure.`,
          },
        ],
      },
      {
        scriptType: ScriptType.Dialogue,
        dialogue: `After reviewing your account I see that as long as you continue to make on time payments your APR will return to the non-penalty rate in 6 months.`,
      },
    );
  }

  return [
    ...scripts,
    {
      scriptType: ScriptType.Instruction,
      instructions: [
        {
          text: `If the card member is upset CS transfer to 7666/ Coll transfer to 7697.`,
        },
      ],
    },
  ];
};

const getInterestGraceDialogue = (interestGrace: InterestGrace): string => {
  switch (interestGrace) {
    case "No Grace":
      return "";
    case "Purch Only":
      return " You know, of course, that if you pay your balance in full each month, there are NO interest charges for purchases, although cash accrues interest charges from the date received.";
    case "Purch + Cash":
      return " You know, of course, that if you pay your balance in full each month, there is NO interest charged.";
  }
};

const getCustomerUpsetScriptsWithPricingAndSpecialHandling = (
  pricingStrategy: string,
  specialHandlingIndicator: string,
): Script[] => {
  const scripts: Script[] = [];

  if (
    pricingStrategy === "CD0%" ||
    pricingStrategy === "CC0%" ||
    pricingStrategy === "CD06" ||
    pricingStrategy === "CC06" ||
    pricingStrategy === "CD10" ||
    pricingStrategy === "CC10" ||
    pricingStrategy === "CD12" ||
    pricingStrategy === "CC12" ||
    pricingStrategy === "CD15" ||
    pricingStrategy === "CC15" ||
    pricingStrategy === "CD18" ||
    pricingStrategy === "CC18" ||
    pricingStrategy === "VCZ5" ||
    pricingStrategy === "VCZM" ||
    pricingStrategy === "MCZ5" ||
    pricingStrategy === "MCZM"
  ) {
    scripts.push({
      scriptType: ScriptType.Dialogue,
      dialogue: `Please hold while I transfer you to a representative that can assist you with that.`,
    });
  } else if (specialHandlingIndicator === "MLA") {
    scripts.push({
      scriptType: ScriptType.Dialogue,
      dialogue: `That is correct, the interest rate currently applicable to your account is the lowest rate we offer to customers who are not experiencing financial hardship.`,
    });
  } else if (specialHandlingIndicator === "SCRA") {
    scripts.push({
      scriptType: ScriptType.Dialogue,
      dialogue: `Please hold while I transfer you to a representative that can assist you with that.`,
    });
  } else {
    scripts.push({
      scriptType: ScriptType.Dialogue,
      dialogue:
        `That is correct, the interest rate currently applicable to your account is the lowest rate we offer to customers who are not experiencing financial hardship or eligible for the benefits of the Service member's Civil Relief Act (SCRA). ` +
        `The benefits of the SCRA include a reduction in the interest rate on debts incurred by a service member, or jointly by a service member and his/her spouse, prior to the service member's entry into active military service to six percent per year during the period of military service.`,
    });
  }

  return scripts;
};

export const termsScriptCash01 = (
  interestGrace: InterestGrace,
  cashAdvanceFeeAmount: string,
  cashAdvanceFeeAmountCents: number,
  availableCreditCents: number,
): Script[] => {
  const scripts: Script[] = [];
  const availableCredit = centsToDollarString(
    availableCreditCents,
    false,
    true,
  );

  if (interestGrace === "Purch + Cash") {
    if (cashAdvanceFeeAmountCents === 0) {
      scripts.push({
        scriptType: ScriptType.Dialogue,
        dialogue: `Your available credit is ${availableCredit}. There is no fee to get a cash advance.`,
      });
    } else {
      scripts.push({
        scriptType: ScriptType.Dialogue,
        dialogue: `Your available credit is ${availableCredit}.\n\nThe cost of getting cash is ${cashAdvanceFeeAmount} of the amount of the transaction, whichever is greater.\n\nPlease make sure that you have enough available credit for the advance, interest, and any cash advance fees.`,
        className: styles.alignTop,
      });
    }
  } else {
    if (cashAdvanceFeeAmountCents === 0) {
      scripts.push({
        scriptType: ScriptType.Dialogue,
        dialogue: `Your available credit is ${availableCredit}. There is no fee to get a cash advance.\n\nAlthough interest charges start to accrue immediately on a cash advance; there is no grace period.`,
        className: styles.alignTop,
      });
    } else {
      scripts.push({
        scriptType: ScriptType.Dialogue,
        dialogue:
          `Your available credit is ${availableCredit}.\n\nThe cost of getting cash is ${cashAdvanceFeeAmount} of the amount of the transaction, whichever is greater.\n\nPlease make sure that you have enough available credit for the advance, interest, and any cash advance fees.` +
          `\n\nInterest charges start to accrue immediately on a cash advance; there is no grace period.`,

        className: styles.alignTop,
      });
    }
  }

  return scripts;
};

export const termsScriptRewards = (rewards: Reward[]): Script[] => {
  return [
    {
      scriptType: ScriptType.Dialogue,
      dialogue: `Your account is currently eligible for cash rewards of ${rewards.map((r) => r.programName).join(", ")}.`,
    },
  ];
};

export const termsScriptAPR01 = (
  basePurchaseAPRLevel1: string,
  baseCashAPRLevel1: string,
  monthlyBasePurchaseAPRLevel1: string,
  monthlyBaseCashAPRLevel1: string,
  interestGrace: InterestGrace,
  pricingStrategy: string,
  specialHandlingIndicator: string,
): Script[] => {
  const scripts: Script[] = [
    {
      scriptType: ScriptType.Dialogue,
      dialogue:
        `Your APR for purchases is ${basePurchaseAPRLevel1}% and for cash is ${baseCashAPRLevel1}%. That amounts to a total interest charge of approximately $${monthlyBasePurchaseAPRLevel1} per month for every $100 ` +
        `you carry in an outstanding purchase balance and approximately $${monthlyBaseCashAPRLevel1} per month for every $100 you carry in an outstanding cash balance.` +
        getInterestGraceDialogue(interestGrace),
    },
    {
      scriptType: ScriptType.Instruction,
      instructions: [
        {
          text: `If the customer upset/asks you don't have a lower rate?`,
        },
      ],
    },
    ...getCustomerUpsetScriptsWithPricingAndSpecialHandling(
      pricingStrategy,
      specialHandlingIndicator,
    ),
  ];

  return scripts;
};

export const termsScriptAPR18 = (
  maximumLevel1BalanceCents: number,
  basePurchaseAPRLevel1: string,
  baseCashAPRLevel1: string,
  monthlyBasePurchaseAPRLevel1: string,
  monthlyBaseCashAPRLevel1: string,
  basePurchaseAPRLevel2: string,
  monthlyBasePurchaseAPRLevel2: string,
  monthlyBaseCashAPRLevel2: string,
  interestGrace: InterestGrace,
  pricingStrategy: string,
  specialHandlingIndicator: string,
): Script[] => {
  const maxBalance = centsToDollarString(maximumLevel1BalanceCents);
  const basePurchaseAPRLevel1Num = parseFloat(basePurchaseAPRLevel1);
  const baseCashAPRLevel1Num = parseFloat(baseCashAPRLevel1);
  const scripts: Script[] = [
    {
      scriptType: ScriptType.Dialogue,
      dialogue: `Your APR on the first ${maxBalance} of purchases is ${basePurchaseAPRLevel1}%. Your APR for cash is ${baseCashAPRLevel1}%.`,
    },
  ];

  if (
    !isNaN(basePurchaseAPRLevel1Num) &&
    !isNaN(baseCashAPRLevel1Num) &&
    basePurchaseAPRLevel1Num > 0 &&
    baseCashAPRLevel1Num > 0
  ) {
    scripts.push({
      scriptType: ScriptType.Dialogue,
      dialogue:
        `That amounts to a total interest charge of approximately $${monthlyBasePurchaseAPRLevel1} per month for every $100 ` +
        `you carry in an outstanding purchase balance and approximately $${monthlyBaseCashAPRLevel1} per month for every $100 you carry in an outstanding cash balance.`,
    });
  }

  scripts.push(
    {
      scriptType: ScriptType.Dialogue,
      dialogue:
        `For the portion of your purchase balance over ${maxBalance}, your APR is ${basePurchaseAPRLevel2}%. That amounts to a total interest charge of approximately $${monthlyBasePurchaseAPRLevel2} ` +
        `per month for every $100 you carry in a purchase balance above ${maxBalance} and approximately $${monthlyBaseCashAPRLevel2} per month for every $100 you carry in a cash balance.` +
        getInterestGraceDialogue(interestGrace),
    },
    {
      scriptType: ScriptType.Instruction,
      instructions: [
        { text: `If the customer upset/asks you don't have a lower rate?` },
      ],
    },
    ...getCustomerUpsetScriptsWithPricingAndSpecialHandling(
      pricingStrategy,
      specialHandlingIndicator,
    ),
  );

  return scripts;
};

export const termsScriptAPR27 = (
  maximumLevel1BalanceCents: number,
  basePurchaseAPRLevel1: string,
  baseCashAPRLevel1: string,
  monthlyBasePurchaseAPRLevel1: string,
  monthlyBaseCashAPRLevel1: string,
  basePurchaseAPRLevel2: string,
  baseCashAPRLevel2: string,
  monthlyBasePurchaseAPRLevel2: string,
  monthlyBaseCashAPRLevel2: string,
  interestGrace: InterestGrace,
  pricingStrategy: string,
  specialHandlingIndicator: string,
): Script[] => {
  const maxBalance = centsToDollarString(maximumLevel1BalanceCents);
  const basePurchaseAPRLevel1Num = parseFloat(basePurchaseAPRLevel1);
  const baseCashAPRLevel1Num = parseFloat(baseCashAPRLevel1);
  const scripts: Script[] = [
    {
      scriptType: ScriptType.Dialogue,
      dialogue: `Your APR on the first ${maxBalance} of purchases is ${basePurchaseAPRLevel1}%. On the first ${maxBalance} of cash your APR is ${baseCashAPRLevel1}%.`,
    },
  ];

  if (
    !isNaN(basePurchaseAPRLevel1Num) &&
    !isNaN(baseCashAPRLevel1Num) &&
    basePurchaseAPRLevel1Num > 0 &&
    baseCashAPRLevel1Num > 0
  ) {
    scripts.push({
      scriptType: ScriptType.Dialogue,
      dialogue:
        `That amounts to a total interest charge of approximately $${monthlyBasePurchaseAPRLevel1} per month for every $100 ` +
        `you carry in an outstanding purchase balance and approximately $${monthlyBaseCashAPRLevel1} per month for every $100 you carry in an outstanding cash balance.`,
    });
  }

  scripts.push(
    {
      scriptType: ScriptType.Dialogue,
      dialogue:
        `For the portion of your purchase balance over ${maxBalance}, your APR is ${basePurchaseAPRLevel2}%. For the portion of your cash advances over ${maxBalance}, your APR is ${baseCashAPRLevel2}% ` +
        `That amounts to a total interest charge of approximately $${monthlyBasePurchaseAPRLevel2} per month for every $100 you carry in a purchase balance above ` +
        `${maxBalance} and approximately $${monthlyBaseCashAPRLevel2} per month for every $100 you carry in a cash balance.` +
        getInterestGraceDialogue(interestGrace),
    },
    {
      scriptType: ScriptType.Instruction,
      instructions: [
        { text: `If the customer upset/asks you don't have a lower rate?` },
      ],
    },
    ...getCustomerUpsetScriptsWithPricingAndSpecialHandling(
      pricingStrategy,
      specialHandlingIndicator,
    ),
  );

  return scripts;
};

export const termsScriptAPR02 = (
  maximumLevel1BalanceCents: number,
  basePurchaseAPRLevel1: string,
  baseCashAPRLevel1: string,
  monthlyBasePurchaseAPRLevel1: string,
  monthlyBaseCashAPRLevel1: string,
  basePurchaseAPRLevel2: string,
  baseCashAPRLevel2: string,
  monthlyBasePurchaseAPRLevel2: string,
  monthlyBaseCashAPRLevel2: string,
  interestGrace: InterestGrace,
  pricingStrategy: string,
  specialHandlingIndicator: string,
): Script[] => {
  if (baseCashAPRLevel1 === baseCashAPRLevel2) {
    return termsScriptAPR18(
      maximumLevel1BalanceCents,
      basePurchaseAPRLevel1,
      baseCashAPRLevel1,
      monthlyBasePurchaseAPRLevel1,
      monthlyBaseCashAPRLevel1,
      basePurchaseAPRLevel2,
      monthlyBasePurchaseAPRLevel2,
      monthlyBaseCashAPRLevel2,
      interestGrace,
      pricingStrategy,
      specialHandlingIndicator,
    );
  } else {
    return termsScriptAPR27(
      maximumLevel1BalanceCents,
      basePurchaseAPRLevel1,
      baseCashAPRLevel1,
      monthlyBasePurchaseAPRLevel1,
      monthlyBaseCashAPRLevel1,
      basePurchaseAPRLevel2,
      baseCashAPRLevel2,
      monthlyBasePurchaseAPRLevel2,
      monthlyBaseCashAPRLevel2,
      interestGrace,
      pricingStrategy,
      specialHandlingIndicator,
    );
  }
};

export const termsScriptAPR03 = (
  introductoryPurchaseAPRRate: string,
  introductoryPurchaseExpirationMonths: string,
  introductoryCashAPRRate: string,
  introductoryPurchaseExpirationDate: string,
  basePurchaseAPRLevel1: string,
  baseCashAPRLevel1: string,
  monthlyBasePurchaseAPRLevel1: string,
  monthlyBaseCashAPRLevel1: string,
  interestGrace: InterestGrace,
  pricingStrategy: string,
  specialHandlingIndicator: string,
): Script[] => {
  const scripts: Script[] = [
    {
      scriptType: ScriptType.Dialogue,
      dialogue:
        `Your APR is ${introductoryPurchaseAPRRate}% for the first ${introductoryPurchaseExpirationMonths} months for purchases and ${introductoryCashAPRRate}% ` +
        `for cash from the account open date (Expires ${convertDateStringToMonthDayYearFomat(introductoryPurchaseExpirationDate)}). After the introductory offer, your APR for purchases is ${basePurchaseAPRLevel1}% ` +
        `and for cash is ${baseCashAPRLevel1}%. That amounts to a total interest charge of approximately $${monthlyBasePurchaseAPRLevel1} per month for every $100 ` +
        `you carry in an outstanding purchase balance and approximately $${monthlyBaseCashAPRLevel1} per month for every $100 you carry in an outstanding cash balance.` +
        getInterestGraceDialogue(interestGrace),
    },
    {
      scriptType: ScriptType.Instruction,
      instructions: [
        {
          text: `If the customer upset/asks you don't have a lower rate?`,
        },
      ],
    },
    ...getCustomerUpsetScriptsWithPricingAndSpecialHandling(
      pricingStrategy,
      specialHandlingIndicator,
    ),
  ];

  return scripts;
};

export const termsScriptAPR04 = (
  currentPurchaseAPR: string,
  currentCashAPR: string,
  newRateOnTransDate: string,
  tier1PenaltyAPR: string,
  nextPricingStrategyDate: string,
  basePurchaseAPRLevel1: string,
  baseCashAPRLevel1: string,
): Script[] => [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      `Your rate is ${currentPurchaseAPR}% for purchases and ${currentCashAPR}% for cash on your balance ` +
      `prior to ${convertDateStringToMonthDayYearFomat(newRateOnTransDate)}. For purchases and cash after ${convertDateStringToMonthDayYearFomat(newRateOnTransDate)} you are currently ` +
      `subject to the penalty rate of ${tier1PenaltyAPR}% because you failed to make the required minimum payment by the due date and/or ` +
      `your balance exceeded the credit limit on your billing cycle closing date.`,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `If the card member asks for specifics then you tell the date of when they went OCL or Late.`,
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      `The penalty rate will remain in effect until you make the required minimum payment by the due date for each of 6 consecutive billing cycles ` +
      `and the balance on your account remains at or below the credit limit for 6 consecutive billing cycles. After these conditions are satisfied, the non-penalty rate will be reinstated.`,
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue: `Your non-penalty APR for purchases is ${basePurchaseAPRLevel1}% and for cash is ${baseCashAPRLevel1}%.`,
  },
  ...upsetScriptsVariantA(nextPricingStrategyDate),
];

export const termsScriptAPR19 = (
  maximumLevel1BalanceCents: number,
  basePurchaseAPRLevel1: string,
  basePurchaseAPRLevel2: string,
  baseCashAPRLevel2: string,
): Script[] => {
  const maxBalance = centsToDollarString(maximumLevel1BalanceCents);
  return [
    {
      scriptType: ScriptType.Dialogue,
      dialogue: `Your non-penalty APR on the first ${maxBalance} of purchases is ${basePurchaseAPRLevel1}%.`,
    },
    {
      scriptType: ScriptType.Dialogue,
      dialogue: `For the portion of your purchase balance over ${maxBalance}, your APR is ${basePurchaseAPRLevel2}%. For cash advances your APR is ${baseCashAPRLevel2}%.`,
    },
  ];
};

export const termsScriptAPR05 = (
  currentPurchaseAPR: string,
  currentCashAPR: string,
  newRateOnTransDate: string,
  tier1PenaltyAPR: string,
  maximumLevel1BalanceCents: number,
  basePurchaseAPRLevel1: string,
  baseCashAPRLevel1: string,
  basePurchaseAPRLevel2: string,
  baseCashAPRLevel2: string,
  nextPricingStrategyDate: string,
): Script[] => {
  const baseCashAPRLevel1Num = parseFloat(baseCashAPRLevel1);
  const baseCashAPRLevel2Num = parseFloat(baseCashAPRLevel2);
  const scripts: Script[] = [
    {
      scriptType: ScriptType.Dialogue,
      dialogue:
        `Your rate is ${currentPurchaseAPR}% for purchases and ${currentCashAPR}% for cash on your balance ` +
        `prior to ${convertDateStringToMonthDayYearFomat(newRateOnTransDate)}. For purchases and cash after ${convertDateStringToMonthDayYearFomat(newRateOnTransDate)} you are currently ` +
        `subject to the penalty rate of ${tier1PenaltyAPR}% because you failed to make the required minimum payment by the due date and/or ` +
        `your balance exceeded the credit limit on your billing cycle closing date.`,
    },
    {
      scriptType: ScriptType.Instruction,
      instructions: [
        {
          text: `If the card member asks for specifics then you tell the date of when they went OCL or Late.`,
        },
      ],
    },
    {
      scriptType: ScriptType.Dialogue,
      dialogue:
        `The penalty rate will remain in effect until you make the required minimum payment by the due date for each of 6 consecutive ` +
        `billing cycles and the balance on your account remains at or below the credit limit for 6 consecutive billing cycles. After these ` +
        `conditions are satisfied, the non-penalty rate will be reinstated.`,
    },
  ];

  if (
    !isNaN(baseCashAPRLevel1Num) &&
    !isNaN(baseCashAPRLevel2Num) &&
    baseCashAPRLevel1Num === baseCashAPRLevel2Num
  ) {
    scripts.push(
      ...termsScriptAPR19(
        maximumLevel1BalanceCents,
        basePurchaseAPRLevel1,
        basePurchaseAPRLevel2,
        baseCashAPRLevel2,
      ),
    );
  } else {
    const maxBalance = centsToDollarString(maximumLevel1BalanceCents);
    scripts.push(
      {
        scriptType: ScriptType.Dialogue,
        dialogue:
          `Your non-penalty APR on the first ${maxBalance} of purchases is ${basePurchaseAPRLevel1}%. ` +
          `On the first ${maxBalance} of cash your APR is ${baseCashAPRLevel1}%.`,
      },
      {
        scriptType: ScriptType.Dialogue,
        dialogue:
          `For the portion of your purchase balance over ${maxBalance}, your APR is ${basePurchaseAPRLevel2}%. ` +
          `For the portion of your cash advances over ${maxBalance}, your APR is ${baseCashAPRLevel2}%.`,
      },
    );
  }

  scripts.push(...upsetScriptsVariantA(nextPricingStrategyDate));

  return scripts;
};

export const termsScriptAPR06 = (
  currentPurchaseAPR: string,
  currentCashAPR: string,
  newRateOnTransDate: string,
  tier1PenaltyAPR: string,
  basePurchaseAPRLevel1: string,
  baseCashAPRLevel1: string,
  introductoryPurchaseAPRRate: string,
  introductoryPurchaseExpirationMonths: string,
  introductoryCashAPRRate: string,
  introductoryPurchaseExpirationDate: string,
  nextPricingStrategyDate: string,
): Script[] => [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      `Your rate is ${currentPurchaseAPR}% for purchases and ${currentCashAPR}% for cash on your balance ` +
      `prior to ${convertDateStringToMonthDayYearFomat(newRateOnTransDate)}. For purchases and cash after ${convertDateStringToMonthDayYearFomat(newRateOnTransDate)} you are currently ` +
      `subject to the penalty rate of ${tier1PenaltyAPR}% because you failed to make the required minimum payment by the due date and/or ` +
      `your balance exceeded the credit limit on your billing cycle closing date.`,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `If the card member asks for specifics then you tell the date of when they went OCL or Late.`,
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      `The penalty rate will remain in effect until you make the required minimum payment by the due date for each of 6 consecutive billing cycles and the balance on ` +
      `your account remains at or below the credit limit for 6 consecutive billing cycles. After these conditions are satisfied, the non-penalty rate will be reinstated.`,
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      `Your non-penalty APR is ${introductoryPurchaseAPRRate}% for the first ${introductoryPurchaseExpirationMonths} months for purchases and ` +
      `${introductoryCashAPRRate}% for cash from the account open date (Expires ${convertDateStringToMonthDayYearFomat(introductoryPurchaseExpirationDate)}). ` +
      `After the introductory offer, your APR for purchases is ${basePurchaseAPRLevel1}% and for cash is ${baseCashAPRLevel1}%.`,
  },
  ...upsetScriptsVariantA(nextPricingStrategyDate),
];

export const termsScriptAPR07 = (
  tier1PenaltyAPR: string,
  basePurchaseAPRLevel1: string,
  baseCashAPRLevel1: string,
): Script[] => [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      `Your account is currently subject to the penalty rate of ${tier1PenaltyAPR}% for new purchases because you failed to make the required ` +
      `minimum payment by the due date and/or your balance exceeded the credit limit on your billing cycle closing date.`,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `If the card member asks for specifics then you tell the date of when they went OCL or Late.`,
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      `The penalty rate will remain in effect until you make the required minimum payment by the due date for each of 6 consecutive billing cycles and the balance on ` +
      `your account remains at or below the credit limit for 6 consecutive billing cycles. After these conditions are satisfied, the non-penalty rate will be reinstated.`,
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue: `Your non-penalty APR for purchases is ${basePurchaseAPRLevel1}% and for cash is ${baseCashAPRLevel1}%.`,
  },
  ...upsetScriptsVariantB(),
];

export const termsScriptAPR08 = (
  tier1PenaltyAPR: string,
  maximumLevel1BalanceCents: number,
  basePurchaseAPRLevel1: string,
  baseCashAPRLevel1: string,
  basePurchaseAPRLevel2: string,
  baseCashAPRLevel2: string,
): Script[] => {
  const baseCashAPRLevel1Num = parseFloat(baseCashAPRLevel1);
  const baseCashAPRLevel2Num = parseFloat(baseCashAPRLevel2);
  const scripts: Script[] = [
    {
      scriptType: ScriptType.Dialogue,
      dialogue:
        `Your account is currently subject to the penalty rate of ${tier1PenaltyAPR}% for new purchases because you failed to make the required ` +
        `minimum payment by the due date and/or your balance exceeded the credit limit on your billing cycle closing date.`,
    },
    {
      scriptType: ScriptType.Instruction,
      instructions: [
        {
          text: `If the card member asks for specifics then you tell the date of when they went OCL or Late.`,
        },
      ],
    },
    {
      scriptType: ScriptType.Dialogue,
      dialogue:
        `The penalty rate will remain in effect until you make the required minimum payment by the due date for each of 6 consecutive billing cycles and the balance on ` +
        `your account remains at or below the credit limit for 6 consecutive billing cycles. After these conditions are satisfied, the non-penalty rate will be reinstated.`,
    },
  ];

  if (
    !isNaN(baseCashAPRLevel1Num) &&
    !isNaN(baseCashAPRLevel2Num) &&
    baseCashAPRLevel1Num === baseCashAPRLevel2Num
  ) {
    scripts.push(
      ...termsScriptAPR19(
        maximumLevel1BalanceCents,
        basePurchaseAPRLevel1,
        basePurchaseAPRLevel2,
        baseCashAPRLevel2,
      ),
    );
  } else {
    const maxBalance = centsToDollarString(maximumLevel1BalanceCents);
    scripts.push(
      {
        scriptType: ScriptType.Dialogue,
        dialogue:
          `Your non-penalty APR on the first ${maxBalance} of purchases is ${basePurchaseAPRLevel1}%. ` +
          `On the first ${maxBalance} of cash your APR is ${baseCashAPRLevel1}%.`,
      },
      {
        scriptType: ScriptType.Dialogue,
        dialogue:
          `For the portion of your purchase balance over ${maxBalance}, your APR is ${basePurchaseAPRLevel2}%. ` +
          `For the portion of your cash advances over ${maxBalance}, your APR is ${baseCashAPRLevel2}%.`,
      },
    );
  }

  scripts.push(...upsetScriptsVariantB());

  return scripts;
};

export const termsScriptAPR09 = (
  tier1PenaltyAPR: string,
  basePurchaseAPRLevel1: string,
  baseCashAPRLevel1: string,
  introductoryPurchaseAPRRate: string,
  introductoryPurchaseExpirationMonths: string,
  introductoryCashAPRRate: string,
  introductoryPurchaseExpirationDate: string,
): Script[] => [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      `Your account is currently subject to the penalty rate of ${tier1PenaltyAPR}% for new purchases because you failed to make the required ` +
      `minimum payment by the due date and/or your balance exceeded the credit limit on your billing cycle closing date.`,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `If the card member asks for specifics then you tell the date of when they went OCL or Late.`,
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      `The penalty rate will remain in effect until you make the required minimum payment by the due date for each of 6 consecutive billing cycles and the balance on ` +
      `your account remains at or below the credit limit for 6 consecutive billing cycles. After these conditions are satisfied, the non-penalty rate will be reinstated.`,
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      `Your non-penalty APR is ${introductoryPurchaseAPRRate}% for the first ${introductoryPurchaseExpirationMonths} months for purchases and ` +
      `${introductoryCashAPRRate}% for cash from the account open date (Expires ${convertDateStringToMonthDayYearFomat(introductoryPurchaseExpirationDate)}). ` +
      `After the introductory offer, your APR for purchases is ${basePurchaseAPRLevel1}% and for cash is ${baseCashAPRLevel1}%.`,
  },
  ...upsetScriptsVariantB(),
];

export const termsScriptAPR10 = (
  tier2PenaltyAPR: string,
  basePurchaseAPRLevel1: string,
  baseCashAPRLevel1: string,
  nextPricingStrategyDate: string,
  nextPricingStrategyCitMethodId: string,
  next2PricingStrategyEffectiveDate: string,
  next2PricingStrategyCitMethodId: string,
): Script[] => [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      `Your account is currently subject to the penalty rate of ${tier2PenaltyAPR}% for new purchases because you failed to make the required ` +
      `minimum payment by the due date and/or your balance exceeded the credit limit on your billing cycle closing date.`,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `If the card member asks for specifics then you tell the date of when they went OCL or Late.`,
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      `The penalty rate will remain in effect until you make the required minimum payment by the due date for each of 6 consecutive billing cycles and the balance on ` +
      `your account remains at or below the credit limit for 6 consecutive billing cycles. After these conditions are satisfied, the non-penalty rate will be reinstated.`,
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue: `Your non-penalty APR for purchases is ${basePurchaseAPRLevel1}% and for cash is ${baseCashAPRLevel1}%.`,
  },
  ...upsetScriptsVariantC(
    nextPricingStrategyDate,
    nextPricingStrategyCitMethodId,
    next2PricingStrategyCitMethodId,
    next2PricingStrategyEffectiveDate,
  ),
];

export const termsScriptAPR20 = (
  maximumLevel1BalanceCents: number,
  basePurchaseAPRLevel1: string,
  basePurchaseAPRLevel2: string,
  baseCashAPRLevel2: string,
  nextPricingStrategyDate: string,
  nextPricingStrategyCitMethodId: string,
  next2PricingStrategyCitMethodId: string,
  next2PricingStrategyEffectiveDate: string,
): Script[] => {
  const maxBalance = centsToDollarString(
    maximumLevel1BalanceCents,
    false,
    true,
  );
  const scripts: Script[] = [
    {
      scriptType: ScriptType.Dialogue,
      dialogue: `Your non-penalty APR on the first ${maxBalance} of purchases is ${basePurchaseAPRLevel1}%.`,
    },
    {
      scriptType: ScriptType.Dialogue,
      dialogue: `For the portion of your purchase balance over ${maxBalance}, your APR is ${basePurchaseAPRLevel2}%. For cash advances your APR is ${baseCashAPRLevel2}%.`,
    },
    ...upsetScriptsVariantC(
      nextPricingStrategyDate,
      nextPricingStrategyCitMethodId,
      next2PricingStrategyEffectiveDate,
      next2PricingStrategyCitMethodId,
    ),
  ];

  return scripts;
};

export const termsScriptAPR11 = (
  tier2PenaltyAPR: string,
  maximumLevel1BalanceCents: number,
  basePurchaseAPRLevel1: string,
  baseCashAPRLevel1: string,
  basePurchaseAPRLevel2: string,
  baseCashAPRLevel2: string,
  nextPricingStrategyDate: string,
  nextPricingStrategyCitMethodId: string,
  next2PricingStrategyEffectiveDate: string,
  next2PricingStrategyCitMethodId: string,
) => {
  const baseCashAPRLevel1Num = parseFloat(baseCashAPRLevel1);
  const baseCashAPRLevel2Num = parseFloat(baseCashAPRLevel2);
  const scripts: Script[] = [
    {
      scriptType: ScriptType.Dialogue,
      dialogue:
        `Your account is currently subject to the penalty rate of ${tier2PenaltyAPR}% because you failed to make the required ` +
        `minimum payment by the due date for that payment and/or your balance exceeded the credit limit on your billing cycle closing date.`,
    },
    {
      scriptType: ScriptType.Instruction,
      instructions: [
        {
          text: `If the card member asks for specifics then you tell the date of when they went OCL or Late.`,
        },
      ],
    },
    {
      scriptType: ScriptType.Dialogue,
      dialogue:
        `The penalty rate will remain in effect until you make the required minimum payment by the due date for each of 6 consecutive billing cycles and the balance on ` +
        `your account remains at or below the credit limit for 6 consecutive billing cycles. After these conditions are satisfied, the non-penalty rate will be reinstated.`,
    },
  ];

  if (
    !isNaN(baseCashAPRLevel1Num) &&
    !isNaN(baseCashAPRLevel2Num) &&
    baseCashAPRLevel1Num === baseCashAPRLevel2Num
  ) {
    scripts.push(
      ...termsScriptAPR20(
        maximumLevel1BalanceCents,
        basePurchaseAPRLevel1,
        basePurchaseAPRLevel2,
        baseCashAPRLevel2,
        nextPricingStrategyDate,
        nextPricingStrategyCitMethodId,
        next2PricingStrategyEffectiveDate,
        next2PricingStrategyCitMethodId,
      ),
    );
  } else {
    const maxBalance = centsToDollarString(
      maximumLevel1BalanceCents,
      false,
      true,
    );
    scripts.push(
      {
        scriptType: ScriptType.Dialogue,
        dialogue:
          `Your non-penalty APR on the first ${maxBalance} of purchases is ${basePurchaseAPRLevel1}%. ` +
          `On the first ${maxBalance} of cash your APR is ${baseCashAPRLevel1}%`,
      },
      {
        scriptType: ScriptType.Dialogue,
        dialogue:
          `For the portion of your purchase balance over ${maxBalance}, your APR is ${basePurchaseAPRLevel2}%.` +
          `For the portion of your cash advances over ${maxBalance}, your APR is ${baseCashAPRLevel2}%.`,
      },
      ...upsetScriptsVariantC(
        nextPricingStrategyDate,
        nextPricingStrategyCitMethodId,
        next2PricingStrategyEffectiveDate,
        next2PricingStrategyCitMethodId,
      ),
    );
  }

  return scripts;
};

export const termsScriptAPR12 = (
  tier2PenaltyAPR: string,
  introductoryPurchaseAPRRate: string,
  introductoryPurchaseExpirationMonths: string,
  introductoryCashAPRRate: string,
  introductoryPurchaseExpirationDate: string,
  basePurchaseAPRLevel1: string,
  baseCashAPRLevel1: string,
  nextPricingStrategyDate: string,
  nextPricingStrategyCitMethodId: string,
  next2PricingStrategyEffectiveDate: string,
  next2PricingStrategyCitMethodId: string,
): Script[] => [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      `Your account is currently subject to the penalty rate of ${tier2PenaltyAPR}% for new purchases because you failed to make the required ` +
      `minimum payment by the due date and/or your balance exceeded the credit limit on your billing cycle closing date.`,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `If the card member asks for specifics then you tell the date of when they went OCL or Late.`,
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      `The penalty rate will remain in effect until you make the required minimum payment by the due date for each of 6 consecutive billing cycles and the balance on ` +
      `your account remains at or below the credit limit for 6 consecutive billing cycles. After these conditions are satisfied, the non-penalty rate will be reinstated.`,
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      `Your non-penalty APR is ${introductoryPurchaseAPRRate}% for the first ${introductoryPurchaseExpirationMonths} months for purchases ` +
      `and ${introductoryCashAPRRate}% for cash from the account open date (Expires ${convertDateStringToMonthDayYearFomat(introductoryPurchaseExpirationDate)}). ` +
      `After the introductory offer, your APR for purchases is ${basePurchaseAPRLevel1}% and for cash is ${baseCashAPRLevel1}%.`,
  },
  ...upsetScriptsVariantC(
    nextPricingStrategyDate,
    nextPricingStrategyCitMethodId,
    next2PricingStrategyEffectiveDate,
    next2PricingStrategyCitMethodId,
  ),
];

export const termsScriptAPR16 = (
  basePurchaseAPRLevel1: string,
  baseCashAPRLevel1: string,
  monthlyBasePurchaseAPRLevel1: string,
  monthlyBaseCashAPRLevel1: string,
  interestGrace: InterestGrace,
  tier2PenaltyAPR: string,
  pricingStrategy: string,
  specialHandlingIndicator: string,
): Script[] => {
  const scripts: Script[] = [
    {
      scriptType: ScriptType.Dialogue,
      dialogue:
        `Your APR for purchases is ${basePurchaseAPRLevel1}% and for cash is ${baseCashAPRLevel1}%. That amounts to a total interest charge of approximately $${monthlyBasePurchaseAPRLevel1} per month for every $100 ` +
        `you carry in an outstanding purchase balance and approximately $${monthlyBaseCashAPRLevel1} per month for every $100 you carry in an outstanding cash balance.` +
        getInterestGraceDialogue(interestGrace),
    },
  ];

  scripts.push(
    {
      scriptType: ScriptType.Instruction,
      instructions: [
        {
          text: `If customer upset/asks you don't have a lower rate? (no protected balance)`,
        },
      ],
    },
    ...getCustomerUpsetScriptsWithPricingAndSpecialHandling(
      pricingStrategy,
      specialHandlingIndicator,
    ),
    ...upsetScriptsVariantD(tier2PenaltyAPR),
  );

  return scripts;
};

export const termsScriptAPR13 = (
  tier2PenaltyAPR: string,
  basePurchaseAPRLevel1: string,
  baseCashAPRLevel1: string,
  monthlyBasePurchaseAPRLevel1: string,
  monthlyBaseCashAPRLevel1: string,
  nextPricingStrategyDate: string,
  nextPricingStrategyCitMethodId: string,
  interestGrace: InterestGrace,
  pricingStrategy: string,
  specialHandlingIndicator: string,
): Script[] => {
  const scripts: Script[] = [];
  const basePurchaseAPRLevel1Num = parseFloat(basePurchaseAPRLevel1);
  const tier2PenaltyAPRNum = parseFloat(tier2PenaltyAPR);

  if (basePurchaseAPRLevel1Num === tier2PenaltyAPRNum) {
    scripts.push(
      ...termsScriptAPR16(
        basePurchaseAPRLevel1,
        baseCashAPRLevel1,
        monthlyBasePurchaseAPRLevel1,
        monthlyBaseCashAPRLevel1,
        interestGrace,
        tier2PenaltyAPR,
        specialHandlingIndicator,
        pricingStrategy,
      ),
    );
  } else if (basePurchaseAPRLevel1Num < tier2PenaltyAPRNum) {
    scripts.push(
      {
        scriptType: ScriptType.Dialogue,
        dialogue: `Your account is currently subject to the penalty rate of ${tier2PenaltyAPR}% because you failed to make the required minimum payment by the due date for that payment.`,
      },
      {
        scriptType: ScriptType.Instruction,
        instructions: [
          {
            text: `If the card member asks for specifics then you tell the date of when they went 60 days (90 FDR days) delinquent.`,
          },
        ],
      },
      {
        scriptType: ScriptType.Dialogue,
        dialogue: `The penalty rate will remain in effect until you make the required minimum payment by the due date for each of 6 consecutive billing cycles. After these conditions are satisfied, the non-penalty rate will be reinstated.`,
      },
      {
        scriptType: ScriptType.Dialogue,
        dialogue: `Your non-penalty APR for purchases is ${basePurchaseAPRLevel1}% and for cash is ${baseCashAPRLevel1}%`,
      },
      ...upsetScriptsVariantE(
        nextPricingStrategyDate,
        nextPricingStrategyCitMethodId,
      ),
    );
  } else {
    scripts.push(
      {
        scriptType: ScriptType.Dialogue,
        dialogue: `Please hold while I review your account.`,
      },
      {
        scriptType: ScriptType.Instruction,
        instructions: [
          {
            text: `See Penalty Pricing Procedure for determining if the account was Late and/or OCL and when account will cure.`,
          },
        ],
      },
      {
        scriptType: ScriptType.Dialogue,
        dialogue: `After reviewing your account I see that as long as you continue to make on time payments your APR will return to the non-penalty rate in 6 months.`,
      },
      {
        scriptType: ScriptType.Instruction,
        instructions: [
          {
            text: `If the card member is upset CS transfer to 7666/ Coll transfer to 7697.`,
          },
        ],
      },
    );
  }

  return scripts;
};

export const termsScriptAPR21 = (
  maximumLevel1BalanceCents: number,
  basePurchaseAPRLevel1: string,
  basePurchaseAPRLevel2: string,
  baseCashAPRLevel2: string,
  nextPricingStrategyDate: string,
  nextPricingStrategyCitMethodId: string,
): Script[] => {
  const maxBalance = centsToDollarString(maximumLevel1BalanceCents);
  const scripts: Script[] = [
    {
      scriptType: ScriptType.Dialogue,
      dialogue: `Your non-penalty APR on the first ${maxBalance} of purchases is ${basePurchaseAPRLevel1}%.`,
    },
    {
      scriptType: ScriptType.Dialogue,
      dialogue: `For the portion of your purchase balance over ${maxBalance}, your APR is ${basePurchaseAPRLevel2}%. For cash advances your APR is ${baseCashAPRLevel2}%.`,
    },
    ...upsetScriptsVariantE(
      nextPricingStrategyDate,
      nextPricingStrategyCitMethodId,
    ),
  ];

  return scripts;
};

export const termsScriptAPR14 = (
  tier2PenaltyAPR: string,
  maximumLevel1BalanceCents: number,
  basePurchaseAPRLevel1: string,
  baseCashAPRLevel1: string,
  basePurchaseAPRLevel2: string,
  baseCashAPRLevel2: string,
  nextPricingStrategyDate: string,
  nextPricingStrategyCitMethodId: string,
): Script[] => {
  const baseCashAPRLevel1Num = parseFloat(baseCashAPRLevel1);
  const baseCashAPRLevel2Num = parseFloat(baseCashAPRLevel2);
  const scripts: Script[] = [
    {
      scriptType: ScriptType.Dialogue,
      dialogue:
        `Your account is currently subject to the penalty rate of ${tier2PenaltyAPR}% because you failed to make the required ` +
        `minimum payment by the due date for that payment.`,
    },
    {
      scriptType: ScriptType.Instruction,
      instructions: [
        {
          text: `If the card member asks for specifics then you tell the date of when they went 60 days (90 FDR days) delinquent.`,
        },
      ],
    },
    {
      scriptType: ScriptType.Dialogue,
      dialogue: `The penalty rate will remain in effect until you make the required minimum payment by the due date for each of 6 consecutive billing cycles. After these conditions are satisfied, the non-penalty rate will be reinstated.`,
    },
  ];

  if (baseCashAPRLevel1Num === baseCashAPRLevel2Num) {
    scripts.push(
      ...termsScriptAPR21(
        maximumLevel1BalanceCents,
        basePurchaseAPRLevel1,
        basePurchaseAPRLevel2,
        baseCashAPRLevel2,
        nextPricingStrategyDate,
        nextPricingStrategyCitMethodId,
      ),
    );
  } else {
    const maxBalance = centsToDollarString(maximumLevel1BalanceCents);
    scripts.push(
      {
        scriptType: ScriptType.Dialogue,
        dialogue:
          `Your non-penalty APR on the first ${maxBalance} of purchases is ${basePurchaseAPRLevel1}%. ` +
          `On the first ${maxBalance} of cash your APR is ${baseCashAPRLevel1}%.`,
      },
      {
        scriptType: ScriptType.Dialogue,
        dialogue:
          `For the portion of your purchase balance ` +
          `over ${maxBalance}, your APR is ${basePurchaseAPRLevel2}%. For the portion of your cash advances over ${maxBalance}, your APR is ${baseCashAPRLevel2}%.`,
      },
    );
  }

  scripts.push(
    ...upsetScriptsVariantE(
      nextPricingStrategyDate,
      nextPricingStrategyCitMethodId,
    ),
  );

  return scripts;
};

export const termsScriptAPR17 = (
  tier2PenaltyAPR: string,
  introductoryPurchaseAPRRate: string,
  introductoryPurchaseExpirationMonths: string,
  introductoryCashAPRRate: string,
  introductoryPurchaseExpirationDate: string,
  basePurchaseAPRLevel1: string,
  baseCashAPRLevel1: string,
  monthlyBasePurchaseAPRLevel1: string,
  monthlyBaseCashAPRLevel1: string,
  interestGrace: InterestGrace,
  pricingStrategy: string,
  specialHandlingIndicator: string,
): Script[] => {
  const scripts: Script[] = [
    {
      scriptType: ScriptType.Dialogue,
      dialogue:
        `Your APR is ${introductoryPurchaseAPRRate}% for the first ${introductoryPurchaseExpirationMonths} months for purchases and ${introductoryCashAPRRate}% ` +
        `for cash from the account open date (Expires ${convertDateStringToMonthDayYearFomat(introductoryPurchaseExpirationDate)}). After the introductory offer, your APR for purchases is ${basePurchaseAPRLevel1}% ` +
        `and for cash is ${baseCashAPRLevel1}%. That amounts to a total interest charge of approximately $${monthlyBasePurchaseAPRLevel1} per month for every $100 ` +
        `you carry in an outstanding purchase balance and approximately $${monthlyBaseCashAPRLevel1} per month for every $100 you carry in an outstanding cash balance.` +
        getInterestGraceDialogue(interestGrace),
    },
    {
      scriptType: ScriptType.Instruction,
      instructions: [
        {
          text: `If customer upset/asks you don't have a lower rate? (no protected balance)`,
        },
      ],
    },
    ...getCustomerUpsetScriptsWithPricingAndSpecialHandling(
      pricingStrategy,
      specialHandlingIndicator,
    ),
    {
      scriptType: ScriptType.Instruction,
      instructions: [
        {
          text: `If customer asks why APR so high on full balance. (protected balance)`,
        },
      ],
    },
    {
      scriptType: ScriptType.Dialogue,
      dialogue: `Your entire account balance is currently subject to the penalty rate of ${tier2PenaltyAPR}% because you failed to make the required minimum payment by the due date for that payment.`,
    },
    {
      scriptType: ScriptType.Instruction,
      instructions: [
        {
          text: `If the card member asks for specifics then you tell the date of when they went 60 days (90 FDR days) delinquent.`,
        },
      ],
    },
    {
      scriptType: ScriptType.Dialogue,
      dialogue:
        `The penalty rate will remain in effect until you make the required minimum payment by the due date for each of 6 consecutive billing cycles. ` +
        `After these conditions are satisfied, the non-penalty rate on your protected balance will be reinstated if applicable.`,
    },
    {
      scriptType: ScriptType.Instruction,
      instructions: [
        {
          text: `If customer asks for non-penalty rate, review previous statements.`,
        },
      ],
    },
  ];

  return scripts;
};

export const termsScriptAPR15 = (
  tier2PenaltyAPR: string,
  introductoryPurchaseAPRRate: string,
  introductoryPurchaseExpirationMonths: string,
  introductoryCashAPRRate: string,
  introductoryPurchaseExpirationDate: string,
  basePurchaseAPRLevel1: string,
  baseCashAPRLevel1: string,
  monthlyBasePurchaseAPRLevel1: string,
  monthlyBaseCashAPRLevel1: string,
  interestGrace: InterestGrace,
  pricingStrategy: string,
  specialHandlingIndicator: string,
  nextPricingStrategyDate: string,
  nextPricingStrategyCitMethodId: string,
): Script[] => {
  const scripts: Script[] = [];
  const basePurchaseAPRLevel1Num = parseFloat(basePurchaseAPRLevel1);
  const tier2PenaltyAPRNum = parseFloat(tier2PenaltyAPR);

  if (basePurchaseAPRLevel1Num === tier2PenaltyAPRNum) {
    scripts.push(
      ...termsScriptAPR17(
        tier2PenaltyAPR,
        introductoryPurchaseAPRRate,
        introductoryPurchaseExpirationMonths,
        introductoryCashAPRRate,
        introductoryPurchaseExpirationDate,
        basePurchaseAPRLevel1,
        baseCashAPRLevel1,
        monthlyBasePurchaseAPRLevel1,
        monthlyBaseCashAPRLevel1,
        interestGrace,
        pricingStrategy,
        specialHandlingIndicator,
      ),
    );
  } else if (basePurchaseAPRLevel1Num < tier2PenaltyAPRNum) {
    scripts.push(
      {
        scriptType: ScriptType.Dialogue,
        dialogue:
          `Your account is currently subject to the penalty rate of ${tier2PenaltyAPR}% because you failed to make the required ` +
          `minimum payment by the due date for that payment.`,
      },
      {
        scriptType: ScriptType.Instruction,
        instructions: [
          {
            text: `If the card member asks for specifics then you tell the date of when they went 60 days (90 FDR days) delinquent.`,
          },
        ],
      },
      {
        scriptType: ScriptType.Dialogue,
        dialogue:
          `The penalty rate will remain in effect until you make the required minimum payment by the due date for each of 6 consecutive billing cycles. ` +
          `After these conditions are satisfied, the non-penalty rate will be reinstated.`,
      },
      {
        scriptType: ScriptType.Dialogue,
        dialogue:
          `Your non-penalty APR is ${introductoryPurchaseAPRRate}% for the first ${introductoryPurchaseExpirationMonths} months ` +
          `for purchases and ${introductoryCashAPRRate}% for cash from the account open date (Expires ${convertDateStringToMonthDayYearFomat(introductoryPurchaseExpirationDate)}). After the introductory offer, ` +
          `your APR for purchases is ${basePurchaseAPRLevel1}% and for cash is ${baseCashAPRLevel1}%.`,
      },
      ...upsetScriptsVariantE(
        nextPricingStrategyDate,
        nextPricingStrategyCitMethodId,
      ),
    );
  } else {
    scripts.push(
      {
        scriptType: ScriptType.Dialogue,
        dialogue: `Please hold while I review your account.`,
      },
      {
        scriptType: ScriptType.Instruction,
        instructions: [
          {
            text: `See Penalty Pricing Procedure for determining if the account was Late and/or OCL and when account will cure.`,
          },
        ],
      },
      {
        scriptType: ScriptType.Dialogue,
        dialogue: `After reviewing your account I see that as long as you continue to make on time payments your APR will return to the non-penalty rate in 6 months.`,
      },
      {
        scriptType: ScriptType.Instruction,
        instructions: [
          {
            text: `If the card member is upset CS transfer to 7666/ Coll transfer to 7697.`,
          },
        ],
      },
    );
  }

  return scripts;
};

export const termsScriptAPR26 = (
  currentPurchaseAPR: string,
  currentCashAPR: string,
  monthlyPurchaseAPR: string,
  monthlyCashAPR: string,
  interestGrace: InterestGrace,
): Script[] => [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      `Your account is currently eligible for the Service Members Civil Relief Act (SCRA) rate of ${currentPurchaseAPR}% for purchases and ${currentCashAPR}% for cash. ` +
      `That amounts to a total interest charge of approximately $${monthlyPurchaseAPR} per month for every $100 ` +
      `you carry in an outstanding purchase balance and approximately $${monthlyCashAPR} per month for every $100 you carry in an outstanding cash balance.` +
      getInterestGraceDialogue(interestGrace),
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `If Cardmember has further APR or Fee questions, transfer to Central Services at ext 6750.`,
      },
    ],
  },
];

export const getTermsScriptDetails = (
  termsResponse: GetTermsResponse,
): Script[] => {
  const { aprTerms, currentTerms } = termsResponse;

  const {
    purchaseAPR: basePurchaseAPRLevel1,
    cashAPR: baseCashAPRLevel1,
    monthlyBaseCashAPR: monthlyBaseCashAPRLevel1,
    monthlyBasePurchaseAPR: monthlyBasePurchaseAPRLevel1,
    maxBalanceCents: maxBalanceCentsLevel1,
  } = aprTerms.levels[0];

  switch (termsResponse.aprScript) {
    case "APR_001":
      return termsScriptAPR01(
        basePurchaseAPRLevel1,
        baseCashAPRLevel1,
        monthlyBasePurchaseAPRLevel1,
        monthlyBaseCashAPRLevel1,
        currentTerms.interestGrace,
        termsResponse.pricingStrategy,
        termsResponse.specialHandlingIndicator,
      );
    case "APR_003":
      return termsScriptAPR03(
        aprTerms.introductoryPurchaseAPRRate,
        aprTerms.introductoryPurchaseExpirationMonths,
        aprTerms.introductoryCashAPRRate,
        aprTerms.introductoryPurchaseExpirationDate,
        basePurchaseAPRLevel1,
        baseCashAPRLevel1,
        monthlyBasePurchaseAPRLevel1,
        monthlyBaseCashAPRLevel1,
        currentTerms.interestGrace,
        termsResponse.pricingStrategy,
        termsResponse.specialHandlingIndicator,
      );
    case "APR_004":
      return termsScriptAPR04(
        currentTerms.currentPurchaseAPR,
        currentTerms.currentCashAPR,
        termsResponse.newRateOnTransDate,
        aprTerms.tier1PenaltyAPR,
        termsResponse.nextPricingStrategyDate,
        basePurchaseAPRLevel1,
        baseCashAPRLevel1,
      );
    case "APR_006":
      return termsScriptAPR06(
        currentTerms.currentPurchaseAPR,
        currentTerms.currentCashAPR,
        termsResponse.newRateOnTransDate,
        aprTerms.tier1PenaltyAPR,
        basePurchaseAPRLevel1,
        baseCashAPRLevel1,
        aprTerms.introductoryPurchaseAPRRate,
        aprTerms.introductoryPurchaseExpirationMonths,
        aprTerms.introductoryCashAPRRate,
        aprTerms.introductoryPurchaseExpirationDate,
        termsResponse.nextPricingStrategyDate,
      );
    case "APR_007":
      return termsScriptAPR07(
        aprTerms.tier1PenaltyAPR,
        basePurchaseAPRLevel1,
        baseCashAPRLevel1,
      );

    case "APR_009":
      return termsScriptAPR09(
        aprTerms.tier1PenaltyAPR,
        basePurchaseAPRLevel1,
        baseCashAPRLevel1,
        aprTerms.introductoryPurchaseAPRRate,
        aprTerms.introductoryPurchaseExpirationMonths,
        aprTerms.introductoryCashAPRRate,
        aprTerms.introductoryPurchaseExpirationDate,
      );
    case "APR_010":
      return termsScriptAPR10(
        aprTerms.tier2PenaltyAPR,
        basePurchaseAPRLevel1,
        baseCashAPRLevel1,
        termsResponse.nextPricingStrategyDate,
        termsResponse.nextPricingStrategyCitMethodId,
        termsResponse.next2PricingStrategyEffectiveDate,
        termsResponse.next2PricingStrategyCitMethodId,
      );
    case "APR_012":
      return termsScriptAPR12(
        aprTerms.tier2PenaltyAPR,
        aprTerms.introductoryPurchaseAPRRate,
        aprTerms.introductoryPurchaseExpirationMonths,
        aprTerms.introductoryCashAPRRate,
        aprTerms.introductoryPurchaseExpirationDate,
        basePurchaseAPRLevel1,
        baseCashAPRLevel1,
        termsResponse.nextPricingStrategyDate,
        termsResponse.nextPricingStrategyCitMethodId,
        termsResponse.next2PricingStrategyEffectiveDate,
        termsResponse.next2PricingStrategyCitMethodId,
      );
    case "APR_013":
      return termsScriptAPR13(
        aprTerms.tier2PenaltyAPR,
        basePurchaseAPRLevel1,
        baseCashAPRLevel1,
        monthlyBasePurchaseAPRLevel1,
        monthlyBaseCashAPRLevel1,
        termsResponse.nextPricingStrategyDate,
        termsResponse.nextPricingStrategyCitMethodId,
        currentTerms.interestGrace,
        termsResponse.pricingStrategy,
        termsResponse.specialHandlingIndicator,
      );
    case "APR_015":
      return termsScriptAPR15(
        aprTerms.tier2PenaltyAPR,
        aprTerms.introductoryPurchaseAPRRate,
        aprTerms.introductoryPurchaseExpirationMonths,
        aprTerms.introductoryCashAPRRate,
        aprTerms.introductoryPurchaseExpirationDate,
        basePurchaseAPRLevel1,
        baseCashAPRLevel1,
        monthlyBasePurchaseAPRLevel1,
        monthlyBaseCashAPRLevel1,
        currentTerms.interestGrace,
        termsResponse.pricingStrategy,
        termsResponse.specialHandlingIndicator,
        termsResponse.nextPricingStrategyDate,
        termsResponse.nextPricingStrategyCitMethodId,
      );
    case "APR_017":
      return termsScriptAPR17(
        aprTerms.tier2PenaltyAPR,
        aprTerms.introductoryPurchaseAPRRate,
        aprTerms.introductoryPurchaseExpirationMonths,
        aprTerms.introductoryCashAPRRate,
        aprTerms.introductoryPurchaseExpirationDate,
        basePurchaseAPRLevel1,
        baseCashAPRLevel1,
        monthlyBasePurchaseAPRLevel1,
        monthlyBaseCashAPRLevel1,
        currentTerms.interestGrace,
        termsResponse.pricingStrategy,
        termsResponse.specialHandlingIndicator,
      );
    case "APR_026":
      return termsScriptAPR26(
        currentTerms.currentPurchaseAPR,
        currentTerms.currentCashAPR,
        currentTerms.monthlyCurrentPurchaseAPR,
        currentTerms.monthlyCurrentCashAPR,
        currentTerms.interestGrace,
      );
  }

  // scripts requiring additional nullable data; do not show script if data missing

  if (termsResponse.aprTerms.levels.length < 2) {
    return [];
  }

  const {
    purchaseAPR: basePurchaseAPRLevel2,
    cashAPR: baseCashAPRLevel2,
    monthlyBaseCashAPR: monthlyBaseCashAPRLevel2,
    monthlyBasePurchaseAPR: monthlyBasePurchaseAPRLevel2,
  } = aprTerms.levels[1];

  switch (termsResponse.aprScript) {
    case "APR_002":
      return termsScriptAPR02(
        maxBalanceCentsLevel1,
        basePurchaseAPRLevel1,
        baseCashAPRLevel1,
        monthlyBasePurchaseAPRLevel1,
        monthlyBaseCashAPRLevel1,
        basePurchaseAPRLevel2,
        baseCashAPRLevel2,
        monthlyBasePurchaseAPRLevel2,
        monthlyBaseCashAPRLevel2,
        currentTerms.interestGrace,
        termsResponse.pricingStrategy,
        termsResponse.specialHandlingIndicator,
      );
    case "APR_005":
      return termsScriptAPR05(
        currentTerms.currentPurchaseAPR,
        currentTerms.currentCashAPR,
        termsResponse.newRateOnTransDate,
        aprTerms.tier1PenaltyAPR,
        maxBalanceCentsLevel1,
        basePurchaseAPRLevel1,
        baseCashAPRLevel1,
        basePurchaseAPRLevel2,
        baseCashAPRLevel2,
        termsResponse.nextPricingStrategyDate,
      );
    case "APR_008":
      return termsScriptAPR08(
        aprTerms.tier1PenaltyAPR,
        maxBalanceCentsLevel1,
        basePurchaseAPRLevel1,
        baseCashAPRLevel1,
        basePurchaseAPRLevel2,
        baseCashAPRLevel2,
      );
    case "APR_011":
      return termsScriptAPR11(
        aprTerms.tier2PenaltyAPR,
        maxBalanceCentsLevel1,
        basePurchaseAPRLevel1,
        baseCashAPRLevel1,
        basePurchaseAPRLevel2,
        baseCashAPRLevel2,
        termsResponse.nextPricingStrategyDate,
        termsResponse.nextPricingStrategyCitMethodId,
        termsResponse.next2PricingStrategyEffectiveDate,
        termsResponse.next2PricingStrategyCitMethodId,
      );
    case "APR_014":
      return termsScriptAPR14(
        aprTerms.tier2PenaltyAPR,
        maxBalanceCentsLevel1,
        basePurchaseAPRLevel1,
        baseCashAPRLevel1,
        basePurchaseAPRLevel2,
        baseCashAPRLevel2,
        termsResponse.nextPricingStrategyDate,
        termsResponse.nextPricingStrategyCitMethodId,
      );
    case "APR_016":
      return termsScriptAPR16(
        basePurchaseAPRLevel1,
        baseCashAPRLevel1,
        monthlyBasePurchaseAPRLevel1,
        monthlyBaseCashAPRLevel1,
        currentTerms.interestGrace,
        aprTerms.tier2PenaltyAPR,
        termsResponse.pricingStrategy,
        termsResponse.specialHandlingIndicator,
      );
    case "APR_018":
      return termsScriptAPR18(
        maxBalanceCentsLevel1,
        basePurchaseAPRLevel1,
        baseCashAPRLevel1,
        monthlyBasePurchaseAPRLevel1,
        monthlyBaseCashAPRLevel1,
        basePurchaseAPRLevel2,
        monthlyBasePurchaseAPRLevel2,
        monthlyBaseCashAPRLevel2,
        currentTerms.interestGrace,
        termsResponse.pricingStrategy,
        termsResponse.specialHandlingIndicator,
      );
    case "APR_019":
      return termsScriptAPR19(
        maxBalanceCentsLevel1,
        basePurchaseAPRLevel1,
        basePurchaseAPRLevel2,
        baseCashAPRLevel2,
      );
    case "APR_020":
      return termsScriptAPR20(
        maxBalanceCentsLevel1,
        basePurchaseAPRLevel1,
        basePurchaseAPRLevel2,
        baseCashAPRLevel2,
        termsResponse.nextPricingStrategyDate,
        termsResponse.nextPricingStrategyCitMethodId,
        termsResponse.next2PricingStrategyEffectiveDate,
        termsResponse.next2PricingStrategyCitMethodId,
      );
    case "APR_021":
      return termsScriptAPR21(
        maxBalanceCentsLevel1,
        basePurchaseAPRLevel1,
        basePurchaseAPRLevel2,
        baseCashAPRLevel2,
        termsResponse.nextPricingStrategyDate,
        termsResponse.nextPricingStrategyCitMethodId,
      );
    case "APR_027":
      return termsScriptAPR27(
        maxBalanceCentsLevel1,
        basePurchaseAPRLevel1,
        baseCashAPRLevel1,
        monthlyBasePurchaseAPRLevel1,
        monthlyBaseCashAPRLevel1,
        basePurchaseAPRLevel2,
        baseCashAPRLevel2,
        monthlyBasePurchaseAPRLevel2,
        monthlyBaseCashAPRLevel2,
        currentTerms.interestGrace,
        termsResponse.pricingStrategy,
        termsResponse.specialHandlingIndicator,
      );
  }
};
