import classNames from "classnames";
import { DateString } from "../../../lib/api/models";
import styles from "./DateInput.module.css";

type DateInputProps = {
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  label: string;
  value: DateString;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDate?: (date: DateString) => void;
  onBlur?: React.DOMAttributes<HTMLInputElement>["onBlur"];
  min?: DateString;
  max?: DateString;
  validationMessages?: string[];
  variant?: "default" | "horizontal";
};

export default function DateInput(props: DateInputProps) {
  const invalid =
    !!props.validationMessages && props.validationMessages.length > 0;
  return (
    <div
      className={classNames(
        styles.container,
        props.variant === "horizontal" && styles.horizontal,
        props.className,
      )}
    >
      <label
        className={classNames(
          styles.label,
          props.variant === "horizontal" && styles.horizontal,
          props.labelClassName,
        )}
      >
        {props.label}
      </label>

      <input
        className={classNames(
          {
            [styles.input]: true,
            [styles.invalid]: invalid,
            [styles.horizontal]: props.variant === "horizontal",
          },
          props.inputClassName,
        )}
        type="date"
        aria-label={props.label}
        value={props.value}
        onChange={(event) => {
          props.onChange?.(event);
          props.onChangeDate?.(event.target.value as DateString);
        }}
        onBlur={props.onBlur}
        disabled={props.disabled}
        min={props.min}
        max={props.max}
      />
      {props.validationMessages && props.validationMessages.length > 0 ? (
        <div className={styles.validationMessages}>
          {props.validationMessages.join("\n")}
        </div>
      ) : null}
    </div>
  );
}
