import { ReactNode, useMemo, useState } from "react";
import { PhoneContact } from "../../../lib/api/models";
import {
  formatInCentralTime,
  INPUT_MASKS,
  maskInput,
} from "../../../lib/formatting";
import TextButton from "../../common/buttons/TextButton";
import StyledCheckbox from "../../common/StyledCheckbox";
import Table from "../../common/tables/Table";
import { TableColumn } from "../../common/tables/Table/Table";
import classNames from "classnames";
import styles from "./PhoneNumberTable.module.css";

const strings = {
  number: "Number",
  owner: "Owner",
  cellOwnerName: "Cell Owner Name",
  type: "Type",
  asc: "ASC",
  lastVerified: "Last Verified",
  lastCaptured: "Last Captured",
  source: "Source",
  edit: "Edit",
  view: "View",
  verify: "Verify",
  primary: "Primary",
  secondary: "Secondary",
  restricted: "R",
  yes: "Yes",
  no: "No",
  unknown: "Unknown",
};

const renderTableHeaderCell = (
  label: string,
  onClick: () => void,
  asc?: boolean,
) => {
  const triangleUpElement = <div className={styles.triangleUp} />;
  const triangleDownElement = <div className={styles.triangleDown} />;
  let sortIcon = (
    <>
      {triangleUpElement}
      {triangleDownElement}
    </>
  );
  if (asc !== undefined) {
    sortIcon = asc ? triangleUpElement : triangleDownElement;
  }
  return (
    <button type="button" className={styles.headerCell} onClick={onClick}>
      {label}
      <div className={styles.sortIcon}>{sortIcon}</div>
    </button>
  );
};

const renderAscBadge = (asc: PhoneContact["consentStatus"]) => {
  return (
    <div
      className={classNames(
        styles.badge,
        asc === "PEC_YES"
          ? styles.success
          : asc === "PEC_NO"
            ? styles.danger
            : styles.warning,
      )}
    >
      {asc === "PEC_YES"
        ? strings.yes
        : asc === "PEC_NO"
          ? strings.no
          : strings.unknown}
    </div>
  );
};

type Props = {
  headerClassName?: string;
  headerText: string;
  headerContent?: ReactNode;
  data: PhoneContact[];
  selectedToVerify: string[];
  onClickVerify: (id: string) => void;
};

export default function PhoneNumberTable({
  headerClassName,
  headerText,
  headerContent,
  data,
  selectedToVerify,
  onClickVerify,
}: Props) {
  const [sortByLastVerifiedAsc, setSortbyLastVerifiedAsc] = useState<boolean>();

  const sortedData = useMemo(() => {
    if (sortByLastVerifiedAsc === undefined) {
      return data;
    }

    return data.sort(
      (a, b) =>
        (a.lastVerifiedDate?.getTime() ?? 0) -
        (b.lastVerifiedDate?.getTime() ?? 0),
    );
  }, [data, sortByLastVerifiedAsc]);

  const columns: TableColumn<PhoneContact>[] = [
    {
      header: strings.number,
      size: 3,
      alignment: "left",
      render: (row) => (
        <div className={styles.statusBadge}>
          <span>{maskInput(INPUT_MASKS.phoneNumber, row.phoneNumber)}</span>
          {row.isPrimary ? (
            <div className={styles.primary}>{strings.primary}</div>
          ) : (
            row.isSecondary && (
              <div className={styles.secondary}>{strings.secondary}</div>
            )
          )}
          {row.restriction !== null && (
            <div className={styles.restricted}>{strings.restricted}</div>
          )}
        </div>
      ),
    },
    {
      header: strings.owner,
      size: 2,
      alignment: "left",
      render: (row) => <>{row.ownerType}</>,
    },
    {
      header: strings.cellOwnerName,
      size: 2,
      alignment: "left",
      render: (row) => (
        <>{`${row.ownerFirstName} ${row.ownerLastName} ${row.ownerSuffix ?? ""}`}</>
      ),
    },
    {
      header: strings.type,
      size: 1,
      alignment: "left",
      render: (row) => <>{row.phoneType}</>,
    },
    {
      header: strings.asc,
      size: 1,
      alignment: "left",
      render: (row) => renderAscBadge(row.consentStatus),
    },
    {
      header: renderTableHeaderCell(
        strings.lastVerified,
        () =>
          setSortbyLastVerifiedAsc((prev) => {
            if (prev === undefined) {
              return true;
            }
            return prev ? !prev : undefined;
          }),
        sortByLastVerifiedAsc,
      ),
      size: 2,
      alignment: "left",
      render: (row) => (
        <>
          {row.lastVerifiedDate &&
            formatInCentralTime(row.lastVerifiedDate, "MM/dd/yyyy")}
        </>
      ),
    },
    {
      header: strings.lastCaptured,
      size: 2,
      alignment: "left",
      render: (row) => (
        <>{formatInCentralTime(row.lastCapturedDate, "MM/dd/yyyy")}</>
      ),
    },
    {
      header: strings.source,
      size: 2,
      alignment: "left",
      render: (row) => (
        <>{row.sourceType.sort((a, b) => b.count - a.count)[0].source}</>
      ),
    },
    {
      header: strings.edit,
      size: 1,
      alignment: "left",
      render: () => (
        <TextButton className={styles.textButton}>{strings.edit}</TextButton> // TODO: onClick
      ),
    },
    {
      header: strings.view,
      size: 1,
      alignment: "left",
      render: () => (
        <TextButton className={styles.textButton}>{strings.view}</TextButton> // TODO: onClick
      ),
    },
    {
      header: strings.verify,
      size: 0.5,
      alignment: "left",
      render: (row) => (
        <StyledCheckbox
          label=""
          onChange={() => onClickVerify(row.phoneID)}
          checked={selectedToVerify.includes(row.phoneID)}
        />
      ),
    },
  ];

  return (
    <div>
      <div className={classNames(styles.tableHeader, headerClassName)}>
        <span>{headerText}</span>
        {headerContent}
      </div>
      <Table columns={columns} data={sortedData} className={styles.table} />
    </div>
  );
}
