import { useEffect, useId, useState } from "react";
import { Col, Row } from "react-grid-system";
import { useSearchParams } from "react-router-dom";
import { HTTPError, getDisplayError } from "../../lib/api/endpoints";
import { useGetAccount } from "../../lib/api/queries";
import {
  AccountFound,
  AccountNotFound,
  SearchForm,
} from "../../modules/AccountSearch";
import Container from "../../modules/common/Container";
import { NetworkErrorModal } from "../../modules/common/modals";
import styles from "./AccountSearchPage.module.css";

const strings = {
  searchById: "Search by Account ID",
  enterAccountId: "Enter Account ID",
};

const dwbuidSearchParamKey = "dwbuid";

const AccountSearchPage = () => {
  const labelId = useId();
  const [searchParams, setSearchParams] = useSearchParams();
  const accountSearchQuery = useGetAccount(
    searchParams.get(dwbuidSearchParamKey) ?? "",
  );

  const onSearchDwbuid = (dwbuid: string) => {
    if (dwbuid !== searchParams.get(dwbuidSearchParamKey)) {
      setSearchParams({ [dwbuidSearchParamKey]: dwbuid });
    } else {
      accountSearchQuery.refetch();
    }
  };
  const [displayError, setDisplayError] = useState<string>();
  const is404Error =
    accountSearchQuery.error instanceof HTTPError &&
    accountSearchQuery.error.status === 404;
  useEffect(() => {
    if (accountSearchQuery.error && !is404Error) {
      setDisplayError(getDisplayError(accountSearchQuery.error));
    }
  }, [accountSearchQuery.error, is404Error]);
  return (
    <main className={styles.page}>
      <Container>
        <Row>
          <Col xs={3} offset={{ xs: 3 }}>
            <h1 id={labelId} className={styles.heading}>
              {strings.searchById}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col xs={6} offset={{ xs: 3 }}>
            <SearchForm
              currentValue={searchParams.get(dwbuidSearchParamKey)}
              isLoading={accountSearchQuery.isFetching}
              labelId={labelId}
              onSubmit={onSearchDwbuid}
              placeholder={strings.enterAccountId}
            />
          </Col>
        </Row>
        {accountSearchQuery.data ? (
          <Row>
            <Col xs={6} offset={{ xs: 3 }}>
              <AccountFound
                account={accountSearchQuery.data}
                className={styles.card}
                dwbuid={accountSearchQuery.data.currentDwbuid}
              />
            </Col>
          </Row>
        ) : is404Error ? (
          <Row>
            <Col xs={6} offset={{ xs: 3 }}>
              <AccountNotFound className={styles.card} />
            </Col>
          </Row>
        ) : null}
      </Container>

      <NetworkErrorModal
        message={displayError}
        onRequestClose={() => setDisplayError(undefined)}
      />
    </main>
  );
};

export default AccountSearchPage;
