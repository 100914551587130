import StyledButton from "../../common/buttons/StyledButton";
import styles from "./FeesTable.module.css";
import { GetTermsResponse } from "../../../lib/api/models";
import {
  centsToDollarString,
  convertDateStringToMonthDayYearFomat,
  convertYearMonthDateStringToMonthYearFormat,
} from "../../../lib/formatting";
import { TermsScriptType } from "../Terms";

const strings = {
  fees: "Fees",
  annualFee: "Annual Fee",
  cardFeeDesc: "Card Fee Desc",
  cardFeeAmount: "Card Fee Amt",
  amountLastCharged: "AF/MF Amount Last Charged",
  lastChargedDate: "AF/MF Last Charged Date",
  annualFeeCharge: "Annual Fee Charge Month/Year",
  returnedCheckFee: "Returned Check Fee",
  cashAdvanceFee: "Cash Advance Fee",
  overLimit: "Over Limit Service",
  showScript: "Show Script",
  hideScript: "Hide Script",
  lastChargedDatePlaceholder: "00000000",
  chargeMonthYearPlaceholder: "0000",
};

type Props = {
  getTermsResponse: GetTermsResponse;
  scriptToShow: TermsScriptType | undefined;
  setScriptToShow: (s: TermsScriptType | undefined) => void;
};

export default function FeesTable({
  getTermsResponse,
  scriptToShow,
  setScriptToShow,
}: Props) {
  const { fees } = getTermsResponse;

  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.headerRow}>
          <th>
            <span className={styles.header}>{strings.fees}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className={styles.oddRow}>
          <td>
            <span>{strings.annualFee}</span>
          </td>
          <td>
            <span>{fees.annualFee}</span>
          </td>
        </tr>
        <tr className={styles.evenRow}>
          <td>
            <span>{strings.cardFeeDesc}</span>
          </td>
          <td>
            <span>{fees.cardFeeDesc}</span>
          </td>
        </tr>
        <tr className={styles.oddRow}>
          <td>
            <span>{strings.cardFeeAmount}</span>
          </td>
          <td>
            <span>{fees.cardFeeAmount}</span>
          </td>
        </tr>
        <tr className={styles.evenRow}>
          <td>
            <span>{strings.amountLastCharged}</span>
          </td>
          <td>
            <span>
              {centsToDollarString(fees.feeAmountLastChargedCents, false, true)}
            </span>
          </td>
        </tr>
        <tr className={styles.oddRow}>
          <td>
            <span>{strings.lastChargedDate}</span>
          </td>
          <td>
            <span>
              {fees.feeLastChargedDate
                ? convertDateStringToMonthDayYearFomat(fees.feeLastChargedDate)
                : strings.lastChargedDatePlaceholder}
            </span>
          </td>
        </tr>
        <tr className={styles.evenRow}>
          <td>
            <span>{strings.annualFeeCharge}</span>
          </td>
          <td>
            <span>
              {fees.feeChargeMonthYear
                ? convertYearMonthDateStringToMonthYearFormat(
                    fees.feeChargeMonthYear,
                  )
                : strings.chargeMonthYearPlaceholder}
            </span>
          </td>
        </tr>
        <tr className={styles.oddRow}>
          <td>
            <span>{strings.returnedCheckFee}</span>
          </td>
          <td>
            <span>{fees.returnedCheckFee}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>{strings.cashAdvanceFee}</span>
          </td>
          <td>
            <div className={styles.scriptButtonContainer}>
              <span>{fees.cashAdvanceFeeAmount}</span>
              <StyledButton
                onClick={() =>
                  scriptToShow === "fees"
                    ? setScriptToShow(undefined)
                    : setScriptToShow("fees")
                }
                variant="transparent"
                className={styles.cashScriptButton}
              >
                {scriptToShow === "fees"
                  ? strings.hideScript
                  : strings.showScript}
              </StyledButton>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
