import styles from "./Tooltip.module.css";
import tooltipIcon from "../../../assets/tooltip-icon.svg";

type Props = {
  children: React.ReactNode;
};

export default function Tooltip({ children }: Props) {
  return (
    <div className={styles.container}>
      <img src={tooltipIcon} alt="" />
      <div className={styles.content}>{children}</div>
    </div>
  );
}
