import classNames from "classnames";
import { nanoid } from "nanoid";
import dropDownIcon from "../../../assets/drop-down.svg";
import styles from "./Select.module.css";
import StyledButton from "../buttons/StyledButton";
import { useEffect, useState } from "react";

type Props<T extends string> = {
  className?: string;
  contentClassName?: string;
  selectedLabelClassName?: string;
  labelClassName?: string;
  options: Array<{
    key?: string;
    label: string;
    value: T;
  }>;
  validationMessages?: string[];
  onBlur?: () => void;
  variant?: "default" | "horizontal";
} & (
  | {
      disabled?: boolean;
      onChange: (value: T) => void;
    }
  | { disabled: true; onChange?: (value: T) => void }
) &
  (
    | {
        selectedValue?: T;
        placeholder: string;
      }
    | {
        selectedValue: T;
        placeholder?: string;
      }
  ) &
  (
    | {
        label: string;
        ariaLabel?: never;
      }
    | {
        label?: never;
        ariaLabel: string;
      }
  );

export default function Select<T extends string>({
  className,
  contentClassName,
  selectedLabelClassName,
  labelClassName,
  options,
  onChange,
  disabled,
  selectedValue,
  placeholder,
  label,
  ariaLabel,
  validationMessages,
  onBlur,
  variant = "default",
}: Props<T>) {
  const [hideValidation, setHideValidation] = useState(false);

  const selectedOptionIndex = options.findIndex(
    (option) => option.value === selectedValue,
  );
  const id = nanoid();

  const invalid = !!validationMessages && validationMessages.length > 0;

  useEffect(() => {
    setHideValidation(false);
  }, [validationMessages]);

  return (
    <div
      className={classNames(
        {
          [styles.container]: true,
          [styles.horizontal]: variant === "horizontal",
        },
        className,
      )}
    >
      {label ? (
        <label
          className={classNames(
            {
              [styles.label]: true,
              [styles.disabled]: disabled,
              [styles.horizontal]: variant === "horizontal",
            },
            labelClassName,
          )}
          htmlFor={id}
        >
          {label}
        </label>
      ) : null}
      <div
        className={classNames({
          [styles.selectContainer]: true,
          [styles.disabledContainer]: disabled,
          [styles.horizontal]: variant === "horizontal",
        })}
      >
        <select
          className={classNames({
            [styles.select]: true,
            [styles.invalid]: invalid,
          })}
          value={selectedValue}
          onChange={
            disabled
              ? undefined
              : (e) => {
                  const value = e.target.value as T;
                  if (value !== placeholder) {
                    onChange(value);
                  }
                }
          }
          disabled={disabled}
          aria-label={ariaLabel}
          id={id}
          onBlur={onBlur}
          onFocus={() => setHideValidation(false)}
        >
          {placeholder ? (
            <option className={styles.options} value={placeholder}>
              {placeholder}
            </option>
          ) : null}
          {options.map((option) => (
            <option
              key={option.key ?? option.value}
              value={option.value}
              className={styles.options}
            >
              {option.label}
            </option>
          ))}
        </select>
        <div className={classNames(styles.content, contentClassName)}>
          <div
            className={classNames(styles.selectedLabel, selectedLabelClassName)}
          >
            {selectedOptionIndex !== -1
              ? options[selectedOptionIndex].label
              : placeholder}
          </div>
          <img src={dropDownIcon} alt="" />
        </div>
      </div>
      {validationMessages &&
      validationMessages.length > 0 &&
      !hideValidation ? (
        <div className={styles.validationMessages}>
          <div>{validationMessages.join("\n")}</div>

          <StyledButton
            className={styles.closeValidationButton}
            variant="transparent"
            onClick={() => setHideValidation(true)}
          >
            {"X"}
          </StyledButton>
        </div>
      ) : null}
    </div>
  );
}
