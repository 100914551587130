import {
  ContactId,
  ShipReplacementTo,
  ReplacementType,
  TypeOfLoss,
} from "./FieldIds";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-grid-system";
import { ToolType } from "../AccountDetails";
import { toolTypeToName } from "../AccountDetails/utils";
import Container from "../common/Container";
import StyledButton from "../common/buttons/StyledButton";
import styles from "./LostStolen.module.css";
import LostStolenForm from "./LostStolenForm";
import {
  DateString,
  GetAccountResponse,
  LostStolenScript,
} from "../../lib/api/models";
import {
  useGetLostStolenOptions,
  useSubmitLostStolen,
} from "../../lib/api/queries";
import Spinner from "../common/Spinner";
import StyledError from "../StyledError";
import { getLostStolenScriptDetails } from "../../lib/scripts/lostStolen/lostStolenScripts";
import ConversationScript from "../common/ConversationScript";
import StyledCheckbox from "../common/StyledCheckbox";
import StyledRadioButton from "../StyledRadioButton";

const strings = {
  submit: "Submit",
  close: "Close",
  errorTitle: "Failed to load Lost/Stolen",
  successful: "Lost Report Successful",
  ok: "OK",
  emergencyCard: "Emergency Replacement Card",
  traveling: "Traveling in the next 5 business days?",
  yes: "Yes",
  no: "No",
};

type FinalInfo = {
  replacementOption?: ReplacementType;
  waiveReplacementFee?: boolean;
  waiveRushFee?: boolean;
  dateLost?: DateString;
  lossLocation?: string;
  stateOfLoss?: string;
  pinCompromised?: boolean;
  fraudCharges?: string;
};

type ShipReplacementToBilling = {
  addressChangeToday?: boolean;
  newAddressVerify?: boolean;
  cid?: string;

  finalInfo?: FinalInfo;
};

type ShipReplacementToTemporary = {
  cid?: string;
  tempAddressVerify?: boolean;
  reasonForTempAddress?: string;
  tempAddress?: {
    address1?: string;
    address2?: string;
    zip?: string;
    city?: string;
    state?: string;
  };

  finalInfo?: FinalInfo;
};

type EarlyTerminationInfo = {
  additionalInfo?: string;
  shipReplacementTo?: ShipReplacementTo;

  finalInfo?: FinalInfo;
};

export type LostStolenFormState = {
  contactId?: ContactId;
  typeOfLoss?: TypeOfLoss;
  fraudOnAccount?: boolean;

  earlyTermination?: EarlyTerminationInfo;

  yesFraudOnAccount?: {
    transferToSecurity?: boolean;
    cid?: string;
    bestWayToContact?: string;
    shipReplacementTo?: ShipReplacementTo;
    billing?: ShipReplacementToBilling;
    temporary?: ShipReplacementToTemporary;
  };

  noFraudOnAccount?: {
    shipReplacementTo?: ShipReplacementTo;
    billing?: ShipReplacementToBilling;
    temporary?: ShipReplacementToTemporary;
  };
};

type Props = {
  className?: string;
  toolId: string;
  account: GetAccountResponse;
  onRequestClose: (bypassProgressConfirmation?: boolean) => void;
  onSetToolInProgress: (id: string, inProgress: boolean) => void;
  onSuccess: (summaryWithoutTime: string) => void;
};

export default function LostStolen({
  className,
  toolId,
  account,
  onRequestClose,
  onSetToolInProgress,
  onSuccess,
}: Props) {
  const [formState, setFormState] = useState<LostStolenFormState>({});
  const [traveling, setTraveling] = useState<boolean>();
  const [emergencyCardChecked, setEmergencyCardChecked] = useState(false);

  const {
    data: getLostStolenOptionsResponse,
    isLoading: isGetLostStolenOptionsResponseLoading,
    isError: isGetLostStolenOptionsResponseError,
    error: getLostStolenOptionsResponseError,
    refetch: getLostStolenOptionsResponseRefetch,
  } = useGetLostStolenOptions(account.currentDwbuid);

  useEffect(() => {
    if (!getLostStolenOptionsResponse) {
      return;
    }

    if (
      formState.contactId &&
      !(
        getLostStolenOptionsResponse.isLoanAccount &&
        (formState.contactId === ContactId.Cardmember ||
          formState.contactId === ContactId.Merchant ||
          formState.contactId === ContactId.ThirdPartyUnknownToCM)
      )
    ) {
      onSetToolInProgress(toolId, true);
    }
  }, [
    formState.contactId,
    onSetToolInProgress,
    toolId,
    getLostStolenOptionsResponse,
  ]);

  const {
    mutateAsync: submitLostStolen,
    isLoading: isSubmitting,
    isSuccess,
    isError,
  } = useSubmitLostStolen();

  const scriptDetails = useMemo(() => {
    if (getLostStolenOptionsResponse) {
      let scriptName: LostStolenScript | undefined = undefined;
      if (isError) {
        scriptName = "LS0010";
      } else if (formState.contactId) {
        if (isSuccess) {
          if (
            formState.contactId === ContactId.Merchant ||
            formState.contactId === ContactId.ThirdPartyUnknownToCM
          ) {
            scriptName = "LS0033";
          } else if (
            formState.contactId === ContactId.AuthUser ||
            formState.contactId === ContactId.Other ||
            formState.contactId === ContactId.Parent ||
            formState.contactId === ContactId.ThirdPartyKnownToCM
          ) {
            scriptName = "LS0029";
          } else if (getLostStolenOptionsResponse.externalStatus === "") {
            scriptName = "LS0002";
          }
        } else if (
          getLostStolenOptionsResponse.isLoanAccount &&
          (formState.contactId === ContactId.Cardmember ||
            formState.contactId === ContactId.Merchant ||
            formState.contactId === ContactId.ThirdPartyUnknownToCM)
        ) {
          scriptName = "LS0006";
        } else if (
          formState.contactId === ContactId.Merchant ||
          formState.contactId === ContactId.ThirdPartyUnknownToCM
        ) {
          scriptName = "LS0038";
        } else if (
          formState.fraudOnAccount === false &&
          formState.contactId === ContactId.Cardmember &&
          (getLostStolenOptionsResponse.externalStatus === "" ||
            (getLostStolenOptionsResponse.externalStatus === "A" &&
              !getLostStolenOptionsResponse.isClosed))
        ) {
          scriptName = "LS0011";
        } else if (
          formState.contactId === ContactId.ThirdPartyKnownToCM &&
          (getLostStolenOptionsResponse.externalStatus === "" ||
            (getLostStolenOptionsResponse.externalStatus === "A" &&
              !getLostStolenOptionsResponse.isClosed))
        ) {
          scriptName = "LS0020";
        } else if (formState.contactId === ContactId.Cardmember) {
          if (formState.typeOfLoss === TypeOfLoss.FraudApp) {
            scriptName = "LS0039";
          } else if (
            getLostStolenOptionsResponse.externalStatus === "B" ||
            getLostStolenOptionsResponse.externalStatus === "Z"
          ) {
            scriptName = "LS0007";
          } else if (
            getLostStolenOptionsResponse.externalStatus === "L" ||
            getLostStolenOptionsResponse.externalStatus === "U"
          ) {
            scriptName = "LS0009";
          } else if (
            getLostStolenOptionsResponse.externalStatus === "A" &&
            !getLostStolenOptionsResponse.isClosed
          ) {
            scriptName = "LS0024";
          } else if (
            getLostStolenOptionsResponse.isClosed ||
            getLostStolenOptionsResponse.externalStatus === "C" ||
            getLostStolenOptionsResponse.externalStatus === "E" ||
            getLostStolenOptionsResponse.externalStatus === "F" ||
            getLostStolenOptionsResponse.externalStatus === "I"
          ) {
            scriptName = "LS0019";
          }
        } else if (
          formState.contactId === ContactId.AuthUser ||
          formState.contactId === ContactId.Parent ||
          formState.contactId === ContactId.ThirdPartyKnownToCM ||
          formState.contactId === ContactId.Other
        ) {
          if (
            getLostStolenOptionsResponse.externalStatus === "B" ||
            getLostStolenOptionsResponse.externalStatus === "Z" ||
            getLostStolenOptionsResponse.externalStatus === "L" ||
            getLostStolenOptionsResponse.externalStatus === "U" ||
            getLostStolenOptionsResponse.isClosed ||
            getLostStolenOptionsResponse.externalStatus === "C" ||
            getLostStolenOptionsResponse.externalStatus === "E" ||
            getLostStolenOptionsResponse.externalStatus === "F" ||
            getLostStolenOptionsResponse.externalStatus === "I"
          ) {
            scriptName = "LS0008";
          } else if (formState.fraudOnAccount === false) {
            scriptName = "LS0011";
          }
        }
      }

      return getLostStolenScriptDetails(
        formState,
        getLostStolenOptionsResponse,
        traveling ?? false,
        emergencyCardChecked,
        scriptName,
      );
    }

    return undefined;
  }, [
    getLostStolenOptionsResponse,
    formState,
    isSuccess,
    isError,
    emergencyCardChecked,
    traveling,
  ]);

  function onSubmit() {
    if (!formState.contactId) {
      return;
    }

    const fraudOnAccount = !!formState.fraudOnAccount;
    const useBillingAddress =
      formState.yesFraudOnAccount?.shipReplacementTo ===
        ShipReplacementTo.Billing ||
      formState.noFraudOnAccount?.shipReplacementTo ===
        ShipReplacementTo.Billing;
    const tempAddress =
      formState.yesFraudOnAccount?.temporary?.tempAddress ??
      formState.noFraudOnAccount?.temporary?.tempAddress;
    const finalInfo = formState.earlyTermination
      ? formState.earlyTermination.finalInfo
      : fraudOnAccount
        ? useBillingAddress
          ? formState.yesFraudOnAccount?.billing?.finalInfo
          : formState.yesFraudOnAccount?.temporary?.finalInfo
        : useBillingAddress
          ? formState.noFraudOnAccount?.billing?.finalInfo
          : formState.noFraudOnAccount?.temporary?.finalInfo;

    submitLostStolen({
      dwbuid: account.currentDwbuid,
      addressChange: fraudOnAccount
        ? formState.yesFraudOnAccount?.billing?.addressChangeToday
        : formState.noFraudOnAccount?.billing?.addressChangeToday,
      cid: fraudOnAccount
        ? formState.yesFraudOnAccount?.cid
          ? formState.yesFraudOnAccount.cid
          : useBillingAddress
            ? formState.yesFraudOnAccount?.billing?.cid
            : formState.yesFraudOnAccount?.temporary?.cid
        : useBillingAddress
          ? formState.noFraudOnAccount?.billing?.cid
          : formState.noFraudOnAccount?.temporary?.cid,
      contactId: formState.contactId,
      dateLost: finalInfo?.dateLost,
      lossLocationId: finalInfo?.lossLocation,
      lossStateId: finalInfo?.stateOfLoss,
      pinCompromised: finalInfo?.pinCompromised,
      replacementOptionId: finalInfo?.replacementOption,
      shipToId: formState.earlyTermination
        ? formState.earlyTermination.shipReplacementTo
        : fraudOnAccount
          ? formState.yesFraudOnAccount?.shipReplacementTo
          : formState.noFraudOnAccount?.shipReplacementTo,
      typeOfLossId: formState.typeOfLoss,
      waiveReplacementFee: finalInfo?.waiveReplacementFee,
      waiveRushFee: finalInfo?.waiveRushFee,
      additionalInfo: formState.earlyTermination?.additionalInfo
        ? formState.earlyTermination.additionalInfo
        : fraudOnAccount
          ? useBillingAddress
            ? formState.yesFraudOnAccount?.billing?.finalInfo?.fraudCharges
            : formState.yesFraudOnAccount?.temporary?.finalInfo?.fraudCharges
          : undefined,
      bestWayToContactTypeId: fraudOnAccount
        ? formState.yesFraudOnAccount?.bestWayToContact
        : undefined,
      address: !useBillingAddress
        ? {
            addressLine1: tempAddress?.address1,
            addressLine2: tempAddress?.address2,
            city: tempAddress?.city,
            state: tempAddress?.state,
            zip: tempAddress?.zip,
          }
        : undefined,
    }).then(() => {
      onSetToolInProgress(toolId, false);
    });
  }

  function renderTitle() {
    return (
      <h2 className={styles.header}>{toolTypeToName(ToolType.LostStolen)}</h2>
    );
  }

  const finalInfo = formState.earlyTermination
    ? formState.earlyTermination.finalInfo
    : formState.fraudOnAccount
      ? formState.yesFraudOnAccount?.shipReplacementTo ===
        ShipReplacementTo.Billing
        ? formState.yesFraudOnAccount?.billing?.finalInfo
        : formState.yesFraudOnAccount?.temporary?.finalInfo
      : formState.noFraudOnAccount?.shipReplacementTo ===
          ShipReplacementTo.Billing
        ? formState.noFraudOnAccount?.billing?.finalInfo
        : formState.noFraudOnAccount?.temporary?.finalInfo;

  return (
    <div className={classNames(styles.mainContainer, className)} id={toolId}>
      <Container>
        {isGetLostStolenOptionsResponseLoading ? (
          <>
            <Row>
              <Col>{renderTitle()}</Col>
            </Row>
            <Row>
              <Col>
                <Spinner />
              </Col>
            </Row>
          </>
        ) : isGetLostStolenOptionsResponseError ? (
          <>
            <Row>
              <Col>{renderTitle()}</Col>
            </Row>
            <Row>
              <Col>
                <StyledError
                  errorTitle={strings.errorTitle}
                  error={getLostStolenOptionsResponseError}
                  refetch={getLostStolenOptionsResponseRefetch}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col md={9}>{renderTitle()}</Col>
              <Col md={2}>
                <StyledButton
                  className={styles.submitButton}
                  isLoading={isSubmitting}
                  onClick={onSubmit}
                  disabled={
                    isSuccess ||
                    // very limited access and state of loss not selected
                    ((formState.contactId === ContactId.Merchant ||
                      formState.contactId ===
                        ContactId.ThirdPartyUnknownToCM) &&
                      formState.earlyTermination?.finalInfo?.stateOfLoss ===
                        undefined) ||
                    // OR lost/stolen final fields not filled in
                    (formState.contactId !== ContactId.Merchant &&
                      formState.contactId !== ContactId.ThirdPartyUnknownToCM &&
                      (finalInfo?.waiveReplacementFee === undefined ||
                        ((finalInfo?.replacementOption ===
                          ReplacementType.RushFedex ||
                          finalInfo?.replacementOption ===
                            ReplacementType.RushPostal) &&
                          finalInfo?.waiveRushFee === undefined) ||
                        finalInfo?.dateLost === undefined ||
                        finalInfo?.lossLocation === undefined ||
                        finalInfo?.stateOfLoss === undefined ||
                        finalInfo?.pinCompromised === undefined ||
                        (formState.fraudOnAccount &&
                          finalInfo?.fraudCharges === undefined)))
                  }
                >
                  {strings.submit}
                </StyledButton>
              </Col>
              <Col md={1}>
                <StyledButton
                  className={styles.closeButton}
                  variant="secondary"
                  onClick={() => onRequestClose()}
                >
                  {strings.close}
                </StyledButton>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.divider} />
              </Col>
            </Row>
            <Row className={styles.row}>
              <Col md={4}>
                {isSuccess ? (
                  <div>
                    {formState.contactId === ContactId.Cardmember ? (
                      <>
                        <div className={styles.radioContainer}>
                          <div className={styles.label}>
                            {strings.traveling}
                          </div>
                          <div>
                            <StyledRadioButton
                              className={styles.leftRadioInput}
                              checked={!!traveling}
                              label={strings.yes}
                              onChange={() => setTraveling(true)}
                            />
                            <StyledRadioButton
                              checked={traveling !== undefined && !traveling}
                              label={strings.no}
                              onChange={() => setTraveling(false)}
                            />
                          </div>
                        </div>
                        <div className={styles.divider} />
                      </>
                    ) : null}
                    <StyledCheckbox
                      className={styles.emergencyCardCheckbox}
                      checked={emergencyCardChecked}
                      onChange={(e) =>
                        setEmergencyCardChecked(e.target.checked)
                      }
                      label={strings.emergencyCard}
                    />
                    <StyledButton
                      onClick={() =>
                        onSuccess("Successfully submitted lost/stolen report")
                      }
                    >
                      {strings.ok}
                    </StyledButton>
                  </div>
                ) : (
                  <LostStolenForm
                    closeTool={() =>
                      onSuccess(
                        "Successfully transferred lost/stolen report to security",
                      )
                    }
                    formState={formState}
                    setFormState={setFormState}
                    options={getLostStolenOptionsResponse}
                    isSubmitting={isSubmitting}
                  />
                )}
              </Col>
              <Col md={8}>
                {scriptDetails ? (
                  <ConversationScript
                    className={styles.scriptContainer}
                    scripts={scriptDetails.script}
                    scriptName={scriptDetails.name}
                  />
                ) : null}
              </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
}
