import classNames from "classnames";
import { Col, Container, Row } from "react-grid-system";
import { toolTypeToName } from "../AccountDetails/utils";
import { ToolType } from "../AccountDetails";
import StyledButton from "../common/buttons/StyledButton";
import RoundButton from "../common/buttons/RoundButton";
import { useEffect, useMemo, useRef, useState } from "react";
import APRTable from "./APRTable";
import CurrentTermsTable from "./CurrentTermsTable";
import FeesTable from "./FeesTable";
import { useGetTerms } from "../../lib/api/queries";
import {
  GetAccountResponse,
  GetTermsResponse,
  Script,
} from "../../lib/api/models";
import StyledError from "../StyledError";
import Spinner from "../common/Spinner";
import {
  getTermsScriptDetails,
  termsScriptCash01,
  termsScriptRewards,
} from "../../lib/scripts/terms/termsScripts";
import ConversationScript from "../common/ConversationScript";
import { HTTPError } from "../../lib/api/endpoints";
import alertIcon from "../../assets/alert-icon.svg";
import { reportWarning } from "../../lib/bugReporting";
import styles from "./Terms.module.css";

const DF01_ERROR = 404;

const strings = {
  currentTerms: "Current Terms",
  aprTerms: "APR Terms",
  fees: "Fees",
  close: "Close",
  errorTitle: "Failed to load Terms Requirements",
  df01Error:
    "The pricing strategy is temporary. Please contact the help desk for assistance.",
  A0381Warning:
    "A_0381 WARNING: The APR script is unavailable due to external status.",
};

type TermsScriptDetails = {
  script: Script[];
  name: string;
};

type Props = {
  account: GetAccountResponse;
  toolId: string;
  onRequestClose: () => void;
  className?: string;
};

export type TermsScriptType = "apr" | "rewards" | "fees";

export default function Terms({
  account,
  toolId,
  onRequestClose,
  className,
}: Props) {
  const [currentTab, setCurrentTab] = useState<"current" | "apr" | "fees">(
    "current",
  );
  const [scriptToShow, setScriptToShow] = useState<TermsScriptType>();

  const {
    data: getTermsResponse,
    isLoading: isGetTermsResponseLoading,
    isError: isGetTermsResponseError,
    error: getTermsResponseError,
    refetch: getTermsResponseRefetch,
  } = useGetTerms(account.currentDwbuid);

  function renderTitle() {
    return <h2 className={styles.header}>{toolTypeToName(ToolType.Terms)}</h2>;
  }

  const renderTable = (termsResponse: GetTermsResponse): React.ReactElement => {
    switch (currentTab) {
      case "current":
        return (
          <CurrentTermsTable
            getTermsResponse={termsResponse}
            scriptToShow={scriptToShow}
            setScriptToShow={setScriptToShow}
          />
        );
      case "apr":
        return <APRTable getTermsResponse={termsResponse} />;
      case "fees":
        return (
          <FeesTable
            getTermsResponse={termsResponse}
            scriptToShow={scriptToShow}
            setScriptToShow={setScriptToShow}
          />
        );
    }
  };

  const scriptDetails = useMemo((): TermsScriptDetails | undefined => {
    if (!getTermsResponse || !scriptToShow) {
      return undefined;
    }

    switch (scriptToShow) {
      case "apr":
        return {
          script: getTermsScriptDetails(getTermsResponse),
          name: getTermsResponse.aprScript,
        };
      case "fees":
        return {
          script: termsScriptCash01(
            getTermsResponse.currentTerms.interestGrace,
            getTermsResponse.fees.cashAdvanceFeeAmount,
            getTermsResponse.fees.cashAdvanceFeeAmountCents,
            getTermsResponse.availableCreditCents,
          ),
          name: "CASH_001",
        };
      case "rewards":
        return {
          script: termsScriptRewards(getTermsResponse.currentTerms.rewards),
          name: "RWD_009",
        };
    }
  }, [getTermsResponse, scriptToShow]);

  const isDF01Error =
    getTermsResponseError instanceof HTTPError &&
    getTermsResponseError.status === DF01_ERROR;

  const renderScript = (termsResponse: GetTermsResponse) => {
    if (
      currentTab === "current" &&
      !scriptDetails &&
      termsResponse.currentTerms.isExternalStatusDisabled
    ) {
      return <div className={styles.warning}>{strings.A0381Warning}</div>;
    }

    return scriptDetails ? (
      <ConversationScript
        scripts={scriptDetails.script}
        scriptName={scriptDetails.name}
        className={styles.conversationScript}
        showEndOfScript={true}
      />
    ) : undefined;
  };

  const hasWarned = useRef<boolean>(false);
  useEffect(() => {
    if (
      scriptDetails &&
      scriptDetails.script.length === 0 &&
      !hasWarned.current
    ) {
      hasWarned.current = true;
      reportWarning(
        new Error("Attempting to render APR Script that has data missing.", {
          cause: scriptDetails.name,
        }),
      );
    }
  }, [scriptDetails]);

  return (
    <div className={classNames(styles.mainContainer, className)} id={toolId}>
      <Container>
        {isGetTermsResponseLoading ? (
          <>
            <Col>{renderTitle()}</Col>
            <Row>
              <Col>
                <Spinner />
              </Col>
            </Row>
          </>
        ) : isGetTermsResponseError ? (
          <>
            <Row>
              <Col>{renderTitle()}</Col>
            </Row>
            <Row>
              <Col>
                {isDF01Error ? (
                  <div className={styles.error}>
                    <img className={styles.alertIcon} src={alertIcon} alt="" />
                    <div className={styles.errorContent}>
                      <span className={styles.errorTitle}>
                        {strings.errorTitle}
                      </span>
                      <span>{strings.df01Error}</span>
                    </div>
                    <StyledButton
                      className={styles.errorCloseButton}
                      variant="secondary"
                      onClick={onRequestClose}
                    >
                      {strings.close}
                    </StyledButton>
                  </div>
                ) : (
                  <StyledError
                    errorTitle={strings.errorTitle}
                    error={getTermsResponseError}
                    refetch={getTermsResponseRefetch}
                  />
                )}
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col md={1} className={styles.centerCol}>
                {renderTitle()}
              </Col>
              <Col md={4} className={styles.centerCol}>
                <div className={styles.tabGroup}>
                  <RoundButton
                    className={styles.tabButton}
                    variant={currentTab === "current" ? "blue" : "grey"}
                    onClick={() => setCurrentTab("current")}
                  >
                    {strings.currentTerms}
                  </RoundButton>
                  <RoundButton
                    className={styles.tabButton}
                    variant={currentTab === "apr" ? "blue" : "grey"}
                    onClick={() => setCurrentTab("apr")}
                  >
                    {strings.aprTerms}
                  </RoundButton>
                  <RoundButton
                    className={styles.tabButton}
                    variant={currentTab === "fees" ? "blue" : "grey"}
                    onClick={() => setCurrentTab("fees")}
                  >
                    {strings.fees}
                  </RoundButton>
                </div>
              </Col>
              <Col offset={{ md: 6 }} md={1} className={styles.centerCol}>
                <StyledButton
                  className={styles.closeButton}
                  variant="secondary"
                  onClick={onRequestClose}
                >
                  {strings.close}
                </StyledButton>
              </Col>
            </Row>
            <div className={styles.divider} />
            <Row>
              <Col md={6}>{renderTable(getTermsResponse)}</Col>
              <Col md={6}>{renderScript(getTermsResponse)}</Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
}
