import { z } from "zod";

const processingStatusType = z.enum(["ACH", "PAPER"]);
export type ProcessingStatusType = z.infer<typeof processingStatusType>;

const paymentSeriesFrequencyType = z.enum([
  "monthly",
  "weekly",
  "everyTwoWeeks",
  "endOfMonth",
  "multiple",
]);

const sourceAccountRestriction = z.object({
  endDate: z.string(),
  restriction: z.string(),
});

const secureMailConversationType = z.enum([
  "NEW",
  "OPEN",
  "READONLY",
  "CMEXPIRE",
  "EXPIRE",
]);

const labeledValue = z.object({ label: z.string(), value: z.string() });
export type LabeledValue = z.infer<typeof labeledValue>;

const creditDebitType = z.enum(["CREDIT", "DEBIT"]);
const agentRole = z.enum([
  "ach",
  "unlock",
  "usa",
  "username",
  "achPaper",
  "viewPCIData",
  "makePayment",
  "addPaymentRestriction",
  "removePaymentRestriction",
  "permissionToDiscuss",
  "cycleToDateTransactions",
  "statementHistory",
  "viewAccountNumber",
  "cli",
  "terms",
  "phones",
]);

export const validationErrors = z.record(z.string().array());

export const validationErrorResponse = z.object({
  errors: validationErrors,
});

export const getAgentDetailsResponse = z.object({
  roles: z.array(agentRole),
});

export const getAccountResponse = z.object({
  currentDwbuid: z.string(),
  partyId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  registrationDateTime: z
    .string()
    .datetime({ offset: true })
    .transform((str) => new Date(str))
    .nullish(),
});

const paymentMethodInfo = z.object({
  id: z.string(),
  name: z.string(),
});

const scheduledPayment = z.object({
  amountCents: z.number(),
  confirmationId: z.string(),
  id: z.string(),
  paymentAmount: z.string(),
  paymentMethod: paymentMethodInfo,
  scheduledPaymentDate: z.string(),
  status: z.string(),
});

export const getAccountDetailsResponse = z.object({
  activated: z.boolean(),
  addressLine1: z.string(),
  addressLine2: z.string().nullish(),
  amountDueCents: z.number().nullish(),
  authorizedUser: z.string().nullish(),
  autopayDisabledReason: z.string().nullish(),
  cardNumber: z.string().nullish(),
  city: z.string(),
  creditAvailableCents: z.number().nullish(),
  creditLimitCents: z.number().nullish(),
  currentBalanceCents: z.number().nullish(),
  currentDwbuid: z.string(),
  daysDelinquent: z.number().nullish(),
  delinquentAmountCents: z.number().nullish(),
  delinquentBucket: z.string().nullish(),
  employeeVipStatus: z.string().nullish(),
  externalStatusCode: z.string().nullish(),
  internalStatusCode: z.string().nullish(),
  isEnrolledAutopay: z.boolean(),
  isLocked: z.boolean(),
  lastContactCode: z.string().nullish(),
  lastContactDate: z.string().nullish(),
  lastPaymentAmountCents: z.number().nullish(),
  lastPaymentDate: z.string().nullish(),
  lastStatementBalanceCents: z.number().nullish(),
  lastStatementDate: z.string().nullish(),
  minPayDueCents: z.number().nullish(),
  name: z.string(),
  nextCycleDate: z.string().nullish(),
  oclAmountCents: z.number().nullish(),
  openDate: z.string().nullish(),
  p2d: z.array(z.string()).nullish(),
  partyId: z.string(),
  paymentDueDate: z.string().nullish(),
  nextPaymentDueDate: z.string().nullish(),
  permanentQueue: z.string().nullish(),
  phoneNumber: z.string(),
  prevDwbuid: z.string().nullish(),
  prevDwbuid2: z.string().nullish(),
  prevDwbuid3: z.string().nullish(),
  recommendedAmountCents: z.number().nullish(),
  registrationDateTime: z.string().nullish(),
  scheduledPayments: scheduledPayment.array().nullish(),
  specialHoldingId: z.string().nullish(),
  state: z.string().nullish(),
  stateCode: z.string(),
  statusReasonCode: z.string().nullish(),
  temporaryQueue: z.string().nullish(),
  totalAmountDueCents: z.number().nullish(),
  transferReason: z.string().nullish(),
  zipcode: z.string(),
  username: z.string().nullish(),
  isC2CPreselected: z.boolean().nullish(),
});

export const updateUsernameRequest = z.object({
  partyId: z.string(),
  dwbuid: z.string(),
  newUsername: z.string(),
});

export const getAutopayEnrollmentRequest = z.object({
  partyId: z.string(),
  dwbuid: z.string(),
});

export const enum EnrollmentType {
  Eligible = "eligible",
  Ineligible = "ineligible",
}

const sourceAccountOwnerType = z.enum([
  "ACCOUNT_OWNER",
  "AUTHORIZED_USER",
  "THIRD_PARTY",
  "OTHER",
  "UNKNOWN",
]);
export type SourceAccountOwnerType = z.infer<typeof sourceAccountOwnerType>;

const enrollmentInfo = z.object({
  abaNumber: z.string(),
  ddaNumber: z.string(),
  amount: z.string(),
  bankName: z.string(),
  ownerName: z.string(),
  party: sourceAccountOwnerType,
});
export type EnrollmentInfo = z.infer<typeof enrollmentInfo>;

export const getAutopayEnrollmentResponseEligible = z.object({
  enrollmentType: z.literal(EnrollmentType.Eligible),
  enrollmentInfo: enrollmentInfo.nullish(),
  enrolledDate: z.string().nullish(),
  reEnrollDate: z.string(),
  isAutopaySkipped: z.boolean(),
  cancelSkipAutopayDisabledReason: z.string().nullish(),
  isEnrolledAutopay: z.boolean(),
});
export const getAutopayEnrollmentResponseIneligible = z.object({
  enrollmentType: z.literal(EnrollmentType.Ineligible),
  lastEnrollmentDate: z.string().nullish(),
  autopayDisabledReason: z.string(),
});
export const getAutopayEnrollmentResponse = z.discriminatedUnion(
  "enrollmentType",
  [
    getAutopayEnrollmentResponseEligible,
    getAutopayEnrollmentResponseIneligible,
  ],
);

const paymentHistoryInfo = z.object({
  amountPaid: z.number(),
  postDate: z.string(),
  effectiveDate: z.string().nullish(),
  transactionType: z.enum([
    "PMT",
    "PMT RVRSL",
    "PMT AMT Adj",
    "Specific CR AMT Adj",
    "Cash Adv Itm Chrg Rfnd",
    "LF Adj",
    "FC Adj",
    "Cash Adv FC Rfnd",
    "Backdated PMT Adj",
    "Merch Itm Chrg Rfnd",
    "OCL Adj",
    "Credit Life Ins Fee Rfnd",
    "Unknown",
  ]),
});
const paymentHistoryData = z.object({
  cycleDate: z.string(),
  amountDue: z.number(),
  amountDelq: z.number(),
  delqBucket: z.string(),
  dueDate: z.string(),
  paymentInfo: paymentHistoryInfo.array(),
});
export const getPaymentHistoryResponse = paymentHistoryData.array();

export const sendTestPushNotificationRequest = z.object({
  partyId: z.string(),
});

export const getAccountLockStatusResponse = z.object({
  isLocked: z.boolean(),
});

export const updateAccountLockStatusRequest = z.object({
  dwbuid: z.string(),
});

const secureMailResponseList = z.object({
  dwbuid: z.string(),
  subject: z.string(),
  updateDate: z
    .string()
    .datetime({ offset: true })
    .transform((str) => new Date(str)),
  type: secureMailConversationType,
  secureMailId: z.string(),
});

export const getSecureMailListResponse = z.object({
  total: z.number(),
  secureMail: secureMailResponseList.array(),
});

export const patchNextSecureMailIdRequest = z.object({
  classificationId: z.string(),
  mailId: z.string(),
});
export const patchNextSecureMailIdResponse = z.object({
  mailId: z.string().nullable(),
});

export const getSecureMailListRequest = z.object({
  classificationId: z.string(),
  page: z.number(),
  size: z.number(),
});

export const patchSecureMailClassificationRequest = z.object({
  secureMailId: z.string(),
  classificationId: z.string(),
});

export const classification = z.object({
  id: z.string(),
  description: z.string(),
});

export const getClassificationsResponse = z.object({
  classifications: classification.array(),
});

export const classificationCount = z.object({
  id: z.string(),
  description: z.string(),
  count: z.number(),
});

export const getClassificationCountResponse = z.object({
  classifications: classificationCount.array(),
});

export const cardmemberMail = z.object({
  subject: z.string(),
  updateDate: z
    .string()
    .datetime({ offset: true })
    .transform((str) => new Date(str)),
  type: secureMailConversationType,
  secureMailId: z.string(),
});

export const getCardmemberMailListRequest = z.object({
  partyId: z.string(),
  page: z.number(),
  size: z.number(),
  start: z.string().optional(),
  end: z.string().optional(),
});

export const getCardmemberMailListResponse = z.object({
  total: z.number(),
  secureMail: cardmemberMail.array(),
});

export const getSecureMailThreadRequest = z.object({
  secureMailId: z.string(),
});
export const getSecureMailThreadRequestKey = z.object({
  secureMailId: z.string(),
});

export const secureMailAttachment = z.object({
  id: z.string(),
  name: z.string(),
  mimeType: z.string(),
});

export const secureMailMessage = z.object({
  messageId: z.string(),
  sender: z.string(),
  body: z.string(),
  createDate: z
    .string()
    .datetime({ offset: true })
    .transform((str) => new Date(str)),
  dwbuid: z.string(),
  employeeId: z.string().nullish(),
  employeeFirstName: z.string().nullish(),
  employeeLastName: z.string().nullish(),
  actionId: z.string().nullish(),
  attachments: secureMailAttachment.array(),
  timeRead: z
    .string()
    .datetime({ offset: true })
    .transform((str) => new Date(str))
    .nullish(),
});

export const employee = z.object({
  employeeId: z.string(),
  firstname: z.string(),
  lastname: z.string(),
});

export const getSecureMailThreadResponse = z.object({
  id: z.string(),
  dwbuid: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  subject: z.string(),
  type: secureMailConversationType,
  classificationId: z.string().nullish(),
  messages: secureMailMessage.array(),
  employees: employee.array(),
});

export const addToThreadRequest = z.object({
  secureMailId: z.string(),
  messageBody: z.string().optional(),
  file: z.instanceof(File).optional(),
});

export const composeSecureMailRequest = z.object({
  partyId: z.string(),
  subject: z.string(),
  classificationId: z.string(),
  status: secureMailConversationType,
  body: z.string().optional(),
  file: z.instanceof(File).optional(),
});

export const composeSecureMailResponse = z.object({
  id: z.string(),
  messageId: z.string(),
  attachmentId: z.string().nullish(),
});

export const updateSecureMailStatusRequest = z.object({
  secureMailId: z.string(),
  type: secureMailConversationType,
});

export const viewMailAttachmentRequest = z.object({
  mailId: z.string(),
  messageId: z.string(),
  attachmentId: z.string(),
});

export const viewMailAttachmentResponse = z.object({
  url: z.string(),
});

const paymentAmountOptions = z.enum(["MINIMUM", "FULL", "FIXED"]);
export type PaymentAmountOptions = z.infer<typeof paymentAmountOptions>;

const enrollAutopayRequest = z.object({
  dwbuid: z.string(),
  amount: z.object({
    type: paymentAmountOptions,
    amountCents: z.number().optional(),
  }),
  partyId: z.string(),
  paymentMethodId: z.string(),
});

const removeAutopayEnrollmentRequest = z.object({
  partyId: z.string(),
  dwbuid: z.string(),
  party: sourceAccountOwnerType,
});

const skipAutopayRequest = z.object({
  partyId: z.string(),
  dwbuid: z.string(),
  party: sourceAccountOwnerType,
});

const ownershipType = z.enum([
  "ACCOUNT_OWNER",
  "AUTHORIZED_USER",
  "THIRD_PARTY",
  "OTHER",
  "UNKNOWN",
]);
export type OwnershipType = z.infer<typeof ownershipType>;

const paymentMethodType = z.enum([
  "checking_account",
  "debit_card",
  "credit_card",
]);
const paymentMethodBrand = z.enum(["mc", "visa"]);
const getPaymentMethodsRequest = z.object({
  partyId: z.string(),
});
const restrictions = z.object({
  endDate: z.string(),
  restriction: z.string(),
});
const basePaymentMethod = z.object({
  id: z.string(),
  isDefault: z.boolean(),
  isInactive: z.boolean().nullish(),
  hasRestrictions: z.boolean(),
  is1FBAccount: z.boolean(),
  brand: paymentMethodBrand.nullish(),
  last4: z.string(),
  lastUsedDate: z.string(),
  cardHolderFirstName: z.string(),
  cardHolderLastName: z.string(),
  isExpired: z.boolean(),
  ownerType: ownershipType,
  restrictions: restrictions.array(),
  addressLine1: z.string(),
  addressLine2: z.string().nullish(),
  city: z.string(),
  stateCode: z.string(),
  zipcode: z.string(),
  phoneNumber: z.string().nullish(),
});
const paymentMethodCreditCard = basePaymentMethod.merge(
  z.object({
    type: z.literal("credit_card"),
    cardNumber: z.string(),
    expirationDate: z.string(),
  }),
);
const paymentMethodDebitCard = basePaymentMethod.merge(
  z.object({
    type: z.literal("debit_card"),
    cardNumber: z.string(),
    expirationDate: z.string(),
  }),
);
const paymentMethodCheckingAccount = basePaymentMethod.merge(
  z.object({
    type: z.literal("checking_account"),
    bankName: z.string().nullish(),
    abaNumber: z.string(),
    accountNumber: z.string(),
    cardHolderFirstName2: z.string().nullish(),
    cardHolderLastName2: z.string().nullish(),
  }),
);
const paymentMethod = z.discriminatedUnion("type", [
  paymentMethodCreditCard,
  paymentMethodDebitCard,
  paymentMethodCheckingAccount,
]);
const paymentMethodCard = z.discriminatedUnion("type", [
  paymentMethodCreditCard,
  paymentMethodDebitCard,
]);

export const getPaymentMethodsResponse = z.object({
  cardMemberAccounts: paymentMethod.array(),
  thirdPartyAccounts: paymentMethod.array(),
});

const getPaymentTransactionsRequest = z.object({
  dwbuid: z.string(),
});

const paymentTransactionStatus = z.enum(["pending", "processed"]);
export type PaymentTransactionStatus = z.infer<typeof paymentTransactionStatus>;

const paymentTransaction = z.object({
  id: z.string(),
  status: paymentTransactionStatus,
  type: z.string(),
  effectiveDate: z.string(),
  destination: z.string(),
  source: z.string(),
  amountCents: z.number(),
  confirmationId: z.string(),
  creationDateStr: z.string(),
  agent: z.string(),
  paymentMethod: paymentMethod,
  seriesId: z.string().nullish(),
  processingStatusType: processingStatusType.nullish(),
});

export const getPaymentTransactionsResponse = z.object({
  numberOfPendingPayments: z.number(),
  pendingPaymentsAmountCents: z.number(),
  paymentTransactions: paymentTransaction.array(),
  statusFilters: labeledValue.array(),
  typeFilters: labeledValue.array(),
  destinationFilters: labeledValue.array(),
  sourceFilters: labeledValue.array(),
});

const classificationOptions = z.object({
  label: z.string(),
  value: z.string(),
});

export const addBankSourceAccountRequest = z.object({
  partyId: z.string(),

  accountNumber: z.string(),
  abaNumber: z.string(),

  addressLine1: z.string(),
  addressLine2: z.string().nullish(),
  city: z.string(),
  stateCode: z.string(),
  zipcode: z.string(),

  phoneNumber: z.string().nullish(),

  cardHolderFirstName: z.string(),
  cardHolderLastName: z.string(),
  cardHolderFirstName2: z.string().nullish(),
  cardHolderLastName2: z.string().nullish(),

  ownerType: ownershipType,
});
export const addBankSourceAccountResponse = paymentMethodCheckingAccount;
export const editBankSourceAccountRequest = addBankSourceAccountRequest
  .omit({
    accountNumber: true,
    abaNumber: true,
    ownerType: true,
  })
  .extend({
    sourceAccountId: z.string(),
    newRestriction: sourceAccountRestriction.optional(),
  });
export const editBankSourceAccountResponse = addBankSourceAccountResponse;

export const addCardSourceAccountRequest = z.object({
  partyId: z.string(),

  cardNumber: z.string(),
  expirationDate: z.string(),
  creditDebitType,

  addressLine1: z.string(),
  addressLine2: z.string().nullish(),
  city: z.string(),
  stateCode: z.string(),
  zipcode: z.string(),

  phoneNumber: z.string().nullish(),

  cardHolderFirstName: z.string(),
  cardHolderLastName: z.string(),

  ownerType: ownershipType,

  dynamicErrorOverrides: z.string().array(),
  dynamicErrorViewCounts: z.record(z.number()),
});
export const addCardSourceAccountResponse = paymentMethodCard;
export const editCardSourceAccountRequest = addCardSourceAccountRequest
  .omit({
    cardNumber: true,
    creditDebitType: true,
    ownerType: true,
  })
  .extend({
    sourceAccountId: z.string(),
    newRestriction: sourceAccountRestriction.optional(),
  });
export const editCardSourceAccountResponse = addCardSourceAccountResponse;

const paymentAuthorizerType = z.enum(["ACCOUNT_OWNER", "THIRD_PARTY"]);
export type PaymentAuthorizerType = z.infer<typeof paymentAuthorizerType>;

export type CreditDebitType = z.infer<typeof creditDebitType>;

const makeOneTimePaymentRequest = z.object({
  partyId: z.string(),
  amountCents: z.number(),
  authorizerType: paymentAuthorizerType,
  bankName: z.string(),
  dwbuid: z.string(),
  overrideKeys: z.array(z.string()),
  paymentDate: z.string(),
  paymentMethodId: z.string(),
  processingStatusType: processingStatusType,
  removeSourceAccount: z.boolean(),
  skipNegAmCheck: z.boolean(),
  scriptComplete: z.boolean(),
  dynamicErrorOverrides: z.string().array(),
  dynamicErrorViewCounts: z.record(z.number()),
});
export const makeOneTimePaymentResponse = z.object({
  scheduledPayment: scheduledPayment,
});

export const paymentScriptsResponse = z.object({
  type: z.literal("script"),
  message: z.string(),
});

const overrideMessage = z.object({
  overrideInfo: z.string(),
  overrideKey: z.string(),
});
export const paymentOverridesResponse = z.object({
  overrideMessages: overrideMessage.array(),
});

export const makePaymentNegAmResponse = z.object({
  title: z.string(),
  message: z.string(),
  increaseAmountCents: z.number(),
  totalAmountCents: z.number(),
});

const seriesPayment = z.object({
  amountCents: z.number(),
  date: z.string(),
});
export type SeriesPayment = z.infer<typeof seriesPayment>;

const postedSeriesSchedule = z.object({
  paymentId: z.string(),
  postDate: z.string(),
});
export type PostedSeriesSchedule = z.infer<typeof postedSeriesSchedule>;

const scheduledSeriesPayment = z.object({
  paymentMethod: paymentMethodInfo,
  payments: z.array(postedSeriesSchedule),
});
export type ScheduledSeriesPayment = z.infer<typeof scheduledSeriesPayment>;

const makeSeriesPaymentRequest = z.object({
  partyId: z.string(),
  authorizerType: paymentAuthorizerType,
  bankName: z.string(),
  dwbuid: z.string(),
  overrideKeys: z.array(z.string()),
  paymentMethodId: z.string(),
  payments: z.array(seriesPayment),
  removeSourceAccount: z.boolean(),
  scriptComplete: z.boolean(),
  frequency: paymentSeriesFrequencyType,
  dynamicErrorOverrides: z.string().array(),
  dynamicErrorViewCounts: z.record(z.number()),
  processingStatusType: processingStatusType,
});
export const makeSeriesPaymentResponse = z.object({
  messages: z.array(z.string()).nullish(),
  scheduledSeriesPayment: scheduledSeriesPayment,
});

const updateDateStatus = z.enum(["success", "danger"]);
export type UpdateDateStatus = z.infer<typeof updateDateStatus>;

const paymentType = z.enum(["series", "single"]);
export type PaymentType = z.infer<typeof paymentType>;

export const RESTRICTION_NONE = "NONE";
export const RESTRICTION_INDEFINITE = "9999-12-31";
const restriction = z.object({ restriction: z.string() });
export const getPartyRestrictionsResponse = z.object({
  bankRestrictions: labeledValue.array(),
  cardRestrictions: labeledValue.array(),
  partyBankRestriction: restriction.array(),
  partyCardRestriction: restriction.array(),
});
export const addRestrictionRequest = z.object({
  partyId: z.string(),
  restriction: z.string(),
});

export const editOneTimePaymentRequest = z.object({
  partyId: z.string(),
  amountCents: z.number(),
  bankName: z.string(),
  dwbuid: z.string(),
  overrideKeys: z.array(z.string()),
  paymentId: z.string(),
  paymentConfirmationId: z.string(),
  paymentDate: z.string(),
  paymentMethodId: z.string(),
  processingStatusType: processingStatusType,
  removeSourceAccount: z.boolean(),
  scriptComplete: z.boolean(),
  dynamicErrorOverrides: z.string().array(),
  dynamicErrorViewCounts: z.record(z.number()),
});
export const editOneTimePaymentResponse = z.object({
  scheduledPayment: scheduledPayment,
});

const editSeriesPayment = seriesPayment.extend({
  achProcessingStatus: processingStatusType.nullish(),
  paymentId: z.string(),
});
export const editSeriesPaymentRequest = z.object({
  partyId: z.string(),
  bankName: z.string(),
  dwbuid: z.string(),
  seriesId: z.string(),
  paymentMethodId: z.string(),
  payments: editSeriesPayment.array(),
  removeSourceAccount: z.boolean(),
  overrideKeys: z.array(z.string()),
  scriptComplete: z.boolean(),
  dynamicErrorOverrides: z.string().array(),
  dynamicErrorViewCounts: z.record(z.number()),
});

export const cancelSeriesPaymentRequest = z.object({
  partyId: z.string(),
  seriesId: z.string(),
  paymentIds: z.string().array(),
});

export const getBankNamesResponse = z.object({
  bankNames: z.array(z.string()),
});
export type GetBankNamesResponse = z.infer<typeof getBankNamesResponse>;

const permissionToDiscussStatus = z.enum([
  "active",
  "inactive",
  "active_history",
  "inactive_history",
]);
export type PermissionToDiscussStatus = z.infer<
  typeof permissionToDiscussStatus
>;

export const permissionToDiscussRelationshipType = z.object({
  id: z.string(),
  relationship: z.string(),
});
export type PermissionToDiscussRelationshipType = z.infer<
  typeof permissionToDiscussRelationshipType
>;

export const permissionToDiscussPermission = z.object({
  id: z.string(),
  agent: z.string(),
  channel: z.string(),
  date: z.string(),
  effectiveDate: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  relation: permissionToDiscussRelationshipType,
  source: z.string(),
  status: permissionToDiscussStatus,
});
export type PermissionToDiscussPermission = z.infer<
  typeof permissionToDiscussPermission
>;

export const getPermissionToDiscussResponse = z.object({
  lastVerifiedDate: z.nullable(z.string()),
  permissionsList: permissionToDiscussPermission.array(),
  relationshipTypes: permissionToDiscussRelationshipType.array(),
});
export type GetPermissionToDiscussResponse = z.infer<
  typeof getPermissionToDiscussResponse
>;

export const getPermissionToDiscussRequest = z.object({
  partyId: z.string(),
  includeHistory: z.boolean(),
  includeInactive: z.boolean(),
});
export type GetPermissionToDiscussRequest = z.infer<
  typeof getPermissionToDiscussRequest
>;

export const addPermissionToDiscussRequest = z.object({
  dwbuid: z.string(),
  effectiveDate: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  partyId: z.string(),
  relationshipId: z.string(),
});
export type AddPermissionToDiscussRequest = z.infer<
  typeof addPermissionToDiscussRequest
>;

export const editPermissionToDicussRequest = z.object({
  partyId: z.string(),
  permissionId: z.string(),
  dwbuid: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  relationshipId: z.string(),
});
export type EditPermissionToDiscussRequest = z.infer<
  typeof editPermissionToDicussRequest
>;

export const verifyPermissionToDiscussRequest = z.object({
  partyId: z.string(),
  dwbuid: z.string(),
});
export type VerifyPermissionToDiscussRequest = z.infer<
  typeof verifyPermissionToDiscussRequest
>;

export const removePermissionToDiscussRequest = z.object({
  partyId: z.string(),
  permissionId: z.string(),
  removedDate: z.string(),
});
export type RemovePermissionToDiscussRequest = z.infer<
  typeof removePermissionToDiscussRequest
>;

export const cycleToDatePostedTransaction = z.object({
  id: z.string(),
  amountCents: z.number(),
  authNumber: z.string(),
  description: z.string(),
  merchantCategories: z.string(),
  newBalanceCents: z.number(),
  posEntryMode: z.string(),
  postDate: z.string(),
  transactionDate: z.string(),
});
export type CycleToDatePostedTransaction = z.infer<
  typeof cycleToDatePostedTransaction
>;

export const cycleToDatePendingTransaction = z.object({
  id: z.string(),
  amountCents: z.number(),
  authNumber: z.string(),
  date: z.string(),
  description: z.string(),
  merchantCategories: z.string(),
  posEntryMode: z.string(),
});
export type CycleToDatePendingTransaction = z.infer<
  typeof cycleToDatePendingTransaction
>;

export const cycleToDateDeclinedTransaction = z.object({
  id: z.string(),
  amountCents: z.number(),
  authNumber: z.string(),
  date: z.string(),
  description: z.string(),
  merchantCategories: z.string(),
  posEntryMode: z.string(),
  reset: z.string(),
});
export type CycleToDateDeclinedTransaction = z.infer<
  typeof cycleToDateDeclinedTransaction
>;

export const getCycleToDateTransactionsResponse = z.object({
  availableCreditCents: z.number(),
  creditLimitCents: z.number(),
  currentBalanceCents: z.number(),
  cycleEndDate: z.string(),
  cycleStartDate: z.string(),
  declinesThisCycle: z.number(),
  lastDeclineDate: z.string().nullish(),
  lastStatementBalanceCents: z.number(),
  pendingFeesAndChargesCents: z.number(),
  declinedAuthorizations: cycleToDateDeclinedTransaction.array(),
  pendingAuthorizations: cycleToDatePendingTransaction.array(),
  postedTransactions: cycleToDatePostedTransaction.array(),
});
export type GetCycleToDateTransactionsResponse = z.infer<
  typeof getCycleToDateTransactionsResponse
>;

export const statementHistoryStatement = z.object({
  adbCashCents: z.number(),
  adbPurchaseCents: z.number(),
  adjustmentAmountCents: z.number(),
  adjustmentsCount: z.string(),
  balanceCents: z.number(),
  cashAmountCents: z.number(),
  cashBalanceCents: z.number(),
  cashCount: z.string(),
  creditAmountCents: z.number(),
  creditLimitCents: z.number(),
  creditsCount: z.string(),
  daysInCycle: z.string(),
  delinquentAmountCents: z.number(),
  dueDate: z.string(),
  fcCashCents: z.number(),
  fcPurchaseCents: z.number(),
  lateFeeCents: z.number(),
  oclAmountCents: z.number(),
  oclFeeCents: z.number(),
  paymentAmountCents: z.number(),
  paymentsCount: z.string(),
  purchaseAmountCents: z.number(),
  purchaseCount: z.string(),
  statementDate: z.string(),
  totalAmountDueCents: z.number(),
});
export type StatementHistoryStatement = z.infer<
  typeof statementHistoryStatement
>;

export const getStatementHistoryResponse = z.object({
  statements: statementHistoryStatement.array(),
});
export type GetStatementHistoryResponse = z.infer<
  typeof getStatementHistoryResponse
>;

// Scripting

export const enum ScriptType {
  Instruction = "Instruction",
  Dialogue = "Dialogue",
}

export const styleOptions = z.enum(["bold", "italic"]);
export type StyleOptions = z.infer<typeof styleOptions>;

export const instructionScript = z.object({
  text: z.string(),
  styleOptions: z.array(styleOptions).optional(),
});
export type InstructionScript = z.infer<typeof instructionScript>;

export const script = z.discriminatedUnion("scriptType", [
  z.object({
    scriptType: z.literal(ScriptType.Instruction),
    instructions: z.array(instructionScript),
    className: z.string().optional(),
  }),
  z.object({
    scriptType: z.literal(ScriptType.Dialogue),
    dialogue: z.string(),
    className: z.string().optional(),
  }),
]);
export type Script = z.infer<typeof script>;

export const option = z.object({
  id: z.string(),
  value: z.string(),
});
export type Option = z.infer<typeof option>;

export const lostStolenOptionsResponse = z.object({
  contactTypes: z.array(option),
  externalStatus: z.string(),
  isLoanAccount: z.boolean(),
  isClosed: z.boolean(),
  lossLocations: z.array(option),
  lossStates: z.array(option),
  lossTypes: z.array(option),
  replacementTypes: z.array(option),
  shipToTypes: z.array(option),
  tempAddressReasons: z.array(option),
  tempAddressStates: z.array(option),
  waysToContact: z.array(option),
  isEmergencyCardEligible: z.boolean(),
  isRushEligible: z.boolean(),
  rushFee: z.string(),
  replacementFee: z.string(),
  rushDeliveryDate: z.string(),
  cardBrand: paymentMethodBrand,
  securityDeptCloseTime: z.string(),
  travelDaysAllowedEmergencyCard: z.string(),
});
export type LostStolenOptionsResponse = z.infer<
  typeof lostStolenOptionsResponse
>;

export const lostStolenAddress = z.object({
  addressLine1: z.string().nullish(),
  addressLine2: z.string().nullish(),
  city: z.string().nullish(),
  state: z.string().nullish(),
  zip: z.string().nullish(),
});
export type LostStolenAddress = z.infer<typeof lostStolenAddress>;

export const submitLostStolenRequest = z.object({
  dwbuid: z.string(),
  addressChange: z.boolean().nullish(),
  cid: z.string().nullish(),
  contactId: z.string(),
  dateLost: z.string().nullish(),
  lossLocationId: z.string().nullish(),
  lossStateId: z.string().nullish(),
  pinCompromised: z.boolean().nullish(),
  replacementOptionId: z.string().nullish(),
  shipToId: z.string().nullish(),
  typeOfLossId: z.string().nullish(),
  waiveReplacementFee: z.boolean().nullish(),
  waiveRushFee: z.boolean().nullish(),
  additionalInfo: z.string().nullish(),
  address: lostStolenAddress.nullish(),
  bestWayToContactTypeId: z.string().nullish(),
});
export type SubmitLostStolenRequest = z.infer<typeof submitLostStolenRequest>;

// Credit Line Increase

export const creditLineIncreaseReason = z.object({
  id: z.string(),
  value: z.string(),
});
export type CreditLineIncreaseReason = z.infer<typeof creditLineIncreaseReason>;

export const creditLineUserData = z.object({
  accountNumber: z.string(),
  accountSubType: z.string(),
  authorizationFlag: z.string(),
  behaviorScore: z.number(),
  creditLimitCents: z.number(),
  creditLineLastChangeDate: z.string(),
  currentBalanceCents: z.number(),
  cycledOverlimitCount: z.number(),
  delinquentBucketId: z.number(),
  externalStatus: z.string(),
  externalStatusReason: z.string(),
  internalStatus: z.string(),
  lastCreditLineChangeCents: z.number(),
  ocl10PercentOrGreater: z.boolean(),
  oclOrDelinquent: z.boolean(),
  openDate: z.string(),
  permanentCollectorCode: z.string(),
  totalNumberOfTimesOneCycleDelinquentCount: z.number(),
  totalNumberOfTimesThreeCyclesDelinquentCount: z.number(),
  totalNumberOfTimesTwoCyclesDelinquentCount: z.number(),
});
export type CreditLineUserData = z.infer<typeof creditLineUserData>;

export const creditLineIncreaseScript = z.enum([
  "CLI01",
  "CLI02",
  "CLI03",
  "CLI04",
  "CLI05",
  "CLI06",
  "CLI07",
  "CLI08",
  "CLI09",
  "CLI10",
  "CLI11",
  "CLI12",
  "CLI13",
  "CLI14",
  "CLI15",
  "CLI16",
  "CLI17",
  "CLI18",
  "CLI19",
  "CLI20",
]);
export type CreditLineIncreaseScript = z.infer<typeof creditLineIncreaseScript>;

export const getCreditLineIncreaseEligibilityRequest = z.object({
  dwbuid: z.string(),
  referenceId: z.optional(z.string()),
});
export type GetCreditLineIncreaseEligibilityRequest = z.infer<
  typeof getCreditLineIncreaseEligibilityRequest
>;

export const getCreditLineIncreaseEligibilityResponse = z.object({
  maxCreditLineCents: z.number(),
  maxIncreaseCents: z.number(),
  reasons: z.array(creditLineIncreaseReason),
  rejectionReason: z.nullable(z.string()),
  scriptName: creditLineIncreaseScript,
  letterType: z.nullable(z.string()),
  userData: creditLineUserData,
});
export type GetCreditLineIncreaseEligibilityResponse = z.infer<
  typeof getCreditLineIncreaseEligibilityResponse
>;

export const submitCreditLineIncreaseRequest = z.object({
  dwbuid: z.string(),
  newLimitCents: z.number(),
  phoneNumber: z.string(),
  reasonId: z.string(),
  referenceId: z.optional(z.string()),
});
export type SubmitCreditLineIncreaseRequest = z.infer<
  typeof submitCreditLineIncreaseRequest
>;

export const submitCreditLineIncreaseResponse = z.object({
  scriptName: creditLineIncreaseScript,
});
export type SubmitCreditLineIncreaseResponse = z.infer<
  typeof submitCreditLineIncreaseResponse
>;

// Call Info
export const getCallInfoRequest = z.object({
  dwbuid: z.string(),
  callId: z.string(),
});
export type GetCallInfoRequest = z.infer<typeof getCallInfoRequest>;

export const getCallInfoResponse = z.object({
  referenceId: z.string(),
});
export type GetCallInfoResponse = z.infer<typeof getCallInfoResponse>;

// Terms

export const reward = z.object({
  endDate: z.string(),
  programName: z.string(),
  startDate: z.string(),
});
export type Reward = z.infer<typeof reward>;

export const interestGrace = z.enum(["No Grace", "Purch Only", "Purch + Cash"]);
export type InterestGrace = z.infer<typeof interestGrace>;

export const currentTerms = z.object({
  currentCashAPR: z.string(),
  currentCashAPRSpread: z.string(),
  currentPurchaseAPR: z.string(),
  currentPurchaseAPRSpread: z.string(),
  dailyCompounding: z.string(),
  interestGrace: interestGrace,
  isExternalStatusDisabled: z.boolean(),
  monthlyCurrentCashAPR: z.string(),
  monthlyCurrentPurchaseAPR: z.string(),
  primeRate: z.string(),
  rewards: z.array(reward),
  useCashPrimeRate: z.boolean(),
  usePurchasePrimeRate: z.boolean(),
});
export type CurrentTerms = z.infer<typeof currentTerms>;

export const aprTermsLevel = z.object({
  cashAPR: z.string(),
  cashAPRSpread: z.string(),
  maxBalanceCents: z.number(),
  minBalanceCents: z.number(),
  monthlyBaseCashAPR: z.string(),
  monthlyBasePurchaseAPR: z.string(),
  purchaseAPR: z.string(),
  purchaseAPRSpread: z.string(),
  useCashPrimeRate: z.boolean(),
  usePurchasePrimeRate: z.boolean(),
});
export type APRTermsLevel = z.infer<typeof aprTermsLevel>;

export const aprTerms = z.object({
  introductoryCashAPRRate: z.string(),
  introductoryPurchaseAPRRate: z.string(),
  introductoryPurchaseExpirationDate: z.string(),
  introductoryPurchaseExpirationMonths: z.string(),
  levels: z.array(aprTermsLevel),
  tier1PenaltyAPR: z.string(),
  tier1PenaltyAPRSpread: z.string(),
  tier2PenaltyAPR: z.string(),
  tier2PenaltyAPRSpread: z.string(),
  useTier1PenaltyPrimeRate: z.boolean(),
  useTier2PenaltyPrimeRate: z.boolean(),
});
export type AprTerms = z.infer<typeof aprTerms>;

export const fees = z.object({
  annualFee: z.string(),
  cardFeeAmount: z.string(),
  cardFeeDesc: z.string(),
  cashAdvanceFeeAmount: z.string(),
  cashAdvanceFeeAmountCents: z.number(),
  feeAmountLastChargedCents: z.number(),
  feeChargeMonthYear: z.string(),
  feeLastChargedDate: z.string(),
  returnedCheckFee: z.string(),
});
export type Fees = z.infer<typeof fees>;

export const termsAPRScript = z.enum([
  "APR_001",
  "APR_002",
  "APR_003",
  "APR_004",
  "APR_005",
  "APR_006",
  "APR_007",
  "APR_008",
  "APR_009",
  "APR_010",
  "APR_011",
  "APR_012",
  "APR_013",
  "APR_014",
  "APR_015",
  "APR_016",
  "APR_017",
  "APR_018",
  "APR_019",
  "APR_020",
  "APR_021",
  "APR_026",
  "APR_027",
]);
export type TermsAPRScript = z.infer<typeof termsAPRScript>;

export const getTermsResponse = z.object({
  aprScript: termsAPRScript,
  aprTerms: aprTerms,
  availableCreditCents: z.number(),
  currentTerms: currentTerms,
  fees: fees,
  newRateOnTransDate: z.string(),
  next2PricingStrategyCitMethodId: z.string(),
  next2PricingStrategyEffectiveDate: z.string(),
  nextPricingStrategyCitMethodId: z.string(),
  nextPricingStrategyDate: z.string(),
  pricingStrategy: z.string(),
  specialHandlingIndicator: z.string(),
});
export type GetTermsResponse = z.infer<typeof getTermsResponse>;

export const lostStolenScript = z.enum([
  "LS0002",
  "LS0006",
  "LS0007",
  "LS0008",
  "LS0009",
  "LS0010",
  "LS0011",
  "LS0019",
  "LS0020",
  "LS0024",
  "LS0029",
  "LS0033",
  "LS0038",
  "LS0039",
]);
export type LostStolenScript = z.infer<typeof lostStolenScript>;

// Phones

const phonesRestrictionFrequency = z.enum([
  "ONE_CALL_PER_DAY",
  "ONE_CALL_PER_WEEK",
  "ONE_CALL_PER_MONTH",
  "LEGACY_RESTRICTION",
  "NONE",
]);

const phonesInactivationSource = z.enum([
  "CARD_MEMBER",
  "THIRD_PARTY_LIVING_WITH_CM",
  "THIRD_PARTY_NOT_LIVING_WITH_CM",
  "EMPLOYER",
  "ATTORNEY",
  "SETTLEMENT_COMPANY",
  "CCCS_DEBT_MGMT",
  "OTHER",
  "UNKNOWN",
]);

const phonesInactivationDelivery = z.enum([
  "VERBAL",
  "WRITTEN",
  "OTHER",
  "UNKNOWN",
]);

const phonesSourceType = z.enum([
  "CC",
  "ST",
  "THIRD_P",
  "GA",
  "NT",
  "AQ",
  "AP",
  "CI",
  "FS",
  "DDA",
  "TB",
  "NSTR",
  "FSMA",
  "OTPFS",
  "DVFCT",
]);

const phonesPhoneType = z.enum([
  "HOME",
  "WORK",
  "SCHOOL",
  "CELL",
  "PAGER",
  "FAX",
  "POSSIBLE_HOME",
  "POSSIBLE_WORK",
  "OTHER",
  "UNKNOWN",
]);

const phonesOwnerType = z.enum([
  "CARD_MEMBER",
  "PARENT",
  "MOTHER",
  "FATHER",
  "NEIGHBOR",
  "ROOMMATE",
  "EMPLOYER",
  "ATTORNEY",
  "SISTER",
  "BROTHER",
  "GRANDPARENT",
  "RELATIVE",
  "FRIEND",
  "BANK",
  "MERCHANT",
  "SPOUSE",
  "CCCS",
  "IN_LAWS",
  "OUTSIDE_AGENCY",
  "OTHER",
  "UNKNOWN",
]);

const phonesConsentStatus = z.enum([
  "PEC_NOT_SPECIFIED_OR_UNKNOWN",
  "PEC_YES",
  "PEC_NO",
]);

const phonesConsentSourceType = z.enum([
  "ACQ_PAPER",
  "ACQ_ONLINE",
  "ACQ_TELEPHONE",
  "WEB_UIR",
  "TEXT_BANKING",
  "GET_ANI",
  "CORRESPONDENCE",
  "PHONE_CONVERSATION",
  "SECURE_MAIL",
  "OTHER",
  "NEUSTAR",
  "WEB_ACTION_PAGE",
  "MOBILE_WEB_ACTION_PAGE",
]);

const phonesSuffix = z.enum([
  "JR",
  "SR",
  "II",
  "III",
  "IV",
  "SECOND",
  "THIRD",
  "FOURTH",
]);

const phoneInactivationOption = option.extend({
  inactivationDeliveryTypes: option.array(),
  inactivationSourceTypes: option.array(),
});
export const getPhoneOptionsResponse = z.object({
  inactivationReasons: phoneInactivationOption.array(),
  nameSuffixes: option.array(),
  partyInactivationTypes: option.array(),
  pecSources: option.array(),
  phoneInactivationTypes: option.array(),
  phoneOwnerTypes: option.array(),
  phoneSources: option.array(),
  phoneTypes: option.array(),
  restrictionFrequencies: option.array(),
});
export type GetPhoneOptionsResponse = z.infer<typeof getPhoneOptionsResponse>;

export const phoneContact = z.object({
  phoneID: z.string(),
  phoneNumber: z.string(),
  extension: z.nullable(z.string()),
  ownerType: phonesOwnerType,
  ownerFirstName: z.string(),
  ownerLastName: z.string(),
  ownerSuffix: z.nullable(phonesSuffix),
  phoneType: phonesPhoneType,
  consentStatus: phonesConsentStatus,
  consentMethod: phonesConsentSourceType,
  consentCapturedDate: z
    .string()
    .datetime({ offset: true })
    .transform((str) => new Date(str)),
  lastVerifiedDate: z
    .string()
    .datetime({ offset: true })
    .transform((str) => new Date(str))
    .nullable(),
  lastCapturedDate: z
    .string()
    .datetime({ offset: true })
    .transform((str) => new Date(str)),
  firstCapturedDate: z
    .string()
    .datetime({ offset: true })
    .transform((str) => new Date(str)),
  sourceType: z.array(
    z.object({
      count: z.number(),
      source: phonesSourceType,
    }),
  ),
  memos: z.array(
    z.object({
      id: z.string(),
      note: z.string(),
      createDate: z
        .string()
        .datetime({ offset: true })
        .transform((str) => new Date(str)),
      enteredBy: z.string(),
    }),
  ),
  restriction: z.nullable(
    z.object({
      restrictionId: z.string(),
      restrictionDays: z.array(z.string()),
      restrictionLiftDate: z
        .string()
        .datetime({ offset: true })
        .transform((str) => new Date(str))
        .nullable(),
      restrictionFrequency: phonesRestrictionFrequency,
    }),
  ),
  inactivation: z.nullable(
    z.object({
      inactivationID: z.string(),
      inactivationReasonId: z.string(),
      reactivationDate: z
        .string()
        .datetime({ offset: true })
        .transform((str) => new Date(str))
        .nullable(),
      inactivationDeliveryType: z.string(),
      inactivationSourceType: z.string(),
    }),
  ),
  isPrimary: z.boolean(),
  isSecondary: z.boolean(),
});
export type PhoneContact = z.infer<typeof phoneContact>;

export const getPhonesResponse = z.object({
  phoneContacts: phoneContact.array(),
});
export type GetPhonesResponse = z.infer<typeof getPhonesResponse>;

export const verifyPhonesRequest = z.object({
  partyId: z.string(),
  phoneIDs: z.array(z.string()),
});
export type VerifyPhonesRequest = z.infer<typeof verifyPhonesRequest>;

export const addPhoneRequest = z.object({
  partyId: z.string(),
  phoneNumber: z.string(),
  extension: z.nullable(z.string()),
  ownerType: phonesOwnerType,
  ownerFirstName: z.string(),
  ownerLastName: z.string(),
  ownerSuffix: z.nullable(phonesSuffix),
  phoneType: phonesPhoneType,
  consentStatus: z.nullable(phonesConsentStatus),
  consentSourceType: z.nullable(phonesSourceType),
  memo: z.nullable(z.string()),
  restriction: z.nullable(
    z.object({
      restrictionDays: z.array(z.string()),
      restrictionLiftDate: z.nullable(z.string()),
      restrictionFrequency: phonesRestrictionFrequency,
    }),
  ),
  inactivation: z.nullable(
    z.object({
      inactivationReasonId: z.string(),
      reactivationDate: z.nullable(z.string()),
      inactivationDeliveryType: phonesInactivationDelivery,
      inactivationSourceType: phonesInactivationSource,
    }),
  ),
});
export type AddPhoneRequest = z.infer<typeof addPhoneRequest>;

export const editPhoneRequest = addPhoneRequest.extend({
  phoneId: z.string(),
});
export type EditPhonesRequest = z.infer<typeof editPhoneRequest>;

export const DYNAMIC_ERROR = "dynamic_error";
export const dynamicErrorResponse = z.object({
  type: z.literal(DYNAMIC_ERROR),
  errorKey: z.string(),
  message: z.string(),
  isOverrideable: z.boolean(),
});

// for when we don't care what the response is and don't want to parse it
export const unknownModel = z.unknown();

export type SecureMailConversationType = z.infer<
  typeof secureMailConversationType
>;

export type ValidationErrors = z.infer<typeof validationErrors>;
export type ValidationErrorResponse = z.infer<typeof validationErrorResponse>;
export type GetAgentDetailsResponse = z.infer<typeof getAgentDetailsResponse>;
export type AgentRole = z.infer<typeof agentRole>;
export type GetAccountResponse = z.infer<typeof getAccountResponse>;

export type PaymentMethodInfo = z.infer<typeof paymentMethodInfo>;
export type ScheduledPayment = z.infer<typeof scheduledPayment>;
export type GetAccountDetailsResponse = z.infer<
  typeof getAccountDetailsResponse
>;

export type UpdateUsernameRequest = z.infer<typeof updateUsernameRequest>;
export type GetAutopayEnrollmentRequest = z.infer<
  typeof getAutopayEnrollmentRequest
>;
export type GetAutopayEnrollmentResponseEligible = z.infer<
  typeof getAutopayEnrollmentResponseEligible
>;
export type GetAutopayEnrollmentResponseIneligible = z.infer<
  typeof getAutopayEnrollmentResponseIneligible
>;
export type GetAutopayEnrollmentResponse = z.infer<
  typeof getAutopayEnrollmentResponse
>;
export type GetPaymentHistoryResponse = z.infer<
  typeof getPaymentHistoryResponse
>;
export type SendTestPushNotificationRequest = z.infer<
  typeof sendTestPushNotificationRequest
>;
export type GetAccountLockStatusResponse = z.infer<
  typeof getAccountLockStatusResponse
>;

export type UpdateAccountLockStatusRequest = z.infer<
  typeof updateAccountLockStatusRequest
>;

export type GetSecureMailListResponse = z.infer<
  typeof getSecureMailListResponse
>;
export type GetSecureMailListRequest = z.infer<typeof getSecureMailListRequest>;

export type PatchNextSecureMailIdRequest = z.infer<
  typeof patchNextSecureMailIdRequest
>;
export type PatchNextSecureMailIdResponse = z.infer<
  typeof patchNextSecureMailIdResponse
>;

export type PatchSecureMailClassificationRequest = z.infer<
  typeof patchSecureMailClassificationRequest
>;

export type Classification = z.infer<typeof classification>;
export type GetClassificationsResponse = z.infer<
  typeof getClassificationsResponse
>;
export type ClassificationCount = z.infer<typeof classificationCount>;
export type GetClassificationCountResponse = z.infer<
  typeof getClassificationCountResponse
>;

export type CardmemberMail = z.infer<typeof cardmemberMail>;
export type GetCardmemberMailListRequest = z.infer<
  typeof getCardmemberMailListRequest
>;
export type GetCardmemberMailListResponse = z.infer<
  typeof getCardmemberMailListResponse
>;

export type SecureMailAttachment = z.infer<typeof secureMailAttachment>;
export type SecureMailMessage = z.infer<typeof secureMailMessage>;
export type Employee = z.infer<typeof employee>;
export type GetSecureMailThreadRequestKey = z.infer<
  typeof getSecureMailThreadRequestKey
>;
export type GetSecureMailThreadRequest = z.infer<
  typeof getSecureMailThreadRequest
>;
export type GetSecureMailThreadResponse = z.infer<
  typeof getSecureMailThreadResponse
>;

export type AddToThreadRequest = z.infer<typeof addToThreadRequest>;

export type ComposeSecureMailRequest = z.infer<typeof composeSecureMailRequest>;
export type ComposeSecureMailResponse = z.infer<
  typeof composeSecureMailResponse
>;

export type UpdateSecureMailStatusRequest = z.infer<
  typeof updateSecureMailStatusRequest
>;

export type ViewMailAttachmentRequest = z.infer<
  typeof viewMailAttachmentRequest
>;
export type ViewMailAttachmentResponse = z.infer<
  typeof viewMailAttachmentResponse
>;

export type EnrollAutopayRequest = z.infer<typeof enrollAutopayRequest>;

export type RemoveAutopayEnrollmentRequest = z.infer<
  typeof removeAutopayEnrollmentRequest
>;

export type SkipAutopayRequest = z.infer<typeof skipAutopayRequest>;

export type GetPaymentTransactionsRequest = z.infer<
  typeof getPaymentTransactionsRequest
>;
export type PaymentTransaction = z.infer<typeof paymentTransaction>;
export type GetPaymentTransactionsResponse = z.infer<
  typeof getPaymentTransactionsResponse
>;

export type PaymentMethodBrand = z.infer<typeof paymentMethodBrand>;
export type PaymentMethodType = z.infer<typeof paymentMethodType>;
export type PaymentMethod = z.infer<typeof paymentMethod>;
export type PaymentMethodCreditCardAccount = z.infer<
  typeof paymentMethodCreditCard
>;
export type PaymentMethodDebitCardAccount = z.infer<
  typeof paymentMethodDebitCard
>;
export type PaymentMethodCheckingAccount = z.infer<
  typeof paymentMethodCheckingAccount
>;
export type PaymentMethodCard = z.infer<typeof paymentMethodCard>;
export type GetPaymentMethodsRequest = z.infer<typeof getPaymentMethodsRequest>;
export type GetPaymentMethodsResponse = z.infer<
  typeof getPaymentMethodsResponse
>;

export type AddBankSourceAccountRequest = z.infer<
  typeof addBankSourceAccountRequest
>;
export type AddBankSourceAccountResponse = z.infer<
  typeof addBankSourceAccountResponse
>;
export type EditBankSourceAccountRequest = z.infer<
  typeof editBankSourceAccountRequest
>;
export type EditBankSourceAccountResponse = z.infer<
  typeof editBankSourceAccountResponse
>;

export type AddCardSourceAccountRequest = z.infer<
  typeof addCardSourceAccountRequest
>;
export type AddCardSourceAccountResponse = z.infer<
  typeof addCardSourceAccountResponse
>;
export type EditCardSourceAccountRequest = z.infer<
  typeof editCardSourceAccountRequest
>;
export type EditCardSourceAccountResponse = z.infer<
  typeof editCardSourceAccountResponse
>;

export type MakeOneTimePaymentRequest = z.infer<
  typeof makeOneTimePaymentRequest
>;
export type MakeOneTimePaymentResponse = z.infer<
  typeof makeOneTimePaymentResponse
>;
export type PaymentScriptsResponse = z.infer<typeof paymentScriptsResponse>;
export type MakeOneTimePaymentOverrideMessage = z.infer<typeof overrideMessage>;
export type PaymentOverridesResponse = z.infer<typeof paymentOverridesResponse>;

export type MakePaymentNegAmResponse = z.infer<typeof makePaymentNegAmResponse>;

export type MakeSeriesPaymentRequest = z.infer<typeof makeSeriesPaymentRequest>;
export type MakeSeriesPaymentResponse = z.infer<
  typeof makeSeriesPaymentResponse
>;

export type EditOneTimePaymentRequest = z.infer<
  typeof editOneTimePaymentRequest
>;
export type EditOneTimePaymentResponse = z.infer<
  typeof editOneTimePaymentResponse
>;

// ref: https://developer.mozilla.org/en-US/docs/Web/HTML/Date_and_time_formats#date_strings
export type DateString = `${number}-${number}-${number}` | "";

export type ClassificationOptions = z.infer<typeof classificationOptions>;

export type PaymentSeriesFrequencyType = z.infer<
  typeof paymentSeriesFrequencyType
>;
export const getPaymentSeriesScheduleRequest = z.object({
  amountCents: z.number(),
  numberOfPayments: z.number(),
  startDate: z.string(),
  frequency: paymentSeriesFrequencyType,
});
export type GetPaymentSeriesScheduleRequest = z.infer<
  typeof getPaymentSeriesScheduleRequest
>;
const seriesSchedule = z.object({
  date: z.string(),
  amountCents: z.number(),
});
export const getPaymentSeriesScheduleResponse = z.object({
  schedule: z.array(seriesSchedule),
});
export type GetPaymentSeriesScheduleResponse = z.infer<
  typeof getPaymentSeriesScheduleResponse
>;

export type GetPartyRestrictionsResponse = z.infer<
  typeof getPartyRestrictionsResponse
>;
export type AddRestrictionRequest = z.infer<typeof addRestrictionRequest>;

export type EditSeriesPaymentRequest = z.infer<typeof editSeriesPaymentRequest>;

export type CancelSeriesPaymentRequest = z.infer<
  typeof cancelSeriesPaymentRequest
>;

export type SourceAccountRestriction = z.infer<typeof sourceAccountRestriction>;

export type DynamicErrorResponse = z.infer<typeof dynamicErrorResponse>;
