import { useEffect, useState } from "react";
import StyledButton from "../../common/buttons/StyledButton";
import styles from "./SearchForm.module.css";

const strings = {
  search: "Search",
};

type Props = {
  currentValue: string | null;
  labelId: string;
  isLoading: boolean;
  onSubmit: (value: string) => void;
  placeholder: string;
};
const SearchForm = ({
  currentValue,
  labelId,
  isLoading,
  onSubmit,
  placeholder,
}: Props) => {
  const [value, setValue] = useState(currentValue ?? "");
  useEffect(() => {
    setValue(currentValue ?? "");
  }, [currentValue]);
  return (
    <form
      autoComplete="new-password"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(value);
      }}
    >
      <div className={styles.container}>
        <input
          className={styles.input}
          placeholder={placeholder}
          aria-labelledby={labelId}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <StyledButton
          type="submit"
          disabled={!value}
          className={styles.searchButton}
          isLoading={isLoading}
        >
          {strings.search}
        </StyledButton>
      </div>
    </form>
  );
};

export default SearchForm;
