import StyledButton from "../../common/buttons/StyledButton";
import styles from "./CurrentTermsTable.module.css";
import eyeSlashedIcon from "../../../assets/password-eye-slashed.svg";
import eyeIcon from "../../../assets/password-eye.svg";
import { useState } from "react";
import classNames from "classnames";
import { TermsScriptType } from "../Terms";
import { GetTermsResponse, Reward } from "../../../lib/api/models";
import { convertDateStringToMonthDayYearFomat } from "../../../lib/formatting";

const strings = {
  currentTerms: "Current Terms",
  viewScript: "View Script",
  hideScript: "Hide Script",
  currentPurchaseAPR: "Current Purchase APR",
  currentCashAPR: "Current Cash APR",
  dailyCompounding: "Daily Compounding",
  primeRate: "Prime Rate",
  interestGrace: "Interest Grace",
  rewards: "Rewards",
  yes: "Yes",
  no: "No",
  cash: "Cash",
  programName: "Program Name",
  startDate: "Start Date",
  termDate: "Term Date",
};

type Props = {
  getTermsResponse: GetTermsResponse;
  scriptToShow: TermsScriptType | undefined;
  setScriptToShow: (s: TermsScriptType | undefined) => void;
};

export default function CurrentTermsTable({
  getTermsResponse,
  scriptToShow,
  setScriptToShow,
}: Props) {
  const { currentTerms } = getTermsResponse;
  const [viewRewards, setViewRewards] = useState(false);

  const renderViewScriptButton = (
    script: TermsScriptType,
    isDisabled?: boolean,
  ) => {
    return (
      <StyledButton
        variant="secondary"
        onClick={() =>
          scriptToShow === script
            ? setScriptToShow(undefined)
            : setScriptToShow(script)
        }
        disabled={isDisabled}
      >
        {scriptToShow === script ? (
          <>
            <img src={eyeSlashedIcon} alt="" className={styles.eyeIcon} />
            {strings.hideScript}
          </>
        ) : (
          <>
            <img src={eyeIcon} alt="" className={styles.eyeIcon} />
            {strings.viewScript}
          </>
        )}
      </StyledButton>
    );
  };

  const renderRewardsTable = (reward: Reward, index: number) => {
    return (
      <table
        className={classNames(styles.table, styles.cashRewardsTable)}
        key={index}
      >
        <thead>
          <tr className={styles.headerRow}>
            <th>
              <span className={styles.header}>{strings.cash}</span>
            </th>
            <td>{index === 0 ? renderViewScriptButton("rewards") : null}</td>
          </tr>
        </thead>
        <tbody>
          <tr className={styles.oddRow}>
            <td>
              <span>{strings.programName}</span>
            </td>
            <td>
              <span>{reward.programName}</span>
            </td>
          </tr>
          <tr className={styles.evenRow}>
            <td>
              <span>{strings.startDate}</span>
            </td>
            <td>
              <span>
                {convertDateStringToMonthDayYearFomat(reward.startDate)}
              </span>
            </td>
          </tr>
          <tr className={styles.oddRow}>
            <td>
              <span>{strings.termDate}</span>
            </td>
            <td>
              <span>{reward.endDate}</span>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <>
      <table className={styles.table}>
        <thead>
          <tr className={styles.headerRow}>
            <th>
              <span className={styles.header}>{strings.currentTerms}</span>
            </th>
            <th>
              {renderViewScriptButton(
                "apr",
                currentTerms.isExternalStatusDisabled,
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className={styles.oddRow}>
            <td>
              <span>{strings.currentPurchaseAPR}</span>
            </td>
            <td>
              <span>{`${currentTerms.currentPurchaseAPR}%`}</span>
            </td>
          </tr>
          <tr className={styles.evenRow}>
            <td>
              <span>{strings.currentCashAPR}</span>
            </td>
            <td>
              <span>{`${currentTerms.currentCashAPR}%`}</span>
            </td>
          </tr>
          <tr className={styles.oddRow}>
            <td>
              <span>{strings.dailyCompounding}</span>
            </td>
            <td>
              <span>{currentTerms.dailyCompounding}</span>
            </td>
          </tr>
          <tr className={styles.evenRow}>
            <td>
              <span>{strings.primeRate}</span>
            </td>
            <td>
              <span>{`${currentTerms.primeRate}%`}</span>
            </td>
          </tr>
          <tr className={styles.oddRow}>
            <td>
              <span>{strings.interestGrace}</span>
            </td>
            <td>
              <span>{currentTerms.interestGrace}</span>
            </td>
          </tr>
          <tr className={styles.evenRow}>
            <td>
              <span>{strings.rewards}</span>
            </td>
            <td>
              {currentTerms.rewards.length > 0 ? (
                <StyledButton
                  className={styles.yesButton}
                  variant="transparent"
                  onClick={() => setViewRewards(true)}
                >
                  {strings.yes}
                </StyledButton>
              ) : (
                <span>{strings.no}</span>
              )}
            </td>
          </tr>
        </tbody>
      </table>

      {viewRewards
        ? currentTerms.rewards.map((r, i) => renderRewardsTable(r, i))
        : null}
    </>
  );
}
