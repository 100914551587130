import classNames from "classnames";
import styles from "./EndOfScript.module.css";

const strings = {
  endOfScript: "END OF SCRIPT",
};

type Props = {
  className?: string;
};

export default function EndOfScript({ className }: Props) {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.line} />
      <span className={styles.text}>{strings.endOfScript}</span>
      <div className={styles.line} />
      <div />
    </div>
  );
}
