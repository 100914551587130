import classNames from "classnames";
import { ComponentProps, useEffect, useState } from "react";
import StyledButton from "../../buttons/StyledButton";
import styles from "./StyledInput.module.css";

export type StyledInputProps = ComponentProps<"input"> & {
  label?: string;
  rightContent?: React.ReactNode;
  labelClassName?: string;
  showPopover?: boolean;
  variant?: "default" | "horizontal";
} & (
    | {
        invalid: boolean;
        validationMessages?: never;
      }
    | {
        invalid?: never;
        validationMessages?: string[];
      }
  );

export default function StyledInput({
  label,
  className,
  labelClassName,
  disabled,
  rightContent,
  validationMessages,
  invalid = !!validationMessages && validationMessages.length > 0,
  type,
  onFocus,
  showPopover = true,
  autoComplete = type === "password" ? "off" : "new-password",
  variant = "default",
  ...rest
}: StyledInputProps) {
  const [hidePopoverOverride, setHidePopoverOverride] = useState(false);

  useEffect(() => {
    setHidePopoverOverride(false);
  }, [validationMessages]);

  return (
    <div className={classNames(styles.container, className)}>
      <label
        className={classNames(
          styles.label,
          disabled && styles.disabled,
          variant === "horizontal" && styles.horizontal,
          labelClassName,
        )}
        aria-label={label}
      >
        {label}
        <input
          {...rest}
          type={type}
          className={classNames({
            [styles.input]: true,
            [styles.invalid]: invalid,
            [styles.withRightContent]: !!rightContent,
            [styles.horizontal]: variant === "horizontal",
          })}
          disabled={disabled}
          autoComplete={autoComplete}
          onFocus={(e) => {
            onFocus?.(e);
            setHidePopoverOverride(false);
          }}
        />
        {rightContent}
      </label>
      {validationMessages &&
      !hidePopoverOverride &&
      validationMessages.length > 0 ? (
        <div
          className={
            showPopover
              ? styles.validationMessagesPopover
              : styles.validationMessages
          }
        >
          {Object.values(validationMessages).map((message, index) => (
            <div key={`${message} ${index}`}>{message}</div>
          ))}

          <StyledButton
            className={styles.closePopoverButton}
            variant="transparent"
            onClick={() => setHidePopoverOverride(true)}
          >
            {"X"}
          </StyledButton>
        </div>
      ) : null}
    </div>
  );
}
