import {
  ContactId,
  ShipReplacementTo,
  ReplacementType,
  TypeOfLoss,
} from "../FieldIds";
import warningIcon from "../../../assets/warning-icon.svg";
import { useState } from "react";
import StyledRadioButton from "../../StyledRadioButton";
import Select from "../../common/Select";
import { LostStolenFormState } from "../LostStolen";
import styles from "./LostStolenForm.module.css";
import BaseModal from "../../common/modals/BaseModal/BaseModal";
import StyledButton from "../../common/buttons/StyledButton";
import PhoneNumberInput from "../../common/PhoneNumberInput";
import StyledInput from "../../common/inputs/StyledInput";
import StyledZipcodeInput from "../../common/StyledZipcodeInput";
import classNames from "classnames";
import DateInput from "../../common/DateInput";
import { Option, LostStolenOptionsResponse } from "../../../lib/api/models";

const strings = {
  contact: "Contact",
  select: "Select",
  cardmember: "Cardmember",
  authUser: "Authorized user",
  parent: "Parent",
  thirdPartyKnown: "3rd party known to CM",
  thirdPartyUnknown: "3rd party unknown to CM",
  merchant: "Merchant",
  other: "Other",
  cardInPossession: "Card in possession?",
  yes: "Yes",
  no: "No",
  typeOfLoss: "Type of loss",
  lostCard: "Lost card",
  stolen: "Stolen",
  notReceived: "Not received",
  fraudApp: "Fraud app",
  counterfeit: "Counterfeit",
  accountTakeover: "Account takeover",
  allMailPhone: "All mail and phone",
  fraudOnAccount: "Fraud on account?",
  transferToSecurity: "Xfer to sec?",
  cid: "CID",
  bestWayToContact: "Best way to contact",
  home: "Home",
  work: "Work",
  billingAddress: "Billing address",
  temporaryAddress: "Temporary address",
  shipReplacementTo: "Ship replacement to",
  addressChangeToday: "Address change today?",
  newAddressVerify: "New address verify w/ security",
  tempAddressVerify: "Temporary address verify w/ sec",
  reasonForTempAddress: "Reason for temp address",
  homeAddress: "Home address",
  parentsAddress: "Parents address",
  schoolAddress: "School address",
  workAddress: "Work address",
  vacation: "Vacation/traveling",
  securityIssue: "Security issue w/ billing addr",
  tempAddress1: "Temporary address 1",
  tempAddress2: "Temporary address 2",
  zip: "Zip",
  city: "City",
  state: "State",
  replacementOptions: "Replacement Options",
  standard: "Standard",
  rushFedex: "Rush FedEx",
  rushPostalExpress: "Rush Postal Express",
  waiveReplacementFee: "Waive replacement fee",
  waiveRushFee: "Waive rush fee",
  dateLost: "Date lost",
  lossLocation: "Loss location",
  auto: "Auto",
  office: "Office",
  store: "Store",
  rural: "Rural",
  bar: "Bar",
  hotel: "Hotel/motel",
  unknown: "Unknown",
  stateOfLoss: "US state of loss",
  pinCompromised: "Pin compromised",
  fraudCharges: "Fraud charges",
  submit: "Submit",
  transferToSecurityModalTitle: "Transfer to Security",
  transferToSecurityModalDescription:
    "If Transfer for Security 6777 was successful, click “Close Tool” to End.",
  closeTool: "Close Tool",
  goBack: "Go Back",
  ok: "OK",
  additionalInfo: "Additional Info",
};

const CID_VALUE_LENGTH = 10;

function TypeOfLossComponent({
  options,
  typeOfLoss,
  onChange,
  disabled,
}: {
  options: LostStolenOptionsResponse;
  typeOfLoss?: TypeOfLoss;
  onChange: (value: TypeOfLoss) => void;
  disabled?: boolean;
}) {
  return (
    <>
      <Select
        variant="horizontal"
        options={options.lossTypes.map((lt) => ({
          value: lt.id as TypeOfLoss,
          label: lt.value,
        }))}
        selectedValue={typeOfLoss}
        onChange={onChange}
        label={strings.typeOfLoss}
        labelClassName={styles.label}
        placeholder={strings.select}
        disabled={disabled}
      />
      <div className={styles.divider} />
    </>
  );
}

function FraudOnAccountComponent({
  onChange,
  fraudOnAccount,
  disabled,
}: {
  onChange: (value: boolean) => void;
  fraudOnAccount?: boolean;
  disabled: boolean;
}) {
  return (
    <>
      <div className={styles.radioContainer}>
        <div className={classNames(styles.label, styles.radioLabel)}>
          {strings.fraudOnAccount}
        </div>
        <div>
          <StyledRadioButton
            className={styles.leftRadioInput}
            checked={!!fraudOnAccount}
            label={strings.yes}
            disabled={disabled}
            onChange={() => onChange(true)}
          />
          <StyledRadioButton
            checked={fraudOnAccount !== undefined && !fraudOnAccount}
            label={strings.no}
            disabled={disabled}
            onChange={() => onChange(false)}
          />
        </div>
      </div>
      <div className={styles.divider} />
    </>
  );
}

function ShipReplacementToComponent({
  disabled,
  shipToTypes,
  selectedValue,
  onChange,
}: {
  disabled: boolean;
  shipToTypes: Option[];
  selectedValue?: ShipReplacementTo;
  onChange: (value: ShipReplacementTo) => void;
}) {
  return (
    <>
      <Select
        variant="horizontal"
        disabled={disabled}
        options={shipToTypes.map((stt) => ({
          label: stt.value,
          value: stt.id as ShipReplacementTo,
        }))}
        selectedValue={selectedValue}
        onChange={onChange}
        label={strings.shipReplacementTo}
        labelClassName={styles.label}
        placeholder={strings.select}
      />
      <div className={styles.divider} />
    </>
  );
}

function ReplacementOptionsComponent({
  disabled,
  replacementTypes,
  selectedValue,
  onChange,
}: {
  disabled: boolean;
  replacementTypes: Option[];
  selectedValue?: ReplacementType;
  onChange: (value: ReplacementType) => void;
}) {
  return (
    <>
      <Select
        variant="horizontal"
        disabled={disabled}
        label={strings.replacementOptions}
        labelClassName={styles.label}
        placeholder={strings.select}
        options={replacementTypes.map((rt) => ({
          value: rt.id as ReplacementType,
          label: rt.value,
        }))}
        selectedValue={selectedValue}
        onChange={onChange}
      />
      <div className={styles.divider} />
    </>
  );
}

type Props = {
  formState: LostStolenFormState;
  setFormState: React.Dispatch<React.SetStateAction<LostStolenFormState>>;
  closeTool: () => void;
  options: LostStolenOptionsResponse;
  isSubmitting: boolean;
};

export default function LostStolenForm({
  formState,
  setFormState,
  closeTool,
  options,
  isSubmitting,
}: Props) {
  const [showTransferToSecurityModal, setShowTransferToSecurityModal] =
    useState(false);

  function renderOkButton() {
    return (
      <StyledButton className={styles.ok} onClick={closeTool}>
        {strings.ok}
      </StyledButton>
    );
  }

  const hasVeryLimitedAccess =
    formState.contactId === ContactId.Merchant ||
    formState.contactId === ContactId.ThirdPartyUnknownToCM;
  const hasLimitedAccess =
    formState.contactId === ContactId.AuthUser ||
    formState.contactId === ContactId.Parent ||
    formState.contactId === ContactId.ThirdPartyKnownToCM ||
    formState.contactId === ContactId.Other;

  const hasInactiveStatus =
    options.externalStatus === "B" || options.externalStatus === "Z";
  const hasLostStolenExtStatus =
    options.externalStatus === "L" || options.externalStatus === "U";
  const hasInvalidStatus =
    options.isClosed ||
    options.externalStatus === "C" ||
    options.externalStatus === "E" ||
    options.externalStatus === "F" ||
    options.externalStatus === "I";

  const finalInfo = formState.earlyTermination
    ? formState.earlyTermination.finalInfo
    : formState.fraudOnAccount
      ? formState.yesFraudOnAccount?.shipReplacementTo ===
        ShipReplacementTo.Billing
        ? formState.yesFraudOnAccount?.billing?.finalInfo
        : formState.yesFraudOnAccount?.temporary?.finalInfo
      : formState.noFraudOnAccount?.shipReplacementTo ===
          ShipReplacementTo.Billing
        ? formState.noFraudOnAccount?.billing?.finalInfo
        : formState.noFraudOnAccount?.temporary?.finalInfo;

  return (
    <>
      <Select
        variant="horizontal"
        disabled={isSubmitting}
        options={options.contactTypes.map((ct) => ({
          value: ct.id as ContactId,
          label: ct.value,
        }))}
        selectedValue={formState.contactId}
        onChange={(value: ContactId) => {
          const showLS0020 =
            value === ContactId.ThirdPartyKnownToCM &&
            !hasInactiveStatus &&
            !hasLostStolenExtStatus &&
            !hasInvalidStatus;
          setFormState({
            contactId: value,
            earlyTermination: showLS0020
              ? {
                  shipReplacementTo: ShipReplacementTo.Billing,
                  finalInfo: {
                    replacementOption: ReplacementType.Standard,
                  },
                }
              : undefined,
          });
        }}
        label={strings.contact}
        labelClassName={styles.label}
        placeholder={strings.select}
      />
      <div className={styles.divider} />
      {(options.isLoanAccount &&
        (formState.contactId === ContactId.Cardmember ||
          hasVeryLimitedAccess)) ||
      (formState.contactId === ContactId.Cardmember && hasInactiveStatus) ||
      (hasLimitedAccess &&
        (hasInactiveStatus || hasLostStolenExtStatus || hasInvalidStatus))
        ? renderOkButton()
        : null}
      {!options.isLoanAccount && hasVeryLimitedAccess ? (
        <>
          <Select
            variant="horizontal"
            disabled={isSubmitting}
            label={strings.stateOfLoss}
            labelClassName={styles.label}
            placeholder={strings.select}
            options={options.lossStates.map((ls) => ({
              value: ls.id,
              label: ls.value,
            }))}
            selectedValue={formState.earlyTermination?.finalInfo?.stateOfLoss}
            onChange={(value) =>
              setFormState((prev) => ({
                ...prev,
                earlyTermination: {
                  ...prev.earlyTermination,
                  finalInfo: {
                    ...prev.earlyTermination,
                    stateOfLoss: value,
                  },
                },
              }))
            }
          />
          <div className={styles.divider} />
          <div>
            <label className={classNames(styles.label, styles.textareaLabel)}>
              {strings.additionalInfo}
              <textarea
                disabled={isSubmitting}
                className={styles.textareaInput}
                value={formState.earlyTermination?.additionalInfo ?? ""}
                onChange={(e) =>
                  setFormState((prev) => ({
                    ...prev,
                    earlyTermination: {
                      ...prev.earlyTermination,
                      additionalInfo: e.target.value,
                    },
                  }))
                }
              />
            </label>
          </div>
          <div className={styles.divider} />
        </>
      ) : null}
      {formState.contactId === ContactId.ThirdPartyKnownToCM &&
      !hasInactiveStatus &&
      !hasInvalidStatus &&
      !hasLostStolenExtStatus ? (
        <>
          <TypeOfLossComponent
            options={options}
            typeOfLoss={formState.typeOfLoss}
            onChange={(value: TypeOfLoss) =>
              setFormState((prev) => ({
                ...prev,
                typeOfLoss: value,
              }))
            }
            disabled={isSubmitting}
          />
          <ShipReplacementToComponent
            disabled={true}
            shipToTypes={options.shipToTypes}
            selectedValue={formState.earlyTermination?.shipReplacementTo}
            onChange={(value: ShipReplacementTo) =>
              setFormState((prev) => ({
                ...prev,
                earlyTermination: {
                  ...prev.earlyTermination,
                  shipReplacementTo: value,
                },
              }))
            }
          />
          <ReplacementOptionsComponent
            disabled={true}
            replacementTypes={options.replacementTypes}
            selectedValue={
              formState.earlyTermination?.finalInfo?.replacementOption
            }
            onChange={(value: ReplacementType) =>
              setFormState((prev) => ({
                ...prev,
                earlyTermination: {
                  ...prev.earlyTermination,
                  finalInfo: {
                    ...prev.earlyTermination?.finalInfo,
                    replacementOption: value,
                  },
                },
              }))
            }
          />
        </>
      ) : null}
      {formState.contactId === ContactId.Cardmember &&
      hasLostStolenExtStatus ? (
        <>
          <FraudOnAccountComponent
            disabled={
              formState.typeOfLoss === TypeOfLoss.AccountTakeover ||
              formState.typeOfLoss === TypeOfLoss.Counterfeit ||
              formState.typeOfLoss === TypeOfLoss.AllMailAndPhone ||
              formState.typeOfLoss === TypeOfLoss.FraudApp ||
              isSubmitting
            }
            fraudOnAccount={formState.fraudOnAccount}
            onChange={(value) =>
              setFormState((prev) => ({
                ...prev,
                fraudOnAccount: value,
              }))
            }
          />
          {formState.fraudOnAccount === false ? renderOkButton() : null}
        </>
      ) : null}
      {formState.contactId === ContactId.Cardmember && hasInvalidStatus ? (
        <>
          <FraudOnAccountComponent
            disabled={
              formState.typeOfLoss === TypeOfLoss.AccountTakeover ||
              formState.typeOfLoss === TypeOfLoss.Counterfeit ||
              formState.typeOfLoss === TypeOfLoss.AllMailAndPhone ||
              formState.typeOfLoss === TypeOfLoss.FraudApp ||
              isSubmitting
            }
            fraudOnAccount={formState.fraudOnAccount}
            onChange={(value) =>
              setFormState((prev) => ({
                ...prev,
                fraudOnAccount: value,
              }))
            }
          />
          {formState.fraudOnAccount === false ? renderOkButton() : null}
        </>
      ) : null}
      {formState.contactId !== ContactId.ThirdPartyKnownToCM &&
      ((formState.contactId === ContactId.Cardmember &&
        !options.isLoanAccount &&
        !hasInactiveStatus &&
        !hasLostStolenExtStatus &&
        !hasInvalidStatus) ||
        (hasLimitedAccess &&
          !hasInactiveStatus &&
          !hasLostStolenExtStatus &&
          !hasInvalidStatus)) ? (
        <TypeOfLossComponent
          options={options}
          typeOfLoss={formState.typeOfLoss}
          onChange={(value: TypeOfLoss) =>
            setFormState((prev) => ({
              ...prev,
              typeOfLoss: value,
              fraudOnAccount:
                prev.contactId !== ContactId.ThirdPartyKnownToCM &&
                (value === TypeOfLoss.FraudApp ||
                  value === TypeOfLoss.AccountTakeover ||
                  value === TypeOfLoss.Counterfeit ||
                  value === TypeOfLoss.AllMailAndPhone)
                  ? true
                  : undefined,
              yesFraudOnAccount: undefined,
              noFraudOnAccount: undefined,
            }))
          }
          disabled={isSubmitting}
        />
      ) : null}
      {formState.typeOfLoss &&
      !(
        formState.contactId === ContactId.ThirdPartyKnownToCM &&
        !hasInactiveStatus &&
        !hasLostStolenExtStatus &&
        !hasInvalidStatus
      ) ? (
        <FraudOnAccountComponent
          disabled={
            formState.typeOfLoss === TypeOfLoss.AccountTakeover ||
            formState.typeOfLoss === TypeOfLoss.Counterfeit ||
            formState.typeOfLoss === TypeOfLoss.AllMailAndPhone ||
            formState.typeOfLoss === TypeOfLoss.FraudApp ||
            isSubmitting
          }
          fraudOnAccount={formState.fraudOnAccount}
          onChange={(value) =>
            setFormState((prev) => ({
              ...prev,
              fraudOnAccount: value,
              yesFraudOnAccount: undefined,
              noFraudOnAccount: undefined,
            }))
          }
        />
      ) : null}
      {formState.fraudOnAccount ? (
        <>
          <div className={styles.radioContainer}>
            <div className={classNames(styles.label, styles.radioLabel)}>
              {strings.transferToSecurity}
            </div>
            <div>
              <StyledRadioButton
                className={styles.leftRadioInput}
                disabled={isSubmitting}
                checked={!!formState.yesFraudOnAccount?.transferToSecurity}
                label={strings.yes}
                onChange={() => {
                  setFormState((prev) => ({
                    ...prev,
                    yesFraudOnAccount: {
                      ...prev.yesFraudOnAccount,
                      transferToSecurity: true,
                    },
                  }));
                  setShowTransferToSecurityModal(true);
                }}
              />
              <StyledRadioButton
                disabled={isSubmitting}
                checked={
                  formState.yesFraudOnAccount?.transferToSecurity !==
                    undefined &&
                  !formState.yesFraudOnAccount?.transferToSecurity
                }
                label={strings.no}
                onChange={() =>
                  setFormState((prev) => ({
                    ...prev,
                    yesFraudOnAccount: {
                      ...prev.yesFraudOnAccount,
                      transferToSecurity: false,
                    },
                  }))
                }
              />
            </div>
          </div>
          <div className={styles.divider} />
        </>
      ) : null}
      {formState.yesFraudOnAccount?.transferToSecurity !== undefined &&
      !formState.yesFraudOnAccount?.transferToSecurity &&
      formState.contactId === ContactId.Cardmember &&
      hasLostStolenExtStatus
        ? renderOkButton()
        : null}
      {formState.yesFraudOnAccount?.transferToSecurity !== undefined &&
      !formState.yesFraudOnAccount?.transferToSecurity &&
      !(
        formState.contactId === ContactId.Cardmember && hasLostStolenExtStatus
      ) ? (
        <>
          <PhoneNumberInput
            label={strings.cid}
            variant="horizontal"
            disabled={isSubmitting}
            value={formState.yesFraudOnAccount.cid ?? ""}
            onChange={(value) =>
              setFormState((prev) => ({
                ...prev,
                yesFraudOnAccount: {
                  ...prev.yesFraudOnAccount,
                  cid: value,
                },
              }))
            }
          />
          <div className={styles.divider} />
          {formState.contactId === ContactId.Cardmember ? (
            <>
              <Select
                variant="horizontal"
                disabled={isSubmitting}
                options={options.waysToContact.map((wtc) => ({
                  value: wtc.id,
                  label: wtc.value,
                }))}
                selectedValue={formState.yesFraudOnAccount?.bestWayToContact}
                onChange={(value) =>
                  setFormState((prev) => ({
                    ...prev,
                    yesFraudOnAccount: {
                      ...prev.yesFraudOnAccount,
                      bestWayToContact: value,
                    },
                  }))
                }
                label={strings.bestWayToContact}
                labelClassName={styles.label}
                placeholder={strings.select}
              />
              <div className={styles.divider} />{" "}
            </>
          ) : null}
        </>
      ) : null}
      {(formState.fraudOnAccount !== undefined &&
        !formState.fraudOnAccount &&
        (formState.contactId !== ContactId.Cardmember ||
          (!hasLostStolenExtStatus && !hasInvalidStatus))) ||
      formState.yesFraudOnAccount?.cid?.length === CID_VALUE_LENGTH ||
      formState.yesFraudOnAccount?.bestWayToContact ? (
        <ShipReplacementToComponent
          disabled={isSubmitting}
          shipToTypes={options.shipToTypes}
          selectedValue={
            formState.fraudOnAccount
              ? formState.yesFraudOnAccount?.shipReplacementTo
              : formState.noFraudOnAccount?.shipReplacementTo
          }
          onChange={(value: ShipReplacementTo) =>
            setFormState((prev) => ({
              ...prev,
              yesFraudOnAccount: prev.fraudOnAccount
                ? {
                    ...prev.yesFraudOnAccount,
                    shipReplacementTo: value,
                    billing: undefined,
                    temporary: undefined,
                  }
                : undefined,
              noFraudOnAccount: prev.fraudOnAccount
                ? undefined
                : {
                    ...prev.noFraudOnAccount,
                    shipReplacementTo: value,
                    billing: undefined,
                    temporary: undefined,
                  },
            }))
          }
        />
      ) : null}
      {formState.contactId !== ContactId.ThirdPartyKnownToCM &&
      (formState.yesFraudOnAccount?.shipReplacementTo ===
        ShipReplacementTo.Billing ||
        formState.noFraudOnAccount?.shipReplacementTo ===
          ShipReplacementTo.Billing) ? (
        <>
          <div className={styles.radioContainer}>
            <div className={classNames(styles.label, styles.radioLabel)}>
              {strings.addressChangeToday}
            </div>
            <StyledRadioButton
              className={styles.leftRadioInput}
              disabled={isSubmitting}
              checked={
                formState.fraudOnAccount
                  ? !!formState.yesFraudOnAccount?.billing?.addressChangeToday
                  : !!formState.noFraudOnAccount?.billing?.addressChangeToday
              }
              label={strings.yes}
              onChange={() =>
                setFormState((prev) => ({
                  ...prev,
                  yesFraudOnAccount: prev.fraudOnAccount
                    ? {
                        ...prev.yesFraudOnAccount,
                        billing: {
                          ...prev.yesFraudOnAccount?.billing,
                          addressChangeToday: true,
                          finalInfo: undefined,
                        },
                      }
                    : undefined,
                  noFraudOnAccount: prev.fraudOnAccount
                    ? undefined
                    : {
                        ...prev.noFraudOnAccount,
                        billing: {
                          ...prev.noFraudOnAccount?.billing,
                          addressChangeToday: true,
                          finalInfo: undefined,
                        },
                      },
                }))
              }
            />
            <StyledRadioButton
              disabled={isSubmitting}
              checked={
                formState.fraudOnAccount
                  ? formState.yesFraudOnAccount?.billing?.addressChangeToday ===
                    false
                  : formState.noFraudOnAccount?.billing?.addressChangeToday ===
                    false
              }
              label={strings.no}
              onChange={() =>
                setFormState((prev) => ({
                  ...prev,
                  yesFraudOnAccount: prev.fraudOnAccount
                    ? {
                        ...prev.yesFraudOnAccount,
                        billing: {
                          ...prev.yesFraudOnAccount?.billing,
                          addressChangeToday: false,
                        },
                      }
                    : undefined,
                  noFraudOnAccount: prev.fraudOnAccount
                    ? undefined
                    : {
                        ...prev.noFraudOnAccount,
                        billing: {
                          ...prev.noFraudOnAccount?.billing,
                          addressChangeToday: false,
                        },
                      },
                }))
              }
            />
          </div>
          <div className={styles.divider} />
        </>
      ) : null}
      {formState.yesFraudOnAccount?.billing?.addressChangeToday ||
      formState.noFraudOnAccount?.billing?.addressChangeToday ? (
        <>
          <div className={styles.radioContainer}>
            <div className={classNames(styles.label, styles.radioLabel)}>
              {strings.newAddressVerify}
            </div>
            <StyledRadioButton
              className={styles.leftRadioInput}
              disabled={isSubmitting}
              checked={
                formState.fraudOnAccount
                  ? !!formState.yesFraudOnAccount?.billing?.newAddressVerify
                  : !!formState.noFraudOnAccount?.billing?.newAddressVerify
              }
              label={strings.yes}
              onChange={() =>
                setFormState((prev) => ({
                  ...prev,
                  yesFraudOnAccount: prev.fraudOnAccount
                    ? {
                        ...prev.yesFraudOnAccount,
                        billing: {
                          ...prev.yesFraudOnAccount?.billing,
                          newAddressVerify: true,
                        },
                      }
                    : undefined,
                  noFraudOnAccount: prev.fraudOnAccount
                    ? undefined
                    : {
                        ...prev.noFraudOnAccount,
                        billing: {
                          ...prev.noFraudOnAccount?.billing,
                          newAddressVerify: true,
                        },
                      },
                }))
              }
            />
            <StyledRadioButton
              disabled={isSubmitting}
              checked={
                formState.fraudOnAccount
                  ? formState.yesFraudOnAccount?.billing?.newAddressVerify ===
                    false
                  : formState.noFraudOnAccount?.billing?.newAddressVerify ===
                    false
              }
              label={strings.no}
              onChange={() =>
                setFormState((prev) => ({
                  ...prev,
                  yesFraudOnAccount: prev.fraudOnAccount
                    ? {
                        ...prev.yesFraudOnAccount,
                        billing: {
                          ...prev.yesFraudOnAccount?.billing,
                          newAddressVerify: false,
                        },
                      }
                    : undefined,
                  noFraudOnAccount: prev.fraudOnAccount
                    ? undefined
                    : {
                        ...prev.noFraudOnAccount,
                        billing: {
                          ...prev.noFraudOnAccount?.billing,
                          newAddressVerify: false,
                        },
                      },
                }))
              }
            />
          </div>
          <div className={styles.divider} />
        </>
      ) : null}
      {formState.yesFraudOnAccount?.billing?.newAddressVerify === false ||
      formState.noFraudOnAccount?.billing?.newAddressVerify === false ? (
        <>
          <PhoneNumberInput
            label={strings.cid}
            variant="horizontal"
            disabled={isSubmitting}
            value={
              (formState.fraudOnAccount
                ? formState.yesFraudOnAccount?.billing?.cid
                : formState.noFraudOnAccount?.billing?.cid) ?? ""
            }
            onChange={(value) =>
              setFormState((prev) => ({
                ...prev,
                yesFraudOnAccount: prev.fraudOnAccount
                  ? {
                      ...prev.yesFraudOnAccount,
                      billing: {
                        ...prev.yesFraudOnAccount?.billing,
                        cid: value,
                      },
                    }
                  : undefined,
                noFraudOnAccount: prev.fraudOnAccount
                  ? undefined
                  : {
                      ...prev.noFraudOnAccount,
                      billing: {
                        ...prev.noFraudOnAccount?.billing,
                        cid: value,
                      },
                    },
              }))
            }
          />
          <div className={styles.divider} />
        </>
      ) : null}
      {formState.yesFraudOnAccount?.shipReplacementTo ===
        ShipReplacementTo.Temporary ||
      formState.noFraudOnAccount?.shipReplacementTo ===
        ShipReplacementTo.Temporary ? (
        <>
          <div className={styles.radioContainer}>
            <div className={classNames(styles.label, styles.radioLabel)}>
              {strings.tempAddressVerify}
            </div>
            <StyledRadioButton
              className={styles.leftRadioInput}
              disabled={isSubmitting}
              checked={
                formState.fraudOnAccount
                  ? !!formState.yesFraudOnAccount?.temporary?.tempAddressVerify
                  : !!formState.noFraudOnAccount?.temporary?.tempAddressVerify
              }
              label={strings.yes}
              onChange={() =>
                setFormState((prev) => ({
                  ...prev,
                  yesFraudOnAccount: prev.fraudOnAccount
                    ? {
                        ...prev.yesFraudOnAccount,
                        temporary: {
                          ...prev.yesFraudOnAccount?.temporary,
                          tempAddressVerify: true,
                        },
                      }
                    : undefined,
                  noFraudOnAccount: prev.fraudOnAccount
                    ? undefined
                    : {
                        ...prev.noFraudOnAccount,
                        temporary: {
                          ...prev.noFraudOnAccount?.temporary,
                          tempAddressVerify: true,
                        },
                      },
                }))
              }
            />
            <StyledRadioButton
              disabled={isSubmitting}
              checked={
                formState.fraudOnAccount
                  ? formState.yesFraudOnAccount?.temporary
                      ?.tempAddressVerify === false
                  : formState.noFraudOnAccount?.temporary?.tempAddressVerify ===
                    false
              }
              label={strings.no}
              onChange={() =>
                setFormState((prev) => ({
                  ...prev,
                  yesFraudOnAccount: prev.fraudOnAccount
                    ? {
                        ...prev.yesFraudOnAccount,
                        temporary: {
                          ...prev.yesFraudOnAccount?.temporary,
                          tempAddressVerify: false,
                        },
                      }
                    : undefined,
                  noFraudOnAccount: prev.fraudOnAccount
                    ? undefined
                    : {
                        ...prev.noFraudOnAccount,
                        temporary: {
                          ...prev.noFraudOnAccount?.temporary,
                          tempAddressVerify: false,
                        },
                      },
                }))
              }
            />
          </div>
          <div className={styles.divider} />
        </>
      ) : null}
      {formState.yesFraudOnAccount?.temporary?.tempAddressVerify === false ||
      formState.noFraudOnAccount?.temporary?.tempAddressVerify === false ? (
        <>
          <PhoneNumberInput
            label={strings.cid}
            variant="horizontal"
            disabled={isSubmitting}
            value={
              (formState.fraudOnAccount
                ? formState.yesFraudOnAccount?.temporary?.cid
                : formState.noFraudOnAccount?.temporary?.cid) ?? ""
            }
            onChange={(value) =>
              setFormState((prev) => ({
                ...prev,
                yesFraudOnAccount: prev.fraudOnAccount
                  ? {
                      ...prev.yesFraudOnAccount,
                      temporary: {
                        ...prev.yesFraudOnAccount?.temporary,
                        cid: value,
                      },
                    }
                  : undefined,
                noFraudOnAccount: prev.fraudOnAccount
                  ? undefined
                  : {
                      ...prev.noFraudOnAccount,
                      temporary: {
                        ...prev.noFraudOnAccount?.temporary,
                        cid: value,
                      },
                    },
              }))
            }
          />
          <div className={styles.divider} />
        </>
      ) : null}
      {formState.yesFraudOnAccount?.temporary?.cid?.length ===
        CID_VALUE_LENGTH ||
      formState.noFraudOnAccount?.temporary?.cid?.length ===
        CID_VALUE_LENGTH ? (
        <>
          <Select
            variant="horizontal"
            disabled={isSubmitting}
            options={options.tempAddressReasons.map((tar) => ({
              value: tar.id,
              label: tar.value,
            }))}
            selectedValue={
              formState.fraudOnAccount
                ? formState.yesFraudOnAccount?.temporary?.reasonForTempAddress
                : formState.noFraudOnAccount?.temporary?.reasonForTempAddress
            }
            onChange={(value) =>
              setFormState((prev) => ({
                ...prev,
                yesFraudOnAccount: prev.fraudOnAccount
                  ? {
                      ...prev.yesFraudOnAccount,
                      temporary: {
                        ...prev.yesFraudOnAccount?.temporary,
                        reasonForTempAddress: value,
                      },
                    }
                  : undefined,
                noFraudOnAccount: prev.fraudOnAccount
                  ? undefined
                  : {
                      ...prev.noFraudOnAccount,
                      temporary: {
                        ...prev.noFraudOnAccount?.temporary,
                        reasonForTempAddress: value,
                      },
                    },
              }))
            }
            label={strings.reasonForTempAddress}
            labelClassName={styles.label}
            placeholder={strings.select}
          />
          <div className={styles.divider} />
        </>
      ) : null}
      {formState.yesFraudOnAccount?.temporary?.tempAddressVerify ||
      formState.noFraudOnAccount?.temporary?.tempAddressVerify ||
      formState.yesFraudOnAccount?.temporary?.reasonForTempAddress !==
        undefined ||
      formState?.noFraudOnAccount?.temporary?.reasonForTempAddress !==
        undefined ? (
        <>
          <StyledInput
            className={styles.tempAddressField}
            variant="horizontal"
            disabled={isSubmitting}
            labelClassName={styles.label}
            label={strings.tempAddress1}
            value={
              (formState.fraudOnAccount
                ? formState.yesFraudOnAccount?.temporary?.tempAddress?.address1
                : formState.noFraudOnAccount?.temporary?.tempAddress
                    ?.address1) ?? ""
            }
            onChange={(e) =>
              setFormState((prev) => ({
                ...prev,
                yesFraudOnAccount: prev.fraudOnAccount
                  ? {
                      ...prev.yesFraudOnAccount,
                      temporary: {
                        ...prev.yesFraudOnAccount?.temporary,
                        tempAddress: {
                          ...prev.yesFraudOnAccount?.temporary?.tempAddress,
                          address1: e.target.value,
                        },
                      },
                    }
                  : undefined,
                noFraudOnAccount: prev.fraudOnAccount
                  ? undefined
                  : {
                      ...prev.noFraudOnAccount,
                      temporary: {
                        ...prev.noFraudOnAccount?.temporary,
                        tempAddress: {
                          ...prev.noFraudOnAccount?.temporary?.tempAddress,
                          address1: e.target.value,
                        },
                      },
                    },
              }))
            }
          />
          <StyledInput
            className={styles.tempAddressField}
            variant="horizontal"
            disabled={isSubmitting}
            labelClassName={styles.label}
            label={strings.tempAddress2}
            value={
              (formState.fraudOnAccount
                ? formState.yesFraudOnAccount?.temporary?.tempAddress?.address2
                : formState.noFraudOnAccount?.temporary?.tempAddress
                    ?.address2) ?? ""
            }
            onChange={(e) =>
              setFormState((prev) => ({
                ...prev,
                yesFraudOnAccount: prev.fraudOnAccount
                  ? {
                      ...prev.yesFraudOnAccount,
                      temporary: {
                        ...prev.yesFraudOnAccount?.temporary,
                        tempAddress: {
                          ...prev.yesFraudOnAccount?.temporary?.tempAddress,
                          address2: e.target.value,
                        },
                      },
                    }
                  : undefined,
                noFraudOnAccount: prev.fraudOnAccount
                  ? undefined
                  : {
                      ...prev.noFraudOnAccount,
                      temporary: {
                        ...prev.noFraudOnAccount?.temporary,
                        tempAddress: {
                          ...prev.noFraudOnAccount?.temporary?.tempAddress,
                          address2: e.target.value,
                        },
                      },
                    },
              }))
            }
          />
          <StyledInput
            variant="horizontal"
            className={styles.tempAddressField}
            disabled={isSubmitting}
            labelClassName={styles.label}
            label={strings.city}
            value={
              (formState.fraudOnAccount
                ? formState.yesFraudOnAccount?.temporary?.tempAddress?.city
                : formState.noFraudOnAccount?.temporary?.tempAddress?.city) ??
              ""
            }
            onChange={(e) =>
              setFormState((prev) => ({
                ...prev,
                yesFraudOnAccount: prev.fraudOnAccount
                  ? {
                      ...prev.yesFraudOnAccount,
                      temporary: {
                        ...prev.yesFraudOnAccount?.temporary,
                        tempAddress: {
                          ...prev.yesFraudOnAccount?.temporary?.tempAddress,
                          city: e.target.value,
                        },
                      },
                    }
                  : undefined,
                noFraudOnAccount: prev.fraudOnAccount
                  ? undefined
                  : {
                      ...prev.noFraudOnAccount,
                      temporary: {
                        ...prev.noFraudOnAccount?.temporary,
                        tempAddress: {
                          ...prev.noFraudOnAccount?.temporary?.tempAddress,
                          city: e.target.value,
                        },
                      },
                    },
              }))
            }
          />
          <Select
            variant="horizontal"
            className={styles.tempAddressField}
            disabled={isSubmitting}
            label={strings.state}
            labelClassName={styles.label}
            placeholder={strings.select}
            options={options.tempAddressStates.map((ls) => ({
              value: ls.id,
              label: ls.value,
            }))}
            selectedValue={
              formState?.fraudOnAccount
                ? formState.yesFraudOnAccount?.temporary?.tempAddress?.state
                : formState.noFraudOnAccount?.temporary?.tempAddress?.state
            }
            onChange={(value) =>
              setFormState((prev) => ({
                ...prev,
                yesFraudOnAccount: prev.fraudOnAccount
                  ? {
                      ...prev.yesFraudOnAccount,
                      temporary: {
                        ...prev.yesFraudOnAccount?.temporary,
                        tempAddress: {
                          ...prev.yesFraudOnAccount?.temporary?.tempAddress,
                          state: value,
                        },
                      },
                    }
                  : undefined,
                noFraudOnAccount: prev.fraudOnAccount
                  ? undefined
                  : {
                      ...prev.noFraudOnAccount,
                      temporary: {
                        ...prev.noFraudOnAccount?.temporary,
                        tempAddress: {
                          ...prev.noFraudOnAccount?.temporary?.tempAddress,
                          state: value,
                        },
                      },
                    },
              }))
            }
          />
          <StyledZipcodeInput
            variant="horizontal"
            disabled={isSubmitting}
            label={strings.zip}
            labelClassName={styles.label}
            value={
              (formState.fraudOnAccount
                ? formState.yesFraudOnAccount?.temporary?.tempAddress?.zip
                : formState.noFraudOnAccount?.temporary?.tempAddress?.zip) ?? ""
            }
            onChange={(value) =>
              setFormState((prev) => ({
                ...prev,
                yesFraudOnAccount: prev.fraudOnAccount
                  ? {
                      ...prev.yesFraudOnAccount,
                      temporary: {
                        ...prev.yesFraudOnAccount?.temporary,
                        tempAddress: {
                          ...prev.yesFraudOnAccount?.temporary?.tempAddress,
                          zip: value,
                        },
                      },
                    }
                  : undefined,
                noFraudOnAccount: prev.fraudOnAccount
                  ? undefined
                  : {
                      ...prev.noFraudOnAccount,
                      temporary: {
                        ...prev.noFraudOnAccount?.temporary,
                        tempAddress: {
                          ...prev.noFraudOnAccount?.temporary?.tempAddress,
                          zip: value,
                        },
                      },
                    },
              }))
            }
          />
          <div className={styles.divider} />
        </>
      ) : null}
      {
        // no address change today, OR
        formState.yesFraudOnAccount?.billing?.addressChangeToday === false ||
        formState.noFraudOnAccount?.billing?.addressChangeToday === false ||
        // new address has been verified, OR
        formState.yesFraudOnAccount?.billing?.newAddressVerify ||
        formState.noFraudOnAccount?.billing?.newAddressVerify ||
        // new address hasn't been verified and cid is entered, OR
        formState.yesFraudOnAccount?.billing?.cid?.length ===
          CID_VALUE_LENGTH ||
        formState.noFraudOnAccount?.billing?.cid?.length === CID_VALUE_LENGTH ||
        // temporary address has been fully entered
        (formState.yesFraudOnAccount?.temporary?.tempAddress?.address1 &&
          formState?.yesFraudOnAccount?.temporary?.tempAddress?.city &&
          formState.yesFraudOnAccount?.temporary?.tempAddress?.state &&
          formState?.yesFraudOnAccount?.temporary?.tempAddress?.zip) ||
        (formState.noFraudOnAccount?.temporary?.tempAddress?.address1 &&
          formState?.noFraudOnAccount?.temporary?.tempAddress?.city &&
          formState.noFraudOnAccount?.temporary?.tempAddress?.state &&
          formState?.noFraudOnAccount?.temporary?.tempAddress?.zip) ? (
          <ReplacementOptionsComponent
            disabled={isSubmitting}
            replacementTypes={options.replacementTypes}
            selectedValue={
              formState.fraudOnAccount
                ? formState.yesFraudOnAccount?.shipReplacementTo ===
                  ShipReplacementTo.Billing
                  ? formState.yesFraudOnAccount?.billing?.finalInfo
                      ?.replacementOption
                  : formState.yesFraudOnAccount?.temporary?.finalInfo
                      ?.replacementOption
                : formState.noFraudOnAccount?.shipReplacementTo ===
                    ShipReplacementTo.Billing
                  ? formState.noFraudOnAccount?.billing?.finalInfo
                      ?.replacementOption
                  : formState.noFraudOnAccount?.temporary?.finalInfo
                      ?.replacementOption
            }
            onChange={(value: ReplacementType) =>
              setFormState((prev) => ({
                ...prev,
                yesFraudOnAccount: prev.fraudOnAccount
                  ? {
                      ...prev.yesFraudOnAccount,
                      billing:
                        prev.yesFraudOnAccount?.shipReplacementTo ===
                        ShipReplacementTo.Billing
                          ? {
                              ...prev.yesFraudOnAccount?.billing,
                              finalInfo: {
                                ...prev.yesFraudOnAccount?.billing?.finalInfo,
                                replacementOption: value,
                              },
                            }
                          : undefined,
                      temporary:
                        prev.yesFraudOnAccount?.shipReplacementTo ===
                        ShipReplacementTo.Temporary
                          ? {
                              ...prev.yesFraudOnAccount?.temporary,
                              finalInfo: {
                                ...prev.yesFraudOnAccount?.temporary?.finalInfo,
                                replacementOption: value,
                              },
                            }
                          : undefined,
                    }
                  : undefined,
                noFraudOnAccount: prev.fraudOnAccount
                  ? undefined
                  : {
                      ...prev.noFraudOnAccount,
                      billing:
                        prev.noFraudOnAccount?.shipReplacementTo ===
                        ShipReplacementTo.Billing
                          ? {
                              ...prev.noFraudOnAccount?.billing,
                              finalInfo: {
                                ...prev.noFraudOnAccount?.billing?.finalInfo,
                                replacementOption: value,
                              },
                            }
                          : undefined,
                      temporary:
                        prev.noFraudOnAccount?.shipReplacementTo ===
                        ShipReplacementTo.Temporary
                          ? {
                              ...prev.noFraudOnAccount?.temporary,
                              finalInfo: {
                                ...prev.noFraudOnAccount?.temporary?.finalInfo,
                                replacementOption: value,
                              },
                            }
                          : undefined,
                    },
              }))
            }
          />
        ) : null
      }
      {finalInfo?.replacementOption ? (
        <>
          <div className={styles.radioContainer}>
            <div className={classNames(styles.label, styles.radioLabel)}>
              {strings.waiveReplacementFee}
            </div>
            <StyledRadioButton
              className={styles.leftRadioInput}
              disabled={isSubmitting}
              checked={!!finalInfo?.waiveReplacementFee}
              label={strings.yes}
              onChange={() =>
                setFormState((prev) => ({
                  ...prev,
                  earlyTermination: prev.earlyTermination
                    ? {
                        ...prev.earlyTermination,
                        finalInfo: {
                          ...prev.earlyTermination.finalInfo,
                          waiveReplacementFee: true,
                        },
                      }
                    : undefined,
                  yesFraudOnAccount: prev.fraudOnAccount
                    ? {
                        ...prev.yesFraudOnAccount,
                        billing:
                          prev.yesFraudOnAccount?.shipReplacementTo ===
                          ShipReplacementTo.Billing
                            ? {
                                ...prev.yesFraudOnAccount?.billing,
                                finalInfo: {
                                  ...prev.yesFraudOnAccount?.billing?.finalInfo,
                                  waiveReplacementFee: true,
                                },
                              }
                            : undefined,
                        temporary:
                          prev.yesFraudOnAccount?.shipReplacementTo ===
                          ShipReplacementTo.Temporary
                            ? {
                                ...prev.yesFraudOnAccount?.temporary,
                                finalInfo: {
                                  ...prev.yesFraudOnAccount?.temporary
                                    ?.finalInfo,
                                  waiveReplacementFee: true,
                                },
                              }
                            : undefined,
                      }
                    : undefined,
                  noFraudOnAccount: prev.fraudOnAccount
                    ? undefined
                    : {
                        ...prev.noFraudOnAccount,
                        billing:
                          prev.noFraudOnAccount?.shipReplacementTo ===
                          ShipReplacementTo.Billing
                            ? {
                                ...prev.noFraudOnAccount?.billing,
                                finalInfo: {
                                  ...prev.noFraudOnAccount?.billing?.finalInfo,
                                  waiveReplacementFee: true,
                                },
                              }
                            : undefined,
                        temporary:
                          prev.noFraudOnAccount?.shipReplacementTo ===
                          ShipReplacementTo.Temporary
                            ? {
                                ...prev.noFraudOnAccount?.temporary,
                                finalInfo: {
                                  ...prev.noFraudOnAccount?.temporary
                                    ?.finalInfo,
                                  waiveReplacementFee: true,
                                },
                              }
                            : undefined,
                      },
                }))
              }
            />
            <StyledRadioButton
              disabled={isSubmitting}
              checked={finalInfo?.waiveReplacementFee === false}
              label={strings.no}
              onChange={() =>
                setFormState((prev) => ({
                  ...prev,
                  earlyTermination: prev.earlyTermination
                    ? {
                        ...prev.earlyTermination,
                        finalInfo: {
                          ...prev.earlyTermination.finalInfo,
                          waiveReplacementFee: false,
                        },
                      }
                    : undefined,
                  yesFraudOnAccount: prev.fraudOnAccount
                    ? {
                        ...prev.yesFraudOnAccount,
                        billing:
                          prev.yesFraudOnAccount?.shipReplacementTo ===
                          ShipReplacementTo.Billing
                            ? {
                                ...prev.yesFraudOnAccount?.billing,
                                finalInfo: {
                                  ...prev.yesFraudOnAccount?.billing?.finalInfo,
                                  waiveReplacementFee: false,
                                },
                              }
                            : undefined,
                        temporary:
                          prev.yesFraudOnAccount?.shipReplacementTo ===
                          ShipReplacementTo.Temporary
                            ? {
                                ...prev.yesFraudOnAccount?.temporary,
                                finalInfo: {
                                  ...prev.yesFraudOnAccount?.temporary
                                    ?.finalInfo,
                                  waiveReplacementFee: false,
                                },
                              }
                            : undefined,
                      }
                    : undefined,
                  noFraudOnAccount: prev.fraudOnAccount
                    ? undefined
                    : {
                        ...prev.noFraudOnAccount,
                        billing:
                          prev.noFraudOnAccount?.shipReplacementTo ===
                          ShipReplacementTo.Billing
                            ? {
                                ...prev.noFraudOnAccount?.billing,
                                finalInfo: {
                                  ...prev.noFraudOnAccount?.billing?.finalInfo,
                                  waiveReplacementFee: false,
                                },
                              }
                            : undefined,
                        temporary:
                          prev.noFraudOnAccount?.shipReplacementTo ===
                          ShipReplacementTo.Temporary
                            ? {
                                ...prev.noFraudOnAccount?.temporary,
                                finalInfo: {
                                  ...prev.noFraudOnAccount?.temporary
                                    ?.finalInfo,
                                  waiveReplacementFee: false,
                                },
                              }
                            : undefined,
                      },
                }))
              }
            />
          </div>
          <div className={styles.divider} />
          {finalInfo?.replacementOption === ReplacementType.RushFedex ||
          finalInfo?.replacementOption === ReplacementType.RushPostal ? (
            <>
              <div className={styles.radioContainer}>
                <div className={classNames(styles.label, styles.radioLabel)}>
                  {strings.waiveRushFee}
                </div>
                <StyledRadioButton
                  className={styles.leftRadioInput}
                  disabled={isSubmitting}
                  checked={!!finalInfo?.waiveRushFee}
                  label={strings.yes}
                  onChange={() =>
                    setFormState((prev) => ({
                      ...prev,
                      earlyTermination: prev.earlyTermination
                        ? {
                            ...prev.earlyTermination,
                            finalInfo: {
                              ...prev.earlyTermination.finalInfo,
                              waiveRushFee: true,
                            },
                          }
                        : undefined,
                      yesFraudOnAccount: prev.fraudOnAccount
                        ? {
                            ...prev.yesFraudOnAccount,
                            billing:
                              prev.yesFraudOnAccount?.shipReplacementTo ===
                              ShipReplacementTo.Billing
                                ? {
                                    ...prev.yesFraudOnAccount?.billing,
                                    finalInfo: {
                                      ...prev.yesFraudOnAccount?.billing
                                        ?.finalInfo,
                                      waiveRushFee: true,
                                    },
                                  }
                                : undefined,
                            temporary:
                              prev.yesFraudOnAccount?.shipReplacementTo ===
                              ShipReplacementTo.Temporary
                                ? {
                                    ...prev.yesFraudOnAccount?.temporary,
                                    finalInfo: {
                                      ...prev.yesFraudOnAccount?.temporary
                                        ?.finalInfo,
                                      waiveRushFee: true,
                                    },
                                  }
                                : undefined,
                          }
                        : undefined,
                      noFraudOnAccount: prev.fraudOnAccount
                        ? undefined
                        : {
                            ...prev.noFraudOnAccount,
                            billing:
                              prev.noFraudOnAccount?.shipReplacementTo ===
                              ShipReplacementTo.Billing
                                ? {
                                    ...prev.noFraudOnAccount?.billing,
                                    finalInfo: {
                                      ...prev.noFraudOnAccount?.billing
                                        ?.finalInfo,
                                      waiveRushFee: true,
                                    },
                                  }
                                : undefined,
                            temporary:
                              prev.noFraudOnAccount?.shipReplacementTo ===
                              ShipReplacementTo.Temporary
                                ? {
                                    ...prev.noFraudOnAccount?.temporary,
                                    finalInfo: {
                                      ...prev.noFraudOnAccount?.temporary
                                        ?.finalInfo,
                                      waiveRushFee: true,
                                    },
                                  }
                                : undefined,
                          },
                    }))
                  }
                />
                <StyledRadioButton
                  disabled={isSubmitting}
                  checked={finalInfo?.waiveRushFee === false}
                  label={strings.no}
                  onChange={() =>
                    setFormState((prev) => ({
                      ...prev,
                      earlyTermination: prev.earlyTermination
                        ? {
                            ...prev.earlyTermination,
                            finalInfo: {
                              ...prev.earlyTermination.finalInfo,
                              waiveRushFee: false,
                            },
                          }
                        : undefined,
                      yesFraudOnAccount: prev.fraudOnAccount
                        ? {
                            ...prev.yesFraudOnAccount,
                            billing:
                              prev.yesFraudOnAccount?.shipReplacementTo ===
                              ShipReplacementTo.Billing
                                ? {
                                    ...prev.yesFraudOnAccount?.billing,
                                    finalInfo: {
                                      ...prev.yesFraudOnAccount?.billing
                                        ?.finalInfo,
                                      waiveRushFee: false,
                                    },
                                  }
                                : undefined,
                            temporary:
                              prev.yesFraudOnAccount?.shipReplacementTo ===
                              ShipReplacementTo.Temporary
                                ? {
                                    ...prev.yesFraudOnAccount?.temporary,
                                    finalInfo: {
                                      ...prev.yesFraudOnAccount?.temporary
                                        ?.finalInfo,
                                      waiveRushFee: false,
                                    },
                                  }
                                : undefined,
                          }
                        : undefined,
                      noFraudOnAccount: prev.fraudOnAccount
                        ? undefined
                        : {
                            ...prev.noFraudOnAccount,
                            billing:
                              prev.noFraudOnAccount?.shipReplacementTo ===
                              ShipReplacementTo.Billing
                                ? {
                                    ...prev.noFraudOnAccount?.billing,
                                    finalInfo: {
                                      ...prev.noFraudOnAccount?.billing
                                        ?.finalInfo,
                                      waiveRushFee: false,
                                    },
                                  }
                                : undefined,
                            temporary:
                              prev.noFraudOnAccount?.shipReplacementTo ===
                              ShipReplacementTo.Temporary
                                ? {
                                    ...prev.noFraudOnAccount?.temporary,
                                    finalInfo: {
                                      ...prev.noFraudOnAccount?.temporary
                                        ?.finalInfo,
                                      waiveRushFee: false,
                                    },
                                  }
                                : undefined,
                          },
                    }))
                  }
                />
              </div>
              <div className={styles.divider} />
            </>
          ) : null}
          <DateInput
            variant="horizontal"
            disabled={isSubmitting}
            label={strings.dateLost}
            labelClassName={styles.label}
            inputClassName={styles.dateInput}
            value={finalInfo?.dateLost ?? ""}
            onChangeDate={(value) =>
              setFormState((prev) => ({
                ...prev,
                earlyTermination: prev.earlyTermination
                  ? {
                      ...prev.earlyTermination,
                      finalInfo: {
                        ...prev.earlyTermination.finalInfo,
                        dateLost: value,
                      },
                    }
                  : undefined,
                yesFraudOnAccount: prev.fraudOnAccount
                  ? {
                      ...prev.yesFraudOnAccount,
                      billing:
                        prev.yesFraudOnAccount?.shipReplacementTo ===
                        ShipReplacementTo.Billing
                          ? {
                              ...prev.yesFraudOnAccount?.billing,
                              finalInfo: {
                                ...prev.yesFraudOnAccount?.billing?.finalInfo,
                                dateLost: value,
                              },
                            }
                          : undefined,
                      temporary:
                        prev.yesFraudOnAccount?.shipReplacementTo ===
                        ShipReplacementTo.Temporary
                          ? {
                              ...prev.yesFraudOnAccount?.temporary,
                              finalInfo: {
                                ...prev.yesFraudOnAccount?.temporary?.finalInfo,
                                dateLost: value,
                              },
                            }
                          : undefined,
                    }
                  : undefined,
                noFraudOnAccount: prev.fraudOnAccount
                  ? undefined
                  : {
                      ...prev.noFraudOnAccount,
                      billing:
                        prev.noFraudOnAccount?.shipReplacementTo ===
                        ShipReplacementTo.Billing
                          ? {
                              ...prev.noFraudOnAccount?.billing,
                              finalInfo: {
                                ...prev.noFraudOnAccount?.billing?.finalInfo,
                                dateLost: value,
                              },
                            }
                          : undefined,
                      temporary:
                        prev.noFraudOnAccount?.shipReplacementTo ===
                        ShipReplacementTo.Temporary
                          ? {
                              ...prev.noFraudOnAccount?.temporary,
                              finalInfo: {
                                ...prev.noFraudOnAccount?.temporary?.finalInfo,
                                dateLost: value,
                              },
                            }
                          : undefined,
                    },
              }))
            }
          />
          <div className={styles.divider} />
          <Select
            variant="horizontal"
            disabled={isSubmitting}
            label={strings.lossLocation}
            labelClassName={styles.label}
            placeholder={strings.select}
            options={options.lossLocations.map((ll) => ({
              value: ll.id,
              label: ll.value,
            }))}
            selectedValue={finalInfo?.lossLocation}
            onChange={(value) =>
              setFormState((prev) => ({
                ...prev,
                earlyTermination: prev.earlyTermination
                  ? {
                      ...prev.earlyTermination,
                      finalInfo: {
                        ...prev.earlyTermination.finalInfo,
                        lossLocation: value,
                      },
                    }
                  : undefined,
                yesFraudOnAccount: prev.fraudOnAccount
                  ? {
                      ...prev.yesFraudOnAccount,
                      billing:
                        prev.yesFraudOnAccount?.shipReplacementTo ===
                        ShipReplacementTo.Billing
                          ? {
                              ...prev.yesFraudOnAccount?.billing,
                              finalInfo: {
                                ...prev.yesFraudOnAccount?.billing?.finalInfo,
                                lossLocation: value,
                              },
                            }
                          : undefined,
                      temporary:
                        prev.yesFraudOnAccount?.shipReplacementTo ===
                        ShipReplacementTo.Temporary
                          ? {
                              ...prev.yesFraudOnAccount?.temporary,
                              finalInfo: {
                                ...prev.yesFraudOnAccount?.temporary?.finalInfo,
                                lossLocation: value,
                              },
                            }
                          : undefined,
                    }
                  : undefined,
                noFraudOnAccount: prev.fraudOnAccount
                  ? undefined
                  : {
                      ...prev.noFraudOnAccount,
                      billing:
                        prev.noFraudOnAccount?.shipReplacementTo ===
                        ShipReplacementTo.Billing
                          ? {
                              ...prev.noFraudOnAccount?.billing,
                              finalInfo: {
                                ...prev.noFraudOnAccount?.billing?.finalInfo,
                                lossLocation: value,
                              },
                            }
                          : undefined,
                      temporary:
                        prev.noFraudOnAccount?.shipReplacementTo ===
                        ShipReplacementTo.Temporary
                          ? {
                              ...prev.noFraudOnAccount?.temporary,
                              finalInfo: {
                                ...prev.noFraudOnAccount?.temporary?.finalInfo,
                                lossLocation: value,
                              },
                            }
                          : undefined,
                    },
              }))
            }
          />
          <div className={styles.divider} />
          <Select
            variant="horizontal"
            disabled={isSubmitting}
            label={strings.stateOfLoss}
            labelClassName={styles.label}
            placeholder={strings.select}
            options={options.lossStates.map((ls) => ({
              value: ls.id,
              label: ls.value,
            }))}
            selectedValue={finalInfo?.stateOfLoss}
            onChange={(value) =>
              setFormState((prev) => ({
                ...prev,
                earlyTermination: prev.earlyTermination
                  ? {
                      ...prev.earlyTermination,
                      finalInfo: {
                        ...prev.earlyTermination.finalInfo,
                        stateOfLoss: value,
                      },
                    }
                  : undefined,
                yesFraudOnAccount: prev.fraudOnAccount
                  ? {
                      ...prev.yesFraudOnAccount,
                      billing:
                        prev.yesFraudOnAccount?.shipReplacementTo ===
                        ShipReplacementTo.Billing
                          ? {
                              ...prev.yesFraudOnAccount?.billing,
                              finalInfo: {
                                ...prev.yesFraudOnAccount?.billing?.finalInfo,
                                stateOfLoss: value,
                              },
                            }
                          : undefined,
                      temporary:
                        prev.yesFraudOnAccount?.shipReplacementTo ===
                        ShipReplacementTo.Temporary
                          ? {
                              ...prev.yesFraudOnAccount?.temporary,
                              finalInfo: {
                                ...prev.yesFraudOnAccount?.temporary?.finalInfo,
                                stateOfLoss: value,
                              },
                            }
                          : undefined,
                    }
                  : undefined,
                noFraudOnAccount: prev.fraudOnAccount
                  ? undefined
                  : {
                      ...prev.noFraudOnAccount,
                      billing:
                        prev.noFraudOnAccount?.shipReplacementTo ===
                        ShipReplacementTo.Billing
                          ? {
                              ...prev.noFraudOnAccount?.billing,
                              finalInfo: {
                                ...prev.noFraudOnAccount?.billing?.finalInfo,
                                stateOfLoss: value,
                              },
                            }
                          : undefined,
                      temporary:
                        prev.noFraudOnAccount?.shipReplacementTo ===
                        ShipReplacementTo.Temporary
                          ? {
                              ...prev.noFraudOnAccount?.temporary,
                              finalInfo: {
                                ...prev.noFraudOnAccount?.temporary?.finalInfo,
                                stateOfLoss: value,
                              },
                            }
                          : undefined,
                    },
              }))
            }
          />
          <div className={styles.divider} />
          <div className={styles.radioContainer}>
            <div className={classNames(styles.label, styles.radioLabel)}>
              {strings.pinCompromised}
            </div>
            <StyledRadioButton
              className={styles.leftRadioInput}
              disabled={isSubmitting}
              checked={!!finalInfo?.pinCompromised}
              label={strings.yes}
              onChange={() =>
                setFormState((prev) => ({
                  ...prev,
                  earlyTermination: prev.earlyTermination
                    ? {
                        ...prev.earlyTermination,
                        finalInfo: {
                          ...prev.earlyTermination.finalInfo,
                          pinCompromised: true,
                        },
                      }
                    : undefined,
                  yesFraudOnAccount: prev.fraudOnAccount
                    ? {
                        ...prev.yesFraudOnAccount,
                        billing:
                          prev.yesFraudOnAccount?.shipReplacementTo ===
                          ShipReplacementTo.Billing
                            ? {
                                ...prev.yesFraudOnAccount?.billing,
                                finalInfo: {
                                  ...prev.yesFraudOnAccount?.billing?.finalInfo,
                                  pinCompromised: true,
                                },
                              }
                            : undefined,
                        temporary:
                          prev.yesFraudOnAccount?.shipReplacementTo ===
                          ShipReplacementTo.Temporary
                            ? {
                                ...prev.yesFraudOnAccount?.temporary,
                                finalInfo: {
                                  ...prev.yesFraudOnAccount?.temporary
                                    ?.finalInfo,
                                  pinCompromised: true,
                                },
                              }
                            : undefined,
                      }
                    : undefined,
                  noFraudOnAccount: prev.fraudOnAccount
                    ? undefined
                    : {
                        ...prev.noFraudOnAccount,
                        billing:
                          prev.noFraudOnAccount?.shipReplacementTo ===
                          ShipReplacementTo.Billing
                            ? {
                                ...prev.noFraudOnAccount?.billing,
                                finalInfo: {
                                  ...prev.noFraudOnAccount?.billing?.finalInfo,
                                  pinCompromised: true,
                                },
                              }
                            : undefined,
                        temporary:
                          prev.noFraudOnAccount?.shipReplacementTo ===
                          ShipReplacementTo.Temporary
                            ? {
                                ...prev.noFraudOnAccount?.temporary,
                                finalInfo: {
                                  ...prev.noFraudOnAccount?.temporary
                                    ?.finalInfo,
                                  pinCompromised: true,
                                },
                              }
                            : undefined,
                      },
                }))
              }
            />
            <StyledRadioButton
              disabled={isSubmitting}
              checked={finalInfo?.pinCompromised === false}
              label={strings.no}
              onChange={() =>
                setFormState((prev) => ({
                  ...prev,
                  earlyTermination: prev.earlyTermination
                    ? {
                        ...prev.earlyTermination,
                        finalInfo: {
                          ...prev.earlyTermination.finalInfo,
                          pinCompromised: false,
                        },
                      }
                    : undefined,
                  yesFraudOnAccount: prev.fraudOnAccount
                    ? {
                        ...prev.yesFraudOnAccount,
                        billing:
                          prev.yesFraudOnAccount?.shipReplacementTo ===
                          ShipReplacementTo.Billing
                            ? {
                                ...prev.yesFraudOnAccount?.billing,
                                finalInfo: {
                                  ...prev.yesFraudOnAccount?.billing?.finalInfo,
                                  pinCompromised: false,
                                },
                              }
                            : undefined,
                        temporary:
                          prev.yesFraudOnAccount?.shipReplacementTo ===
                          ShipReplacementTo.Temporary
                            ? {
                                ...prev.yesFraudOnAccount?.temporary,
                                finalInfo: {
                                  ...prev.yesFraudOnAccount?.temporary
                                    ?.finalInfo,
                                  pinCompromised: false,
                                },
                              }
                            : undefined,
                      }
                    : undefined,
                  noFraudOnAccount: prev.fraudOnAccount
                    ? undefined
                    : {
                        ...prev.noFraudOnAccount,
                        billing:
                          prev.noFraudOnAccount?.shipReplacementTo ===
                          ShipReplacementTo.Billing
                            ? {
                                ...prev.noFraudOnAccount?.billing,
                                finalInfo: {
                                  ...prev.noFraudOnAccount?.billing?.finalInfo,
                                  pinCompromised: false,
                                },
                              }
                            : undefined,
                        temporary:
                          prev.noFraudOnAccount?.shipReplacementTo ===
                          ShipReplacementTo.Temporary
                            ? {
                                ...prev.noFraudOnAccount?.temporary,
                                finalInfo: {
                                  ...prev.noFraudOnAccount?.temporary
                                    ?.finalInfo,
                                  pinCompromised: false,
                                },
                              }
                            : undefined,
                      },
                }))
              }
            />
          </div>
          <div className={styles.divider} />
          {formState.fraudOnAccount ? (
            <>
              <label className={classNames(styles.label, styles.textareaLabel)}>
                {strings.fraudCharges}
                <textarea
                  disabled={isSubmitting}
                  className={styles.textareaInput}
                  value={finalInfo?.fraudCharges}
                  onChange={(e) =>
                    setFormState((prev) => ({
                      ...prev,
                      yesFraudOnAccount: {
                        ...prev.yesFraudOnAccount,
                        billing:
                          prev.yesFraudOnAccount?.shipReplacementTo ===
                          ShipReplacementTo.Billing
                            ? {
                                ...prev.yesFraudOnAccount?.billing,
                                finalInfo: {
                                  ...prev.yesFraudOnAccount?.billing?.finalInfo,
                                  fraudCharges: e.target.value,
                                },
                              }
                            : undefined,
                        temporary:
                          prev.yesFraudOnAccount?.shipReplacementTo ===
                          ShipReplacementTo.Temporary
                            ? {
                                ...prev.yesFraudOnAccount?.temporary,
                                finalInfo: {
                                  ...prev.yesFraudOnAccount?.temporary
                                    ?.finalInfo,
                                  fraudCharges: e.target.value,
                                },
                              }
                            : undefined,
                      },
                    }))
                  }
                />
              </label>
              <div className={styles.divider} />
            </>
          ) : null}
        </>
      ) : null}
      <BaseModal
        className={styles.modal}
        label={strings.transferToSecurityModalTitle}
        isOpen={showTransferToSecurityModal}
        onRequestClose={() => setShowTransferToSecurityModal(false)}
      >
        <img src={warningIcon} alt="" />
        <div className={styles.modalTitle}>
          {strings.transferToSecurityModalTitle}
        </div>
        <div className={styles.modalDescription}>
          {strings.transferToSecurityModalDescription}
        </div>
        <StyledButton onClick={closeTool}>{strings.closeTool}</StyledButton>
        <StyledButton
          className={styles.goBack}
          variant="secondary"
          onClick={() => setShowTransferToSecurityModal(false)}
        >
          {strings.goBack}
        </StyledButton>
      </BaseModal>
    </>
  );
}
