import classNames from "classnames";
import { Col, Row } from "react-grid-system";
import { GetAccountResponse } from "../../lib/api/models";
import { ToolType } from "../AccountDetails";
import { toolTypeToName } from "../AccountDetails/utils";
import StyledButton from "../common/buttons/StyledButton";
import Container from "../common/Container";
import styles from "./Phones.module.css";
import { useState } from "react";
import { nanoid } from "nanoid";

const strings = {
  close: "Close",
};

type PhonesToolStatus =
  | {
      status: "DEFAULT";
      currentPhoneId: null;
    }
  | {
      status: "VIEW";
      currentPhoneId: string;
    }
  | {
      status: "EDIT";
      currentPhoneId: string;
    }
  | {
      status: "ADD";
      currentPhoneId: null;
    };

const getTitle = (status: PhonesToolStatus) => {
  switch (status.status) {
    case "DEFAULT":
      return toolTypeToName(ToolType.Phones);
    case "VIEW":
      return "View Number";
    case "EDIT":
      return "Edit Number";
    case "ADD":
      return "Add Number";
  }
};

type Props = {
  account: GetAccountResponse;
  className?: string;
  toolId: string;
  onRequestClose: () => void;
  onSetToolInProgress: (id: string, inProgress: boolean) => void;
};

export default function Phones({ toolId, onRequestClose, className }: Props) {
  const [status, setStatus] = useState<PhonesToolStatus>({
    status: "DEFAULT",
    currentPhoneId: null,
  });

  return (
    <div className={classNames(styles.mainContainer, className)} id={toolId}>
      <Container>
        <Row>
          <Col>
            <h2 className={styles.header}>{getTitle(status)}</h2>
          </Col>
          <Col offset={{ md: 6 }} md={1} className={styles.centerCol}>
            <StyledButton
              className={styles.closeButton}
              variant="secondary"
              onClick={onRequestClose}
            >
              {strings.close}
            </StyledButton>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Current Phone ID: {status.currentPhoneId}</p>
            {status.status === "DEFAULT" ? (
              <>
                <StyledButton
                  onClick={() =>
                    setStatus({
                      status: "VIEW",
                      currentPhoneId: nanoid(),
                    })
                  }
                >
                  View Phone
                </StyledButton>
                <br />
                <StyledButton
                  onClick={() =>
                    setStatus({
                      status: "EDIT",
                      currentPhoneId: nanoid(),
                    })
                  }
                >
                  Edit Phone
                </StyledButton>
                <br />
                <StyledButton
                  onClick={() =>
                    setStatus({
                      status: "ADD",
                      currentPhoneId: null,
                    })
                  }
                >
                  Add Phone
                </StyledButton>
              </>
            ) : (
              <StyledButton
                onClick={() =>
                  setStatus({
                    status: "DEFAULT",
                    currentPhoneId: null,
                  })
                }
              >
                Back
              </StyledButton>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
