import { Container, Row, Col } from "react-grid-system";
import { SearchForm } from "../../../modules/AccountSearch";
import { useSearchParams } from "react-router-dom";
import { useId } from "react";
import styles from "./PhonesSearch.module.css";
import Table from "../../../modules/common/tables/Table";
import { TableColumn } from "../../../modules/common/tables/Table/Table";

const strings = {
  searchTitle: "Search",
  search: "Search by Phone or Account Number",
  searchDescription:
    'Enter a 10-digit phone number or the 16-digit Account Number and click "Search" to find the account(s) associated with the phone number or account number.',
  phonePlaceholder: "555-555-1212",
  nameOnCard: "Name on Card",
  accountId: "Account ID",
};

const PHONE_SEARCH_PARAM_KEY = "phone_search_key";

export default function PhonesSearch() {
  const labelId = useId();
  const [searchParams, setSearchParams] = useSearchParams();

  const phoneSearchParam = searchParams.get(PHONE_SEARCH_PARAM_KEY);

  const onSearchPhones = (value: string) => {
    if (value !== phoneSearchParam) {
      setSearchParams({ [PHONE_SEARCH_PARAM_KEY]: value });
    } else {
      // TODO: Refetch
    }
  };

  const columns: TableColumn<Record<string, string>>[] = [];

  columns.push(
    {
      header: strings.nameOnCard,
      size: 5,
      alignment: "left",
      render: (row) => <div>{row.name}</div>,
    },
    {
      header: strings.accountId,
      size: 5,
      alignment: "left",
      render: (row) => <div>{row.id}</div>,
    },
  );

  return (
    <main>
      <Container>
        <Row>
          <Col xs={8} offset={{ xs: 2 }}>
            <div className={styles.searchTitle}>{strings.searchTitle}</div>
          </Col>
        </Row>
        <Row>
          <Col xs={8} offset={{ xs: 2 }}>
            <div className={styles.searchContainer}>
              <div className={styles.search}>{strings.search}</div>
              <div id={labelId} className={styles.searchDescription}>
                {strings.searchDescription}
              </div>
              <div className={styles.searchBarContainer}>
                <SearchForm
                  currentValue={phoneSearchParam}
                  isLoading={false} // TODO
                  labelId={labelId}
                  onSubmit={onSearchPhones}
                  placeholder={strings.phonePlaceholder}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className={styles.tableRow}>
          <Col xs={8} offset={{ xs: 2 }}>
            <Table
              columns={columns}
              data={[
                {
                  name: "Jane Doe",
                  id: "844802 v0071",
                },
                {
                  name: "OCL 10 Account Test",
                  id: "865937 v0025",
                },
              ]}
              onClickRow={() => null}
            />
          </Col>
        </Row>
      </Container>
    </main>
  );
}
