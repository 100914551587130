import styles from "./Toggle.module.css";

const WIDTH = 40;
const HEIGHT = 24;
const MARGIN = 3;

type Props = {
  checked: boolean;
  onCheckedChanged: () => void;
  label: string;
};

export default function Toggle({ checked, onCheckedChanged, label }: Props) {
  return (
    <label className={styles.mainContainer}>
      <input
        className={styles.hiddenInput}
        type="checkbox"
        checked={checked}
        onChange={() => onCheckedChanged()}
      />
      <svg className={styles.sliderContainer} width={WIDTH} height={HEIGHT}>
        <circle
          className={styles.sliderCircle}
          cx={checked ? WIDTH - HEIGHT / 2 : HEIGHT / 2}
          cy={HEIGHT / 2}
          r={HEIGHT / 2 - MARGIN}
          fill="white"
        />
      </svg>
      <span className={styles.label}>{label}</span>
    </label>
  );
}
