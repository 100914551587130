import {
  createContext,
  createElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { getAgentDetails } from "./api/endpoints";
import { AgentRole } from "./api/models";
import { reportError } from "./bugReporting";

export type UserRoles = {
  roles: AgentRole[];
};

export type RoleContext = {
  roles: UserRoles | null;
  isLoadingRoles: boolean;
  refetchRoles: () => void;
  getRolesError: unknown;
};

function _useRoleState(): RoleContext {
  const [roles, setRoles] = useState<UserRoles | null>(null);
  const [loading, setLoading] = useState(true);
  const [roleStateError, setRoleStateError] = useState(undefined);

  const fetchRoles = () => {
    setRoleStateError(undefined);
    setLoading(true);
    getAgentDetails()
      .then(setRoles)
      .catch((err) => {
        reportError(new Error("Error getting current user roles"), {
          error: err,
        });
        setRoleStateError(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  return {
    roles: roles,
    isLoadingRoles: loading,
    refetchRoles: fetchRoles,
    getRolesError: roleStateError,
  };
}

const RoleContext = createContext<RoleContext | undefined>(undefined);

export function useRoleState() {
  const context = useContext(RoleContext);
  if (context) return context;
  return _useRoleState();
}

export function useRoles(): UserRoles | null {
  const { roles: roles } = useRoleState();
  return roles;
}

export function Provider({ children }: { children: React.ReactNode }) {
  const state = useRoleState();
  return createElement(RoleContext.Provider, { value: state }, children);
}

function useAgentHasRoles(desiredRoles: AgentRole[]): boolean {
  const user = useRoles();
  if (!user) {
    return false;
  }
  return user.roles.some((role) => {
    for (let i = 0; i < desiredRoles.length; i++) {
      if (desiredRoles[i] === role) {
        return true;
      }
    }
    return false;
  });
}

export function useIsAgentRoleACH(): boolean {
  return useAgentHasRoles(["ach"]);
}

export function useIsAgentRoleACHPaper(): boolean {
  return useAgentHasRoles(["achPaper"]);
}

export function useIsAgentRoleAddPaymentRestriction(): boolean {
  return useAgentHasRoles(["addPaymentRestriction"]);
}

export function useIsAgentRoleMakePayment(): boolean {
  return useAgentHasRoles(["makePayment"]);
}

export function useIsAgentRoleRemovePaymentRestriction(): boolean {
  return useAgentHasRoles(["removePaymentRestriction"]);
}

export function useIsAgentRoleUnlock(): boolean {
  return useAgentHasRoles(["unlock"]);
}

export function useIsAgentRoleUSA(): boolean {
  return useAgentHasRoles(["usa"]);
}

export function useIsAgentRoleUsername(): boolean {
  return useAgentHasRoles(["username"]);
}

export function useIsAgentRoleViewPCIData(): boolean {
  return useAgentHasRoles(["viewPCIData"]);
}

export function useIsAgentRolePermissionToDiscuss(): boolean {
  return useAgentHasRoles(["permissionToDiscuss"]);
}

export function useIsAgentRoleCycleToDateTransactions(): boolean {
  return useAgentHasRoles(["cycleToDateTransactions"]);
}

export function useIsAgentRoleStatementHistory(): boolean {
  return useAgentHasRoles(["statementHistory"]);
}

export function useIsAgentRoleViewAccountNumber(): boolean {
  return useAgentHasRoles(["viewAccountNumber"]);
}

export function useIsAgentRoleCreditLineIncrease(): boolean {
  return useAgentHasRoles(["cli"]);
}

export function useIsAgentRoleTerms(): boolean {
  return useAgentHasRoles(["terms"]);
}

export function useIsAgentPhones(): boolean {
  return useAgentHasRoles(["phones"]);
}
