import {
  ContactId,
  ReplacementType,
  ShipReplacementTo,
  TypeOfLoss,
} from "../../../modules/LostStolen/FieldIds";
import { LostStolenFormState } from "../../../modules/LostStolen/LostStolen";
import {
  LostStolenOptionsResponse,
  LostStolenScript,
  Script,
  ScriptType,
} from "../../api/models";
import { reportError } from "../../bugReporting";

export const lostStolenScript0055 = (
  fraudOnAccount?: boolean,
): (Script | null)[] => [
  fraudOnAccount
    ? {
        scriptType: ScriptType.Instruction,
        instructions: [{ text: "State about affidavit of fraud and forgery" }],
      }
    : null,
  fraudOnAccount
    ? {
        scriptType: ScriptType.Dialogue,
        dialogue:
          "Due to the fraudulent activity you reported, an affidavit of fraud and forgery will be sent to you from First Data Resources from Omaha, Nebraska within 10-14 days. It would be helpful if you complete the form and return it to First Data Resources as soon as possible to assist us in conducting our investigation. A fax number is printed on the form if you would prefer to return it by fax.\nIf you should remember making the charge(s) or granting someone permission to make the charges, please feel free to call our Security Department and advise them. Their number is 888-999-0970.​",
      }
    : null,
];

export const lostStolenScript0054 = (
  isEmergencyCardEligible: boolean,
  cardBrand: "mc" | "visa",
  emergencyCardTravelInd: boolean,
  emergencyCardRequested: boolean,
  travelDaysAllowed: string,
): (Script | null)[] => [
  {
    scriptType: ScriptType.Instruction,
    instructions: [{ text: "State eligibility for Emergency Card" }],
  },
  isEmergencyCardEligible && cardBrand === "mc"
    ? {
        scriptType: ScriptType.Instruction,
        instructions: [
          {
            text: "Check the Emergency Replacement Card check box if the Cardmember states they need an emergency replacement card.",
          },
        ],
      }
    : null,
  isEmergencyCardEligible && cardBrand === "visa"
    ? {
        scriptType: ScriptType.Instruction,
        instructions: [
          {
            text: `If Cardmember needs the card sooner, ask if they will be traveling in the next ${travelDaysAllowed} business days and click the appropriate button to determine eligibility.\nIf Cardmember does NOT need the card sooner, do NOT respond to the Travel prompt.`,
          },
        ],
      }
    : null,
  emergencyCardTravelInd
    ? {
        scriptType: ScriptType.Instruction,
        instructions: [
          {
            text: "Check the Emergency Replacement Card check box if the Cardmember states they need an emergency replacement card.",
          },
        ],
      }
    : null,
  emergencyCardRequested
    ? {
        scriptType: ScriptType.Dialogue,
        dialogue:
          "Please hold the line while I transfer you to an account specialist that can make delivery arrangements with you for an emergency replacement card. Please hold",
      }
    : null,
  emergencyCardRequested
    ? {
        scriptType: ScriptType.Instruction,
        instructions: [
          {
            text: "For an emergency replacement card, transfer call to an Account Specialist at 1-800-337-9911.",
          },
        ],
      }
    : null,
  !emergencyCardTravelInd || !isEmergencyCardEligible
    ? {
        scriptType: ScriptType.Instruction,
        instructions: [
          {
            text: "This customer is not eligible for an emergency replacement card.\nIf the Cardmember still wants the card sooner than the selected delivery option:\n\nIndia Agent - transfer to Account Specialist Ext - 7020\nUS Agent - Review account for delivery options",
          },
        ],
      }
    : null,
];

export const lostStolenScript0053 = (
  rushDeliveryDate: string,
  replacementOption?: ReplacementType,
): (Script | null)[] => [
  {
    scriptType: ScriptType.Instruction,
    instructions: [{ text: "State to watch for the delivery of the Card" }],
  },
  replacementOption === ReplacementType.Standard
    ? {
        scriptType: ScriptType.Dialogue,
        dialogue:
          "Also watch for the new card which will arrive in a plain white envelope with a return address of Omaha Nebraska.",
      }
    : replacementOption === ReplacementType.RushFedex
      ? {
          scriptType: ScriptType.Dialogue,
          dialogue: `Also watch for the new card which will arrive by FedEx delivery and someone will need to sign for the new card on ${rushDeliveryDate}.`,
        }
      : replacementOption === ReplacementType.RushPostal
        ? {
            scriptType: ScriptType.Dialogue,
            dialogue:
              "Also watch for the new card which will arrive by Postal delivery in approximately 3-4 days",
          }
        : replacementOption === ReplacementType.Fedex
          ? {
              scriptType: ScriptType.Dialogue,
              dialogue:
                "Also watch for the new card which will arrive by FedEx delivery and someone will need to sign for the new card on Saturday.",
            }
          : null,
];

export const lostStolenScript0052 = (
  typeOfLoss?: TypeOfLoss,
): (Script | null)[] => [
  typeOfLoss !== TypeOfLoss.FraudApp
    ? {
        scriptType: ScriptType.Instruction,
        instructions: [
          {
            text: "State if Card Information should be shared with other companies",
          },
        ],
      }
    : null,
  typeOfLoss !== TypeOfLoss.FraudApp
    ? {
        scriptType: ScriptType.Dialogue,
        dialogue:
          "Please be sure to share your new credit card number with companies that you used the card to make automated payments such as for a Bill Pay service or cell phone.",
      }
    : null,
];

export const lostStolenScript0050 = (
  rushDeliveryDate: string,
  replacementOption?: ReplacementType,
): (Script | null)[] => [
  {
    scriptType: ScriptType.Instruction,
    instructions: [{ text: "State when the card may be received" }],
  },
  replacementOption === ReplacementType.Standard
    ? {
        scriptType: ScriptType.Dialogue,
        dialogue:
          "You will receive a new card with a new account number in approximately 10-14 days.",
      }
    : replacementOption === ReplacementType.RushFedex
      ? {
          scriptType: ScriptType.Dialogue,
          dialogue: `You will receive a new card with a new account number on ${rushDeliveryDate}`,
        }
      : replacementOption === ReplacementType.Fedex
        ? {
            scriptType: ScriptType.Dialogue,
            dialogue:
              "You will receive a new card with a new account number on Saturday",
          }
        : replacementOption === ReplacementType.RushPostal
          ? {
              scriptType: ScriptType.Dialogue,
              dialogue:
                "You will receive a new card with a new account number in approximately 3-4 days.",
            }
          : null,
];

export const lostStolenScript0039 = (
  securityDeptCloseTime: string,
  transferToSecurity?: boolean,
): (Script | null)[] => [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Please hold on the line while I transfer you to an account specialist that can assist you.",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `If Transfer to Security 6777 was successful\nSelect Yes to "Xfer'd to Security 6777?"\nClick OK to End\n\nIf security line is busy OR after ${securityDeptCloseTime}\nSelect No to "Xfer'd to Security 6777?"\nAND\nContinue with Lost Report.`,
      },
    ],
  },
  transferToSecurity === false
    ? {
        scriptType: ScriptType.Instruction,
        instructions: [
          {
            text: "Date Lost: Use Today's date or date of 1st transaction (not including fees)",
          },
        ],
      }
    : null,
  transferToSecurity === false
    ? {
        scriptType: ScriptType.Dialogue,
        dialogue:
          "Our security department will call you within the next business day to obtain additional information.",
      }
    : null,
  transferToSecurity === false
    ? {
        scriptType: ScriptType.Instruction,
        instructions: [{ text: "Click the Submit button" }],
      }
    : null,
];

export const lostStolenScript0038 = (): Script[] => [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Thank you for calling in and letting us know you found the card.\nWhat state are you located in?",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: 'Complete "Additional Info" with any information provided by the merchant\n(example- where card was found, name of store, etc.)',
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Please destroy the card in your possession and we will take care of it from here.",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [{ text: "Click the Submit button" }],
  },
];

export const lostStolenScript0033 = (): Script[] => [
  {
    scriptType: ScriptType.Instruction,
    instructions: [{ text: "Lost Report has been completed" }],
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Call the CM:\nAdvise card was found\nLost Report completed\nAND new card is being sent",
      },
    ],
  },
];

export const lostStolenScript0029 = (): Script[] => [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "The lost report has been completed.\n\nWe do need to speak to the Cardmember, so if you talk with them, please have them contact us immediately to confirm their charges.",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [{ text: "Click the OK Button to End" }],
  },
];

export const lostStolenScript0026 = (): Script[] => [
  { scriptType: ScriptType.Dialogue, dialogue: "What date was the card lost?" },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Date Lost: \n(If Fraud on Account use the date of the first fraud charge, \nelse use date provided by Cardmember.)",
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Do you know where the card was lost?\nWhat state was the card lost in?\nWas the PIN number lost with the card?",
  },
];

export const lostStolenScript0024 = (
  securityDeptCloseTime: string,
  fraudOnAccount?: boolean,
): (Script | null)[] => [
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Complete a Full Account Verification and an Out of Wallet question if not completed at the beginning of the call.",
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "I can help you process the lost report\nAre you aware of any fraud charges on the account?",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: 'Review charges and authorizations on the account.\nThen select the appropriate "Fraud on Account" Radio button',
      },
    ],
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: 'If NO Fraud\nSelect No to "Fraud on Account?"\nAND\nContinue with Lost Report',
      },
    ],
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: 'If Fraud\nSelect Yes to "Fraud on Account?"',
      },
    ],
  },
  fraudOnAccount
    ? {
        scriptType: ScriptType.Dialogue,
        dialogue:
          "Please hold on the line while I transfer you to an account specialist that can assist you.",
      }
    : null,
  fraudOnAccount
    ? {
        scriptType: ScriptType.Instruction,
        instructions: [
          {
            text: 'If Transfer to Security 6777 was successful\nSelect Yes to "Xfer\'d to Security 6777?"\nAND\nClick OK to End',
          },
        ],
      }
    : null,
  fraudOnAccount
    ? {
        scriptType: ScriptType.Instruction,
        instructions: [
          {
            text: `If security line is busy OR after ${securityDeptCloseTime}\nSelect No to "Transfer to Security 6777?"\nAND\nContinue with Lost report`,
          },
        ],
      }
    : null,
];

export const lostStolenScript0020 = (): Script[] => [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "I can help you process the lost report. We can shut this card down immediately but we do need to speak to the Cardmember to confirm their charges. Are they available?",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      { text: "If Yes: Change the Contact to Cardmember and continue" },
    ],
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [{ text: "If No:" }],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "If you speak with them, please have them contact us immediately to confirm their charges.",
  },
  ...lostStolenScript0026(),
];

export const lostStolenScript0019 = (
  securityDeptCloseTime: string,
  fraudOnAccount?: boolean,
  transferToSecurity?: boolean,
): (Script | null)[] => [
  {
    scriptType: ScriptType.Dialogue,
    dialogue: "The account you have provided has already been closed.",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      { text: "Review charges and authorizations on the account" },
    ],
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: 'If NO Fraud\nSelect No to "Fraud on Account?"\nIf Fraud\nSelect Yes to "Fraud on Account?"',
      },
    ],
  },
  fraudOnAccount === false
    ? {
        scriptType: ScriptType.Dialogue,
        dialogue:
          "Thank you for calling. Is there anything else I can help you with?",
      }
    : null,
  fraudOnAccount === false
    ? {
        scriptType: ScriptType.Instruction,
        instructions: [{ text: "Click OK to End" }],
      }
    : null,
  fraudOnAccount
    ? {
        scriptType: ScriptType.Dialogue,
        dialogue:
          "Please hold on the line while I transfer you to an account specialist that can assist you.",
      }
    : null,
  fraudOnAccount
    ? {
        scriptType: ScriptType.Instruction,
        instructions: [
          {
            text: `If Transfer to Security 6777 was successful\nSelect Yes to "Xfer'd to Security 6777?"\nAND\nClick OK to End\nIf security line is busy OR after ${securityDeptCloseTime}\nSelect No to "Xfer'd to Security 6777?"\nAND\nContinue with Lost Report.`,
          },
        ],
      }
    : null,
  ...(transferToSecurity ? lostStolenScript0026() : []),
  transferToSecurity
    ? {
        scriptType: ScriptType.Instruction,
        instructions: [{ text: "Click the Submit button" }],
      }
    : null,
];

export const lostStolenScript0011 = (
  isRushEligible: boolean,
  replacementFee: string,
  rushFee: string,
  rushDeliveryDate: string,
  typeOfLoss?: TypeOfLoss,
  replacementType?: ReplacementType,
): (Script | null)[] => [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Do you want the new card to be sent to your current billing address or a temporary address?",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [{ text: "Select Ship Replacement to:" }],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "When a card is lost, we process a lost report on our system. You will normally receive a new card in 10-14 days.",
  },
  typeOfLoss !== TypeOfLoss.NotReceived &&
  typeOfLoss !== TypeOfLoss.Counterfeit &&
  typeOfLoss !== TypeOfLoss.AccountTakeover &&
  typeOfLoss !== TypeOfLoss.AllMailAndPhone
    ? {
        scriptType: ScriptType.Dialogue,
        dialogue: `A ${replacementFee} replacement charge is billed to the account anytime we setup a new account number for you.`,
      }
    : null,
  isRushEligible &&
  (typeOfLoss === TypeOfLoss.NotReceived ||
    typeOfLoss === TypeOfLoss.Counterfeit ||
    typeOfLoss === TypeOfLoss.AccountTakeover ||
    typeOfLoss === TypeOfLoss.AllMailAndPhone)
    ? {
        scriptType: ScriptType.Dialogue,
        dialogue: `If you do need the card sooner, I want to make you aware that we have a rush card delivery option. There is a ${rushFee} fee for this . Would you like your new card rushed?`,
      }
    : null,
  isRushEligible &&
  typeOfLoss !== TypeOfLoss.NotReceived &&
  typeOfLoss !== TypeOfLoss.Counterfeit &&
  typeOfLoss !== TypeOfLoss.AccountTakeover &&
  typeOfLoss !== TypeOfLoss.AllMailAndPhone
    ? {
        scriptType: ScriptType.Dialogue,
        dialogue: `If you do need the card sooner, I want to make you aware that we have a rush card delivery option. There is a ${rushFee} fee for this along with the normal ${replacementFee} replacement fee. Would you like your new card rushed?`,
      }
    : null,
  isRushEligible
    ? {
        scriptType: ScriptType.Instruction,
        instructions: [
          {
            text: 'If customer states:\nYes, select the appropriate Replacement Option (review the "i" for further details)\nNo, Replacement Option = Standard',
          },
        ],
      }
    : null,
  replacementType === ReplacementType.RushFedex
    ? {
        scriptType: ScriptType.Dialogue,
        dialogue: `The card will be delivered to you on ${rushDeliveryDate} by Federal Express and someone must be present to sign for the card.`,
      }
    : null,
  replacementType === ReplacementType.Fedex
    ? {
        scriptType: ScriptType.Dialogue,
        dialogue:
          "The card will be delivered to you on Saturday by Federal Express and someone must be present to sign for the card.",
      }
    : null,
  replacementType === ReplacementType.RushPostal
    ? {
        scriptType: ScriptType.Dialogue,
        dialogue:
          "The card will be delivered to you in approximately 3-4 days.",
      }
    : null,
  ...lostStolenScript0026(),
  {
    scriptType: ScriptType.Instruction,
    instructions: [{ text: "Click the Submit button" }],
  },
];

export const lostStolenScript0010 = (): Script[] => [
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "The System did not successfully submit the Lost Report to FDR.\nPlease Click the Submit button to try again.\nIf the problem continues, please contact the Help Desk for Assistance,\nRead the script below to the customer and then follow the directions.\n\nUS Agents:\n\nManually process lost report on FDR.\nSee CS Procedure Manual for full lost report procedures.\n\nIf unsuccessful on FDR, capture data and process the request at a later time.\nALSO\nAttempt to A status the account.\n\nClick Terminate button to End\n\nIndia Agents:",
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "I was unable to successfully submit the Lost details due to system issues. Please hold the line while I transfer you to an account specialist that can assist you. You will be asked similar questions about this situation as I cannot save the details. Please hold.",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Transfer to the Account Specialist Ext - 7020\nClick the Terminate button to End",
      },
    ],
  },
];

export const lostStolenScript0009 = (
  securityDeptCloseTime: string,
): Script[] => [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "This card has already been reported Lost. Are you calling to report charges that are fraudulent?",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: 'If No:\nSelect No to "Fraud on Account?"\nAND\nClick OK button to End',
      },
    ],
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: 'If Yes:\nSelect Yes to "Fraud on Account?"\nAND\nTransfer call to Security - Ext 6777',
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Please hold on the line while I transfer you to an account specialist that can assist you.",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      { text: "If Transfer to Security 6777 was successful\nClick OK to End" },
    ],
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `US Agents:\nIf security line is busy OR after ${securityDeptCloseTime}\nUpdate the Lost Report on FDR via SL command\nPlace a  Y in the PF indicator\nAND\nAdd fraud transactions in the memo field\nTHEN\nClick OK to End`,
      },
    ],
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `India Agents:\nIf security line is busy OR after ${securityDeptCloseTime}\nTransfer call to the Account Specialist Ext - 7020\nAND\nClick OK to End`,
      },
    ],
  },
];

export const lostStolenScript0008 = (): Script[] => [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Thank you for calling in and letting us know that card was lost. We will take care of it from here. Please destroy the card.",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [{ text: "Click OK button to End" }],
  },
];

export const lostStolenScript0007 = (externalStatus: string): Script[] => [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "The account you have provided has been closed. Due to your account status, please hold on the line while I transfer you to an account specialist that can assist you.",
  },
  externalStatus === "B"
    ? {
        scriptType: ScriptType.Instruction,
        instructions: [
          { text: "Transfer call to Central Services - Ext 6750" },
        ],
      }
    : {
        scriptType: ScriptType.Instruction,
        instructions: [
          {
            text: "If charged off due to delinquency,\nTransfer call to Sioux Fall Collection - Ext 7679If charged off due to Fraud,\nSearch for new account number",
          },
        ],
      },
  {
    scriptType: ScriptType.Instruction,
    instructions: [{ text: "Click OK button to End" }],
  },
];

export const lostStolenScript0006 = (
  contactType?: ContactId,
): (Script | null)[] => [
  contactType === ContactId.Cardmember
    ? {
        scriptType: ScriptType.Dialogue,
        dialogue:
          "The account you have provided is a loan account and does not have a card issued for it. We cannot report this account as lost or send a card.",
      }
    : contactType === ContactId.Merchant ||
        contactType === ContactId.ThirdPartyUnknownToCM
      ? {
          scriptType: ScriptType.Dialogue,
          dialogue:
            "Thank you for calling in and letting us know you found the card. We will take care of it from here. Please destroy the card in your possession.",
        }
      : {
          scriptType: ScriptType.Dialogue,
          dialogue:
            "Thank you for calling in and letting us know that card was lost. We will take care of it from here. Please destroy the card.",
        },
  contactType === ContactId.Cardmember
    ? {
        scriptType: ScriptType.Instruction,
        instructions: [
          {
            text: "If the Customer states they received a card on this account, please use the normal transfer procedures.",
          },
        ],
      }
    : null,
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "No need for further processing.\nClick OK button to End",
      },
    ],
  },
];

export const lostStolenScript0002 = (
  isEmergencyCardEligible: boolean,
  cardBrand: "mc" | "visa",
  emergencyCardTravelInd: boolean,
  emergencyCardRequested: boolean,
  rushDeliveryDate: string,
  travelDaysAllowed: string,
  typeOfLoss?: TypeOfLoss,
  replacementOption?: ReplacementType,
  fraudOnAccount?: boolean,
): (Script | null)[] => [
  {
    scriptType: ScriptType.Dialogue,
    dialogue: "The lost report has been completed.",
  },
  ...lostStolenScript0050(rushDeliveryDate, replacementOption),
  ...lostStolenScript0052(typeOfLoss),
  ...lostStolenScript0053(rushDeliveryDate, replacementOption),
  ...lostStolenScript0055(fraudOnAccount),
  ...lostStolenScript0054(
    isEmergencyCardEligible,
    cardBrand,
    emergencyCardTravelInd,
    emergencyCardRequested,
    travelDaysAllowed,
  ),
  {
    scriptType: ScriptType.Instruction,
    instructions: [{ text: "Click OK button to End" }],
  },
];

function notNull<T>(value: T | null): value is T {
  return value !== null;
}

type LostStolenScriptDetails = {
  script: Script[];
  name: string;
};

export const getLostStolenScriptDetails = (
  formState: LostStolenFormState,
  options: LostStolenOptionsResponse,
  emergencyCardTravelInd: boolean,
  emergencyCardRequested: boolean,
  scriptName?: LostStolenScript,
): LostStolenScriptDetails | undefined => {
  if (!scriptName) {
    return undefined;
  }

  const isRushEligible =
    options.isRushEligible &&
    formState.contactId === ContactId.Cardmember &&
    formState.typeOfLoss !== TypeOfLoss.NotReceived &&
    formState.typeOfLoss !== TypeOfLoss.FraudApp;

  const useBillingAddress =
    formState.yesFraudOnAccount?.shipReplacementTo ===
      ShipReplacementTo.Billing ||
    formState.noFraudOnAccount?.shipReplacementTo === ShipReplacementTo.Billing;
  const selectedReplacementOption = formState.fraudOnAccount
    ? useBillingAddress
      ? formState.yesFraudOnAccount?.billing?.finalInfo?.replacementOption
      : formState.yesFraudOnAccount?.temporary?.finalInfo?.replacementOption
    : useBillingAddress
      ? formState.noFraudOnAccount?.billing?.finalInfo?.replacementOption
      : formState.noFraudOnAccount?.temporary?.finalInfo?.replacementOption;

  switch (scriptName) {
    case "LS0002":
      return {
        script: lostStolenScript0002(
          options.isEmergencyCardEligible,
          options.cardBrand,
          emergencyCardTravelInd,
          emergencyCardRequested,
          options.rushDeliveryDate,
          options.travelDaysAllowedEmergencyCard,
          formState.typeOfLoss,
          selectedReplacementOption,
          formState.fraudOnAccount,
        ).filter(notNull),
        name: "LS0002",
      };
    case "LS0006":
      return {
        script: lostStolenScript0006(formState.contactId).filter(notNull),
        name: "LS0006",
      };
    case "LS0007":
      return {
        script: lostStolenScript0007(options.externalStatus),
        name: "LS0007",
      };
    case "LS0008":
      return {
        script: lostStolenScript0008(),
        name: "LS0008",
      };
    case "LS0009":
      return {
        script: lostStolenScript0009(options.securityDeptCloseTime),
        name: "LS0009",
      };
    case "LS0010":
      return {
        script: lostStolenScript0010(),
        name: "LS0010",
      };
    case "LS0011":
      return {
        script: lostStolenScript0011(
          isRushEligible,
          options.replacementFee,
          options.rushFee,
          options.rushDeliveryDate,
          formState.typeOfLoss,
          selectedReplacementOption,
        ).filter(notNull),
        name: "LS0011",
      };
    case "LS0019":
      return {
        script: lostStolenScript0019(
          options.securityDeptCloseTime,
          formState.fraudOnAccount,
          formState.yesFraudOnAccount?.transferToSecurity,
        ).filter(notNull),
        name: "LS0019",
      };
    case "LS0020":
      return {
        script: lostStolenScript0020(),
        name: "LS0020",
      };
    case "LS0024":
      return {
        script: lostStolenScript0024(
          options.securityDeptCloseTime,
          formState.fraudOnAccount,
        ).filter(notNull),
        name: "LS0024",
      };
    case "LS0029":
      return {
        script: lostStolenScript0029(),
        name: "LS0029",
      };
    case "LS0033":
      return {
        script: lostStolenScript0033(),
        name: "LS0033",
      };
    case "LS0038":
      return {
        script: lostStolenScript0038(),
        name: "LS0038",
      };
    case "LS0039":
      return {
        script: lostStolenScript0039(
          options.securityDeptCloseTime,
          formState.yesFraudOnAccount?.transferToSecurity,
        ).filter(notNull),
        name: "LS0039",
      };

    default:
      reportError(
        new Error(
          "Programmer Error: Could not find script associated with the provided scriptName",
          {
            cause: scriptName,
          },
        ),
      );
      return scriptName;
  }
};
