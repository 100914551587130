import classNames from "classnames";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import clarityBannerLogo from "../../../assets/clarity-banner-logo.svg";
import { reportError } from "../../../lib/bugReporting";
import { useIsAgentRoleUSA } from "../../../lib/roleContext";
import { paths } from "../../../lib/router";
import styles from "./Banner.module.css";

const strings = {
  /* TODO: Remove demo pages */
  clarityLogoAlt: "1FB Clarity Logo",
  componentDemo: "Component Demo",
  toolsDemo: "Tools Demo",
  accountSearch: "Account Search",
  accountDetails: "Mock Link to Account Details",
  secureMailQueue: "SecureMail Queue",
  phones: "Phones",
};

const FETCH_VERSION_INTERVAL_SECONDS = 30;

const isDev = import.meta.env.DEV;
const showDemoLinks = import.meta.env.DEV || import.meta.env.MODE === "bhwlabs";
const viteVersion = import.meta.env.VITE_VERSION_CODE;

const getLinkStyling = ({ isActive }: { isActive: boolean }) =>
  classNames({
    [styles.link]: true,
    [styles.activeLink]: isActive,
  });
export default function Banner() {
  const [shouldReloadOnNavigation, setShouldReloadOnNavigation] =
    useState(false);

  useEffect(() => {
    if (isDev) {
      return;
    }

    function fetchJSONVersion() {
      fetch("/version.json")
        .then((version) => {
          version.json().then((obj) => {
            const jsonVersion = obj.version;
            setShouldReloadOnNavigation(
              jsonVersion && jsonVersion !== viteVersion,
            );
          });
        })
        .catch((err) => reportError(err));
    }

    fetchJSONVersion();
    const interval = setInterval(() => {
      fetchJSONVersion();
    }, FETCH_VERSION_INTERVAL_SECONDS * 1000);
    return () => clearInterval(interval);
  }, []);

  const canViewSecureMail = useIsAgentRoleUSA();
  return (
    <div className={styles.container}>
      <img
        className={styles.logo}
        src={clarityBannerLogo}
        alt={strings.clarityLogoAlt}
      />
      <nav>
        <ul className={styles.navList}>
          <li>
            <NavLink
              className={getLinkStyling}
              to={paths.accountSearch}
              reloadDocument={shouldReloadOnNavigation}
            >
              {strings.accountSearch}
            </NavLink>
          </li>
          {canViewSecureMail ? (
            <li>
              <NavLink
                className={getLinkStyling}
                to={paths.secureMailQueue}
                reloadDocument={shouldReloadOnNavigation}
              >
                {strings.secureMailQueue}
              </NavLink>
            </li>
          ) : null}
          {import.meta.env.VITE_PHONES_ENABLED === "true" ? (
            <li>
              <NavLink
                className={getLinkStyling}
                to={paths.phones}
                reloadDocument={shouldReloadOnNavigation}
              >
                {strings.phones}
              </NavLink>
            </li>
          ) : null}
          {showDemoLinks ? (
            <>
              <li>
                <NavLink
                  className={getLinkStyling}
                  to={paths.componentsDemo}
                  reloadDocument={shouldReloadOnNavigation}
                >
                  {strings.componentDemo}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={getLinkStyling}
                  to={paths.tools}
                  reloadDocument={shouldReloadOnNavigation}
                >
                  {strings.toolsDemo}
                </NavLink>
              </li>

              <li>
                <NavLink
                  className={getLinkStyling}
                  to={paths.accountDetails("1234")}
                  reloadDocument={shouldReloadOnNavigation}
                >
                  {strings.accountDetails}
                </NavLink>
              </li>
            </>
          ) : null}
        </ul>
      </nav>
    </div>
  );
}
