import { ToolState, ToolType, useToolState } from "../AccountDetails";
import {
  useIsAgentPhones,
  useIsAgentRoleACH,
  useIsAgentRoleCreditLineIncrease,
  useIsAgentRoleCycleToDateTransactions,
  useIsAgentRoleMakePayment,
  useIsAgentRolePermissionToDiscuss,
  useIsAgentRoleStatementHistory,
  useIsAgentRoleTerms,
  useIsAgentRoleUsername,
} from "../../lib/roleContext";
import ActiveToolButton from "./ActiveToolButton";
import CloseToolConfirmationModal from "../AccountDetails/CloseToolConfirmationModal/CloseToolConfirmationModal";
import { GetAccountResponse } from "../../lib/api/models";
import SelectToolButton from "./SelectToolButton";
import ToolbarExpansionButton from "./ToolbarExpansionButton";
import ToolbarSummaryButton from "./ToolbarSummaryButton";
import autopayActive from "../../assets/autopay-active.svg";
import autopayInactive from "../../assets/autopay-inactive.svg";
import changeUsernameActive from "../../assets/change-username-active.svg";
import changeUsernameInactive from "../../assets/change-username-inactive.svg";
import classNames from "classnames";
import creditLineIncreaseActive from "../../assets/credit-line-increase-active.svg";
import creditLineIncreaseInactive from "../../assets/credit-line-increase-inactive.svg";
import cycleToDateTransactionsActive from "../../assets/cycle-to-date-transactions-active.svg";
import cycleToDateTransactionsInactive from "../../assets/cycle-to-date-transactions-inactive.svg";
import lostStolenActive from "../../assets/lost-stolen-active.svg";
import lostStolenInactive from "../../assets/lost-stolen-inactive.svg";
import makePaymentActive from "../../assets/make-payment-active.svg";
import makePaymentInactive from "../../assets/make-payment-inactive.svg";
import notificationActive from "../../assets/notification-active.svg";
import notificationInactive from "../../assets/notification-inactive.svg";
import paymentHistoryActive from "../../assets/payment-history-active.svg";
import paymentHistoryInactive from "../../assets/payment-history-inactive.svg";
import paymentTransactionsActive from "../../assets/payment-transactions-active.svg";
import paymentTransactionsInactive from "../../assets/payment-transactions-inactive.svg";
import permissionToDiscussActive from "../../assets/permission-to-discuss-active.svg";
import permissionToDiscussInactive from "../../assets/permission-to-discuss-inactive.svg";
import phonesActive from "../../assets/phones-active.svg";
import phonesInactive from "../../assets/phones-inactive.svg";
import sourceAccountsActive from "../../assets/payment-methods-active.svg";
import sourceAccountsInactive from "../../assets/payment-methods-inactive.svg";
import statementHistoryActive from "../../assets/statement-history-active.svg";
import statementHistoryInactive from "../../assets/statement-history-inactive.svg";
import termsActive from "../../assets/terms-active.svg";
import termsInactive from "../../assets/terms-inactive.svg";
import styles from "./Toolbar.module.css";
import { toolTypeToName } from "../AccountDetails/utils";
import { useState } from "react";

const toolData: Record<
  ToolType,
  {
    active: string;
    inactive: string;
  }
> = {
  [ToolType.ChangeUsername]: {
    active: changeUsernameActive,
    inactive: changeUsernameInactive,
  },
  [ToolType.TestPushNotification]: {
    active: notificationActive,
    inactive: notificationInactive,
  },
  [ToolType.PaymentTransactions]: {
    active: paymentTransactionsActive,
    inactive: paymentTransactionsInactive,
  },
  [ToolType.PaymentHistory]: {
    active: paymentHistoryActive,
    inactive: paymentHistoryInactive,
  },
  [ToolType.ManageAutopay]: {
    active: autopayActive,
    inactive: autopayInactive,
  },
  [ToolType.MakePayment]: {
    active: makePaymentActive,
    inactive: makePaymentInactive,
  },
  [ToolType.SourceAccounts]: {
    active: sourceAccountsActive,
    inactive: sourceAccountsInactive,
  },
  [ToolType.PermissionToDiscuss]: {
    active: permissionToDiscussActive,
    inactive: permissionToDiscussInactive,
  },
  [ToolType.CycleToDateTransactions]: {
    active: cycleToDateTransactionsActive,
    inactive: cycleToDateTransactionsInactive,
  },
  [ToolType.StatementHistory]: {
    active: statementHistoryActive,
    inactive: statementHistoryInactive,
  },
  [ToolType.LostStolen]: {
    active: lostStolenActive,
    inactive: lostStolenInactive,
  },
  [ToolType.CreditLineIncrease]: {
    active: creditLineIncreaseActive,
    inactive: creditLineIncreaseInactive,
  },
  [ToolType.Terms]: {
    active: termsActive,
    inactive: termsInactive,
  },
  [ToolType.Phones]: {
    active: phonesActive,
    inactive: phonesInactive,
  },
};

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}

type Props = {
  account: GetAccountResponse;
  toolState: ReturnType<typeof useToolState>;
  scrollToCompleted: () => void;
};

export function Toolbar({ account, toolState, scrollToCompleted }: Props) {
  const [isToolbarExpanded, setIsToolbarExpanded] = useState(false);
  const [closeConfirmationTool, setCloseConfirmationTool] =
    useState<ToolState>();

  const onExpandOrCollapse = () => {
    setIsToolbarExpanded((prev) => !prev);
  };

  const onCloseToolbar = () => {
    setIsToolbarExpanded(false);
  };

  const onClickCloseTool = (tool: ToolState) => {
    if (tool.inProgress && !closeConfirmationTool) {
      setCloseConfirmationTool(tool);
    } else {
      toolState.onRequestCloseTool(tool.id);
      onCloseToolbar();
    }
  };

  const canMakePayment = useIsAgentRoleMakePayment();
  const canChangeUsername = useIsAgentRoleUsername();
  const canManageAutopay = useIsAgentRoleACH();
  const canP2D = useIsAgentRolePermissionToDiscuss();
  const canC2D = useIsAgentRoleCycleToDateTransactions();
  const canViewStatementHistory = useIsAgentRoleStatementHistory();
  const canViewCreditLineIncrease = useIsAgentRoleCreditLineIncrease();
  const canViewTerms = useIsAgentRoleTerms();
  const canViewPhones = useIsAgentPhones();

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(
          styles.container,
          isToolbarExpanded ? styles.expandedContainer : null,
        )}
      >
        <div className={styles.subContainer}>
          <ToolbarExpansionButton
            isExpanded={isToolbarExpanded}
            onClick={onExpandOrCollapse}
            className={styles.toolbarExpansionButton}
          />
          <div className={styles.activeToolsContainer}>
            {toolState.activeTools.map((tool) => (
              <ActiveToolButton
                key={tool.id}
                className={styles.activeTool}
                icon={toolData[tool.type].active}
                isExpanded={isToolbarExpanded}
                label={toolTypeToName(tool.type)}
                onClickCloseTool={() => onClickCloseTool(tool)}
                onClickActiveTool={() =>
                  document
                    .getElementById(tool.id)
                    ?.scrollIntoView({ behavior: "smooth" })
                }
                inProgress={tool.inProgress}
              />
            ))}
          </div>
          {isToolbarExpanded && (
            <div className={styles.selectToolButtonContainer}>
              <SelectToolButton
                className={styles.selectToolButton}
                inactiveIcon={paymentHistoryInactive}
                activeIcon={paymentHistoryActive}
                label={toolTypeToName(ToolType.PaymentHistory)}
                active={toolState.activeTools.some(
                  (tool) => tool.type === ToolType.PaymentHistory,
                )}
                onClick={() => {
                  toolState.onAddTool({
                    type: ToolType.PaymentHistory,
                    inProgress: false,
                  });
                  onExpandOrCollapse();
                  scrollToTop();
                }}
              />
              <SelectToolButton
                className={styles.selectToolButton}
                inactiveIcon={paymentTransactionsInactive}
                activeIcon={paymentTransactionsActive}
                label={toolTypeToName(ToolType.PaymentTransactions)}
                active={toolState.activeTools.some(
                  (tool) => tool.type === ToolType.PaymentTransactions,
                )}
                onClick={() => {
                  toolState.onAddTool({
                    type: ToolType.PaymentTransactions,
                    inProgress: false,
                  });
                  onExpandOrCollapse();
                  scrollToTop();
                }}
              />
              {account.registrationDateTime && canChangeUsername ? (
                <SelectToolButton
                  className={styles.selectToolButton}
                  inactiveIcon={changeUsernameInactive}
                  activeIcon={changeUsernameActive}
                  label={toolTypeToName(ToolType.ChangeUsername)}
                  active={toolState.activeTools.some(
                    (tool) => tool.type === ToolType.ChangeUsername,
                  )}
                  onClick={() => {
                    toolState.onAddTool({
                      type: ToolType.ChangeUsername,
                      inProgress: true,
                    });
                    onExpandOrCollapse();
                    scrollToTop();
                  }}
                />
              ) : null}
              {canManageAutopay ? (
                <SelectToolButton
                  className={styles.selectToolButton}
                  inactiveIcon={autopayInactive}
                  activeIcon={autopayActive}
                  label={toolTypeToName(ToolType.ManageAutopay)}
                  active={toolState.activeTools.some(
                    (tool) => tool.type === ToolType.ManageAutopay,
                  )}
                  onClick={() => {
                    toolState.onAddTool({
                      type: ToolType.ManageAutopay,
                      inProgress: false,
                    });
                    onExpandOrCollapse();
                    scrollToTop();
                  }}
                />
              ) : null}
              {account.registrationDateTime ? (
                <SelectToolButton
                  className={styles.selectToolButton}
                  inactiveIcon={notificationInactive}
                  activeIcon={notificationActive}
                  label={toolTypeToName(ToolType.TestPushNotification)}
                  active={toolState.activeTools.some(
                    (tool) => tool.type === ToolType.TestPushNotification,
                  )}
                  onClick={() => {
                    toolState.onAddTool({
                      type: ToolType.TestPushNotification,
                      inProgress: false,
                    });
                    onExpandOrCollapse();
                    scrollToTop();
                  }}
                />
              ) : null}
              {canMakePayment ? (
                <SelectToolButton
                  className={styles.selectToolButton}
                  inactiveIcon={makePaymentInactive}
                  activeIcon={makePaymentActive}
                  label={toolTypeToName(ToolType.MakePayment)}
                  active={toolState.activeTools.some(
                    (tool) => tool.type === ToolType.MakePayment,
                  )}
                  onClick={() => {
                    toolState.onAddTool({
                      type: ToolType.MakePayment,
                      inProgress: false,
                    });
                    onExpandOrCollapse();
                    scrollToTop();
                  }}
                />
              ) : null}
              <SelectToolButton
                className={styles.selectToolButton}
                inactiveIcon={sourceAccountsInactive}
                activeIcon={sourceAccountsActive}
                label={toolTypeToName(ToolType.SourceAccounts)}
                active={toolState.activeTools.some(
                  (tool) => tool.type === ToolType.SourceAccounts,
                )}
                onClick={() => {
                  toolState.onAddTool({
                    type: ToolType.SourceAccounts,
                    inProgress: false,
                  });
                  onExpandOrCollapse();
                  scrollToTop();
                }}
              />
              {import.meta.env.VITE_P2D_ENABLED === "true" && canP2D ? (
                <SelectToolButton
                  className={styles.selectToolButton}
                  inactiveIcon={permissionToDiscussInactive}
                  activeIcon={permissionToDiscussActive}
                  label={toolTypeToName(ToolType.PermissionToDiscuss)}
                  active={toolState.activeTools.some(
                    (tool) => tool.type === ToolType.PermissionToDiscuss,
                  )}
                  onClick={() => {
                    toolState.onAddTool({
                      type: ToolType.PermissionToDiscuss,
                      inProgress: false,
                    });
                    onExpandOrCollapse();
                    scrollToTop();
                  }}
                />
              ) : null}
              {canC2D ? (
                <SelectToolButton
                  className={styles.selectToolButton}
                  inactiveIcon={cycleToDateTransactionsInactive}
                  activeIcon={cycleToDateTransactionsActive}
                  label={toolTypeToName(ToolType.CycleToDateTransactions)}
                  active={toolState.activeTools.some(
                    (tool) => tool.type === ToolType.CycleToDateTransactions,
                  )}
                  onClick={() => {
                    toolState.onAddTool({
                      type: ToolType.CycleToDateTransactions,
                      inProgress: false,
                    });
                    onExpandOrCollapse();
                    scrollToTop();
                  }}
                />
              ) : null}
              {canViewStatementHistory ? (
                <SelectToolButton
                  className={styles.selectToolButton}
                  inactiveIcon={statementHistoryInactive}
                  activeIcon={statementHistoryActive}
                  label={toolTypeToName(ToolType.StatementHistory)}
                  active={toolState.activeTools.some(
                    (tool) => tool.type === ToolType.StatementHistory,
                  )}
                  onClick={() => {
                    toolState.onAddTool({
                      type: ToolType.StatementHistory,
                      inProgress: false,
                    });
                    onExpandOrCollapse();
                    scrollToTop();
                  }}
                />
              ) : null}
              {import.meta.env.VITE_LOST_STOLEN_ENABLED === "true" ? (
                <SelectToolButton
                  className={styles.selectToolButton}
                  inactiveIcon={lostStolenInactive}
                  activeIcon={lostStolenActive}
                  label={toolTypeToName(ToolType.LostStolen)}
                  active={toolState.activeTools.some(
                    (tool) => tool.type === ToolType.LostStolen,
                  )}
                  onClick={() => {
                    toolState.onAddTool({
                      type: ToolType.LostStolen,
                      inProgress: false,
                    });
                    onExpandOrCollapse();
                    scrollToTop();
                  }}
                />
              ) : null}
              {canViewCreditLineIncrease ? (
                <SelectToolButton
                  className={styles.selectToolButton}
                  inactiveIcon={creditLineIncreaseInactive}
                  activeIcon={creditLineIncreaseActive}
                  label={toolTypeToName(ToolType.CreditLineIncrease)}
                  active={toolState.activeTools.some(
                    (tool) => tool.type === ToolType.CreditLineIncrease,
                  )}
                  onClick={() => {
                    toolState.onAddTool({
                      type: ToolType.CreditLineIncrease,
                      inProgress: false,
                    });
                    onExpandOrCollapse();
                    scrollToTop();
                  }}
                />
              ) : null}
              {canViewTerms ? (
                <SelectToolButton
                  className={styles.selectToolButton}
                  inactiveIcon={termsInactive}
                  activeIcon={termsActive}
                  label={toolTypeToName(ToolType.Terms)}
                  active={toolState.activeTools.some(
                    (tool) => tool.type === ToolType.Terms,
                  )}
                  onClick={() => {
                    toolState.onAddTool({
                      type: ToolType.Terms,
                      inProgress: false,
                    });
                    onExpandOrCollapse();
                    scrollToTop();
                  }}
                />
              ) : null}
              {import.meta.env.VITE_PHONES_ENABLED === "true" &&
              canViewPhones ? (
                <SelectToolButton
                  className={styles.selectToolButton}
                  inactiveIcon={phonesInactive}
                  activeIcon={phonesActive}
                  label={toolTypeToName(ToolType.Phones)}
                  active={toolState.activeTools.some(
                    (tool) => tool.type === ToolType.Phones,
                  )}
                  onClick={() => {
                    toolState.onAddTool({
                      type: ToolType.Phones,
                      inProgress: false,
                    });
                    onExpandOrCollapse();
                    scrollToTop();
                  }}
                />
              ) : null}
              <ToolbarSummaryButton
                className={styles.toolbarSummaryButton}
                completedToolsCount={toolState.completedTools.length}
                disabled={!toolState.completedTools.length}
                onClick={() => {
                  scrollToCompleted();
                  onExpandOrCollapse();
                }}
              />
            </div>
          )}
        </div>
        <div className={styles.versionContainer}>
          v{import.meta.env.VITE_VERSION_CODE}
        </div>
      </div>
      {closeConfirmationTool && (
        <CloseToolConfirmationModal
          isOpen={!!closeConfirmationTool}
          toolNames={[toolTypeToName(closeConfirmationTool.type)]}
          onCloseTool={() => {
            toolState.onRequestCloseTool(closeConfirmationTool.id);
            setCloseConfirmationTool(undefined);
            onCloseToolbar();
          }}
          onRequestClose={() => setCloseConfirmationTool(undefined)}
        />
      )}
    </div>
  );
}
